<h5>Consumption</h5>
<!-- ROW 1 -->
<div class="flex flex-row pb-4">
  <dms-frontend-tile-value-with-ref-value
    [headline]="'Produced Parts'"
    [loading]="loading"
    [value]="dressingMonitorIntervalKPIs?.producedPartsInterval?.value"
    [decimalPlaces]="0"
  ></dms-frontend-tile-value-with-ref-value>
  <dms-frontend-tile-value-with-ref-value
    class="pl-4"
    [headline]="'Tools'"
    [loading]="loading"
    [value]="dressingMonitorIntervalKPIs?.toolChangeCounterInterval?.value"
    [decimalPlaces]="0"
  ></dms-frontend-tile-value-with-ref-value>
  <dms-frontend-tile-value-with-ref-value
    class="pl-4"
    [headline]="'Life time / tool'"
    [loading]="loading"
    [value]="dressingMonitorIntervalKPIs?.lifeTimePerToolInterval?.value"
    [decimalPlaces]="0"
  ></dms-frontend-tile-value-with-ref-value>
</div>
<h5>Dressing</h5>
<!-- ROW 2-->
<div class="flex flex-row pb-4">
  <dms-frontend-tile-value-with-ref-value
    [headline]="'Periodic #'"
    [loading]="loading"
    [value]="
      dressingMonitorIntervalKPIs?.periodicDressingCounterInterval?.value
    "
    [decimalPlaces]="0"
  ></dms-frontend-tile-value-with-ref-value>
  <dms-frontend-tile-value-with-ref-value
    class="pl-4"
    [headline]="'Manual #'"
    [loading]="loading"
    [value]="dressingMonitorIntervalKPIs?.manualDressingCounterInterval?.value"
    [decimalPlaces]="0"
  ></dms-frontend-tile-value-with-ref-value>
  <dms-frontend-tile-value-with-ref-value
    class="pl-4"
    [headline]="'Manual [mm]'"
    [loading]="loading"
    [value]="dressingMonitorIntervalKPIs?.manualDressingSumInterval?.value"
    [decimalPlaces]="3"
  ></dms-frontend-tile-value-with-ref-value>
</div>
<h5>Diameter at Change</h5>
<div class="flex flex-row">
  <dms-frontend-tile-value-with-ref-value
    [headline]="'Min [mm]'"
    [loading]="loading"
    [value]="
      dressingMonitorIntervalKPIs?.grindingWheelDiameterAtChangeIntervalMin
        ?.value
    "
    [decimalPlaces]="2"
  ></dms-frontend-tile-value-with-ref-value>
  <dms-frontend-tile-value-with-ref-value
    class="pl-4"
    [headline]="'Max [mm]'"
    [loading]="loading"
    [value]="
      dressingMonitorIntervalKPIs?.grindingWheelDiameterAtChangeIntervalMax
        ?.value
    "
    [decimalPlaces]="2"
  ></dms-frontend-tile-value-with-ref-value>
  <dms-frontend-tile-value-with-ref-value
    class="pl-4"
    [headline]="'Avg [mm]'"
    [loading]="loading"
    [value]="
      dressingMonitorIntervalKPIs?.grindingWheelDiameterAtChangeIntervalAvg
        ?.value
    "
    [decimalPlaces]="2"
  ></dms-frontend-tile-value-with-ref-value>
</div>
