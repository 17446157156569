import { MachinesService } from './machines.service'
export * from './machines.service'

import { ReferenceValuesService } from './referenceValues.service'
export * from './referenceValues.service'

export * from './kpi.service'
import { KpiService } from './kpi.service'

export * from './allowanceMonitor.service'
import { AllowanceMonitorService } from './allowanceMonitor.service'

export * from './cycleReferencedProcessValues.service'
import { CycleReferencedProcessValuesService } from './cycleReferencedProcessValues.service'


export * from './mesInfo.service'
import { MESInfoService } from './mesInfo.service'


export const APIS = [
  AllowanceMonitorService,
  CycleReferencedProcessValuesService,
  KpiService,
  MachinesService,
  MESInfoService,
  ReferenceValuesService,
]
