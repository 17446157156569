import { HistogramComponent } from './histogram.component'
import * as helper from './../helper/histogram.helper'

function convertToMillimeter(
  chartData: helper.LineChartData[]
): helper.LineChartData[] {
  return chartData.map((point) => {
    return { x: point.x * 1000, y: point.y }
  })
}

export function setChartData(histogram: HistogramComponent): void {
  histogram.chartData = {
    labels: histogram.xAxisLabel,
    datasets: [
      {
        type: 'line',
        label: 'Limits current allowance',
        borderDash: [6, 6],
        backgroundColor: 'rgba(0,0,0,0)',
        pointStyle: 'dash',
        borderWidth: 1,
        borderColor: 'red',
        data: convertToMillimeter(histogram.limitCurrentAllowanceChartData),
      },
      {
        type: 'line',
        label: 'Allowance position',
        backgroundColor: 'rgba(0,0,0,0)',
        pointStyle: 'dash',
        borderWidth: 1,
        borderColor: 'black',
        data: histogram.referenceAllowancePositionChartData,
      },
      {
        type: 'line',
        label: 'Reference allowance position',
        borderDash: [6, 6],
        backgroundColor: 'rgba(0,0,0,0)',
        pointStyle: 'dash',
        borderWidth: 1,
        borderColor: 'black',
        data: convertToMillimeter(histogram.referenceAllowanceChartData),
      },
      {
        type: 'line',
        label: 'Suggestion allowance position',
        borderDash: [6, 6],
        backgroundColor: 'rgba(0,0,0,0)',
        pointStyle: 'dash',
        borderWidth: 1,
        borderColor: 'blue',
        data: histogram.suggestionAllowancePositionChartData,
      },
      {
        label: 'current Allowance',
        data: histogram.histogramData,
        backgroundColor: '#00893d',
        hoverBackgroundColor: '#00893d',
        categoryPercentage: 0.5,
        barPercentage: 1,
      },
    ],
  }
}
