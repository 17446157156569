import { Component } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'dms-frontend-process-config',
  templateUrl: './process-config.component.html',
})
export class ProcessConfigComponent {
  loading$ = new BehaviorSubject<boolean>(false)

  anyDiagramRefreshable = false
  subsystemDiagramLoading = false

  // Lazy load timing diagram to avoid network traffic
  showSubsystemDiagram = false

  loadSubsystemDiagram(): void {
    this.showSubsystemDiagram = !this.showSubsystemDiagram
    if (!this.showSubsystemDiagram) {
      this.subsystemDiagramLoading = false
    }
    this.anyDiagramLoadable()
  }

  anyDiagramLoadable(): void {
    this.anyDiagramRefreshable = this.showSubsystemDiagram
  }
}
