<div class="container relative">
  <div id="relative cycle-time-chart-container">
    <canvas
      #cycleTimeChart
      id="cycle-time-chart"
      baseChart
      [data]="chartData"
      [type]="'scatter'"
      [options]="chartOptions"
      [plugins]="chartPlugins"
    >
    </canvas>
    <canvas id="cycle-time-tip" width="0" height="0"></canvas>
    <mat-progress-spinner
      *ngIf="loading$ | async"
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      [mode]="'indeterminate'"
    >
    </mat-progress-spinner>
  </div>
</div>
