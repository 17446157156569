import { Component, Input } from '@angular/core'
import { CategoryEnum, EquipmentStates } from '@dms-frontend/tds/services/api'

@Component({
  selector: 'dms-frontend-status-tile',
  templateUrl: './status-tile.component.html',
  styleUrls: ['./status-tile.component.scss']
})
export class StatusTileComponent {
  @Input() eqState!: EquipmentStates

  /**
   * Indicates current error within equipment number
   * @returns true on current error within equipment number else false
   */
  error(): boolean {
    if (this.eqState.states == null || this.eqState.states.length === 0) {
      return false
    }

    // Return true
    return !this.eqState.states[0].ok
  }

  /**
   * Indicates an error within category of this equipment number
   * @param category technology category within equipment to be checked for errors
   * @returns error
   */
  private errorInCategory(category: CategoryEnum): boolean {
    return (
      this.eqState.states.filter((el) => {
        return el.category === category
      }).length > 0
    )
  }

  /**
   * Indicates an error within measurement monitor of this equipment number
   * @returns error
   */
  errorMeasurementMonitor(): boolean {
    return this.errorInCategory(CategoryEnum.AllowanceMonitor)
  }

  /**
   * Indicates an error caused by no data
   * @returns error
   */
  errorNoDataFound(): boolean {
    return this.errorInCategory(CategoryEnum.NoDataFound)
  }

  errorCount(): number | string {
    if (this.eqState.states == null) {
      return 0
    }

    if (this.errorInCategory(CategoryEnum.NoDataFound)) {
      return 'No Data'
    }

    return this.eqState.states[0].states
      .map((state) => {
        return state.value === true
      })
      .filter((ok) => !ok).length
  }
}
