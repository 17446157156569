import { Order } from '@dms-frontend/tds/services/api'
import { Subject } from 'rxjs'
import { MesChartComponent } from '../mes-chart/mes-chart.component'
import { HttpErrorResponse } from '@angular/common/http'

export function retrieveOrders(
  component: MesChartComponent,
  from: Date,
  to: Date,
  workCenterID: number
): Subject<Order[]> {
  const orders$ = new Subject<Order[]>()
  component.mesInfoService
    .mesOrderWcGet(
      workCenterID.toString(),
      from.toISOString(),
      to.toISOString()
    )
    .subscribe(
      (orders) => {
        const returnOrders =
          orders == null || orders.length === 0
            ? (orders = [
              {
                id: -1,
                start: from.toISOString(),
                end: to.toISOString(),
                materialnumber: 'no order available',
                materialtext: 'no order available',
                targetcycletime: -1,
                targetcycletimeunit: '',
                targetcycletimefactor: -1,
                targetquantity: -1,
                referencecycletimetotalorder: -1,
              },
            ])
            : orders
        orders$.next(returnOrders)
      },
      (error: HttpErrorResponse) => {     
        component.snackBar.open(`${error.status}: ${error.statusText}` , 'Close')
        orders$.error(error)
      }
    )
  return orders$
}
