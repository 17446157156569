import {
  AllowanceMonitorKPIs,
  Order,
  Parameter,
  ReferenceValues,
  Result,
  sortedReferenceValues,
} from '@dms-frontend/tds/services/api'
import {
  Observable,
  Subject,
  catchError,
  delay,
  forkJoin,
  map,
  of,
  timeout,
} from 'rxjs'
import { DECIMAL_PLACES, TSValue } from '../../config/config'
import { CycleTimeGapGrindingDiagramComponent } from '../cycle-time-gap-grinding-diagram.component'
import { untilDestroyed } from '@ngneat/until-destroy'
import { HttpErrorResponse } from '@angular/common/http'

export function retrieveReferenceCycleTimeGapGrinding(
  component: CycleTimeGapGrindingDiagramComponent,
  eq: number,
  orders: Order[]
): Subject<boolean> {
  const finished$ = new Subject<boolean>()
  if (orders.length === 0) {
    of(true)
      .pipe(timeout(10))
      .subscribe((val) => {
        finished$.next(true)
      })
    return finished$
  }

  const referenceCycleTimeGapGrindingData: TSValue[] = []
  const obs: Observable<ReferenceValues>[] = []
  for (const element in orders) {
    if (Object.prototype.hasOwnProperty.call(orders, element)) {
      const order = orders[element]
      let endDate = order.end
      if (endDate === '') {
        endDate = component.maxDate.toISOString()
      }
      // MAP error in request to be retruned as 0 Values
      const observable =
        component.referenceValues.referencevaluesEqMaterialnumberGet(
          eq.toString(),
          order.materialnumber
        )
      obs.push(observable)
    }
  }
  forkJoin(obs)
    .pipe(untilDestroyed(component))
    .subscribe({
      next: (obs) => {
        for (const referenceValues of obs) {
          if (referenceValues == null) {
            continue
          }
          const values = sortedReferenceValues(referenceValues.values)
          let referenceCycleTimes = values
            .filter(
              (refValue) =>
                refValue.name === 'ReferenceCycleTimeGapGrinding' &&
                !Number.isNaN(parseFloat(refValue.value))
            )
            .map((el) => {
              return {
                ts: new Date(el.validSince),
                value: parseFloat(parseFloat(el.value).toFixed(DECIMAL_PLACES)),
              }
            })
            .reverse()

          let priorReferenceValue = null
          for (const referenceCycleTime of referenceCycleTimes) {
            if (referenceCycleTime.ts.valueOf() > component.minDate.valueOf()) {
              break
            }
            priorReferenceValue = referenceCycleTime
          }
          if (priorReferenceValue != null) {
            referenceCycleTimes = referenceCycleTimes.filter((el) => {
              return (
                el.ts.valueOf() > component.minDate.valueOf() &&
                el.ts.valueOf() < component.maxDate.valueOf()
              )
            })

            referenceCycleTimes = [
              { ts: component.minDate, value: priorReferenceValue.value },
              ...referenceCycleTimes,
            ]

            for (const el of referenceCycleTimes) {
              referenceCycleTimeGapGrindingData.push({
                ts: el.ts.toISOString(),
                value: el.value,
              })
            }
          }
          component.upperLimitCycleTimeGapGrinding =
            referenceCycleTimeGapGrindingData
        }
        finished$.next(true)
      },
      error: (err) => {
        throw err
      },
    })
  return finished$
}

export function retrieveKPIs(
  component: CycleTimeGapGrindingDiagramComponent,
  eq: number,
  orders: Order[]
): Subject<boolean> {
  const finished$ = new Subject<boolean>()
  if (orders.length === 0) {
    of(true)
      .pipe(timeout(10))
      .subscribe((el) => {
        finished$.next(true)
      })
    return finished$
  }
  const averageCurrentData: TSValue[] = []
  const suggestionsAllowancePositions: Result[] = []
  const allowanceMonitorKPIObs$: Observable<AllowanceMonitorKPIs>[] = []
  const allowanceMonitorKPIDummy: AllowanceMonitorKPIs = {
    allowancePosition: undefined,
    averageCurrentAllowance: undefined,
    averageCycleTimeProcessing: undefined,
    averageGapTimeActual: undefined,
    cycleTimeProcessing: undefined,
    deltaTWithSuggestionAllowancePosition: undefined,
    suggestionAllowancePosition: undefined,
  }

  for (const order of orders) {
    let endDate = order.end
    if (endDate === '') {
      endDate = component.maxDate.toISOString()
    }
    let startDate = order.start
    if (new Date(startDate) < component.minDate) {
      startDate = component.minDate.toISOString()
    }
    // MAP error in request to be retruned as 0 Values
    const averageCurrentAllowance$ = component.allowanceKpi
      .kpiAllowancemonitorEqGet(eq.toString(), startDate, endDate)
      .pipe(
        map((res) => res),
        catchError((e) => of(allowanceMonitorKPIDummy))
      )
    allowanceMonitorKPIObs$.push(averageCurrentAllowance$)
    averageCurrentData.push({
      ts: startDate,
      value: NaN,
    })
    suggestionsAllowancePositions.push({
      ts: startDate,
      value: NaN,
    })
  }
  forkJoin(allowanceMonitorKPIObs$)
    .pipe(untilDestroyed(component))
    .subscribe({
      next: (allowanceMonitorKPIs) => {
        for (const [i, v] of allowanceMonitorKPIs.entries()) {
          // Writing data for average current allowance
          if (v.averageGapTimeActual === undefined) {
            averageCurrentData[i].value = NaN
          } else {
            averageCurrentData[i].value = v.averageGapTimeActual.value
          }
          // Writing data for suggestion allowance position
          if (v.deltaTWithSuggestionAllowancePosition) {
            for (const iterator of v.deltaTWithSuggestionAllowancePosition) {
              suggestionsAllowancePositions.push(iterator)
            }
          }
        }
        // Get correct end date
        let endDate = orders.slice(-1)[0].end
        if (endDate === '') {
          endDate = component.maxDate.toISOString()
        }

        if (suggestionsAllowancePositions.length !== 0) {
          suggestionsAllowancePositions.push({
            ts: endDate,
            value: suggestionsAllowancePositions.slice(-1)[0].value,
          })
        }
        component.deltaTWithSuggestionAllowancePosition =
          suggestionsAllowancePositions

        if (averageCurrentData.length !== 0) {
          averageCurrentData.push({
            ts: endDate,
            value: averageCurrentData.slice(-1)[0].value,
          })
        }
        component.averageCycleTimeGapGrinding = averageCurrentData
        finished$.next(true)
      },
      error: (err) => {
        finished$.error(err)
      },
    })
  return finished$
}

export function retrieveCycleTimeGapGrinding(
  component: CycleTimeGapGrindingDiagramComponent,
  from: Date,
  to: Date,
  eq: number
): Subject<boolean> {
  const finished$ = new Subject<boolean>()

  component.cycleReferencedValues
    .cycleEqGet(eq.toString(), 'CycleTimeGapGrinding', from.toISOString(), to.toISOString())
    .pipe(delay(1000))
    .pipe(untilDestroyed(component))
    .subscribe(
      (cycles) => {
        
        if (
          cycles == null ||
          cycles.cycles == null ||
          cycles.cycles.length === 0
        ) {
          of(1)
            .pipe(timeout(10))
            .subscribe((val) => {
              finished$.next(true)
            })
          return
        }
        const cycleValues = cycles.cycles
        // Get and set cycle time gap grinding values and calculate average cycle time gap grinding
        const gapTimes = cycleValues
          .map((cycle) => {

            const cycleTimeGapGrinding = cycle.parameters.filter(
              (parameter) => {
                return parameter.name === 'CycleTimeGapGrinding'
              }
            )[0] as Parameter

            return cycleTimeGapGrinding.double === undefined
              ? null
              : {
                ts: cycle.ts,
                value: parseFloat(
                  (cycleTimeGapGrinding.double).toFixed(DECIMAL_PLACES)
                ),
              }
          })
          .filter((el) => el != null) as TSValue[]

        component.cycleTimeGapGrinding = gapTimes
        finished$.next(true)
      },
      (error: HttpErrorResponse) => {
        component.snackBar.open(`${error.status}: ${error.statusText}` , 'Close')
        finished$.error(error)
      }
    )

  return finished$
}
