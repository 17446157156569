/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { ChartData } from 'chart.js'
import { BaseChartDirective } from 'ng2-charts'
import {
  BehaviorSubject,
  Subject,
  combineLatest,
  combineLatestWith,
  of,
  skip,
  take,
  timeout,
} from 'rxjs'
import * as helper from './../helper/histogram.helper'
import { buildHistogramPlugin } from './histogramPlugin'
import { MachineSelectionService } from '@dms-frontend/shared/services/machine-selection'
import {
  AllowanceMonitorKPIs,
  AllowanceMonitorService,
  CycleReferencedProcessValuesService,
  MESInfoService,
  Order,
  Parameter,
  ReferenceValueNames,
  ReferenceValues,
  ReferenceValuesService,
  Result,
  lowerLimitsCurrentAllowance,
  upperLimitsCurrentAllowance,
} from '@dms-frontend/tds/services/api'
import { TimeSelectionService } from '@dms-frontend/shared/services/time-selection'
import { setChartOptions } from './chart-options'
import { setChartData } from './chart-data'
import { DashboardService } from '@dms-frontend/tds/services/dashboard'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { MesChartComponent, retrieveOrders } from '@dms-frontend/tds/ui/charts'
import { HttpErrorResponse } from '@angular/common/http'
import { MatSnackBar } from '@angular/material/snack-bar'

@UntilDestroy()
@Component({
  selector: 'dms-frontend-histogram',
  templateUrl: './histogram.component.html',
  styleUrls: ['./histogram.component.scss'],
})
export class HistogramComponent extends MesChartComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart!: BaseChartDirective

  // #region ChartJs properties
  override chartData!: ChartData<'bar' | 'line'>
  // #endregion

  // #region Histogram list properties
  maximumAllowance: number
  averageAllowance: number
  minimumAllowance: number
  suggestionAllowance: number
  // #endregion

  // #region Histogram calculation helper properties
  allowanceData: number[] = []
  histogramData: number[] = []
  xAxisLabel: number[] = []
  // #endregion

  // #region Chart datasets and Backendrequest related properties
  // DeltaTSmallerThanTwoHours = false

  // Reference Allowance
  private _referenceAllowance: number[] = []
  get referenceAllowance(): number[] {
    return this._referenceAllowance
  }

  set referenceAllowance(array: number[]) {
    this._referenceAllowance = array
    this.referenceAllowanceChartData = helper.fillLineChartData(array)
  }

  private _referenceAllowanceChartData: helper.LineChartData[] = []
  get referenceAllowanceChartData(): helper.LineChartData[] {
    return this._referenceAllowanceChartData
  }

  set referenceAllowanceChartData(array: helper.LineChartData[]) {
    this._referenceAllowanceChartData = array
    this.updateChart(this.allowanceControl.value)
  }

  // Reference Allowance Position
  private _referenceAllowancePosition: number[] = []
  get referenceAllowancePosition(): number[] {
    return this._referenceAllowancePosition
  }

  set referenceAllowancePosition(array: number[]) {
    this._referenceAllowancePosition = array
    this.referenceAllowancePositionChartData = helper.fillLineChartData(array)
  }

  private _referenceAllowancePositionChartData: helper.LineChartData[] = []
  get referenceAllowancePositionChartData(): helper.LineChartData[] {
    return this._referenceAllowancePositionChartData
  }

  set referenceAllowancePositionChartData(array: helper.LineChartData[]) {
    this._referenceAllowancePositionChartData = array
    this.updateChart(this.allowanceControl.value)
  }

  // Suggestion Allowance Position
  private _suggestionAllowancePosition: number[] = []
  get suggestionAllowancePosition(): number[] {
    return this._suggestionAllowancePosition
  }

  set suggestionAllowancePosition(array: number[]) {
    this._suggestionAllowancePosition = array
    this.suggestionAllowancePositionChartData = helper.fillLineChartData(array)
  }

  private _suggestionAllowancePositionChartData: helper.LineChartData[] = []
  get suggestionAllowancePositionChartData(): helper.LineChartData[] {
    return this._suggestionAllowancePositionChartData
  }

  set suggestionAllowancePositionChartData(array: helper.LineChartData[]) {
    this._suggestionAllowancePositionChartData = array
    this.updateChart(this.allowanceControl.value)
  }

  // Limit Current Allowance
  private _limitCurrentAllowance: number[] = []
  get limitCurrentAllowance(): number[] {
    return this._limitCurrentAllowance
  }

  set limitCurrentAllowance(array: number[]) {
    this._limitCurrentAllowance = array
    this.limitCurrentAllowanceChartData = helper.fillLineChartData(array)
  }

  private _limitCurrentAllowanceChartData: helper.LineChartData[] = []
  get limitCurrentAllowanceChartData(): helper.LineChartData[] {
    return this._limitCurrentAllowanceChartData
  }

  set limitCurrentAllowanceChartData(array: helper.LineChartData[]) {
    this._limitCurrentAllowanceChartData = array
    this.updateChart(this.allowanceControl.value)
  }
  // #endregion

  // Allowance group
  allowanceControl = new FormControl()
  loading$ = new BehaviorSubject<boolean>(false)

  constructor(
    private cycleService: CycleReferencedProcessValuesService,
    public allowanceKpi: AllowanceMonitorService,
    private referenceValuesService: ReferenceValuesService,
    private timeSelection: TimeSelectionService,
    public override snackBar: MatSnackBar,
    @Inject(DashboardService) dashboardService: DashboardService,
    @Inject(MachineSelectionService) machineSelection: MachineSelectionService,
    @Inject(MESInfoService) mesInfoService: MESInfoService
  ) {
    super(dashboardService, machineSelection, mesInfoService, snackBar)
    this.loading$.pipe(untilDestroyed(this)).subscribe((loading) => {
      this.dashboardService.setCycleTimeDiagramLoading(loading)
    })

    // #region will be removed as soon as backend is ready
    this.maximumAllowance = 0
    this.averageAllowance = 0
    this.minimumAllowance = 0
    this.suggestionAllowance = 0
    // #endregion
  }

  ngOnInit(): void {
    const histogramPlugin = buildHistogramPlugin
    this.chartPlugins.push(histogramPlugin)
    this.refresh()
    this.allowanceControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.updateChart(+value)
      })

    const from$ = this.timeSelection.from$
    const to$ = this.timeSelection.to$
    const eq$ = this.machineSelection.selectedEquipment$
    const workCenterID$ = this.machineSelection.selectedWorkCenterID$
    // Get first eq, from, to and workCenterID and refresh diagram
    combineLatest([eq$, from$, to$, workCenterID$])
      .pipe(take(1))
      .pipe(untilDestroyed(this))
      .subscribe(([eq, from, to, workCenterID]) => {
        if (this.minDate.valueOf() !== from.valueOf()) {
          this.minDate = from
        }
        if (this.maxDate.valueOf() !== to.valueOf()) {
          this.maxDate = to
        }
        if (this.eq !== eq) {
          this.eq = eq
        }
        if (this.workCenterID !== workCenterID) {
          this.workCenterID = workCenterID
        }
        this.refresh()
      })
    // Get all further eq, from, to and workCenterID for further diagram refreshes
    combineLatest([eq$, from$, to$, workCenterID$])
      .pipe(skip(1))
      .pipe(untilDestroyed(this))
      .subscribe(([eq, from, to, workCenterID]) => {
        if (this.minDate.valueOf() !== from.valueOf()) {
          this.minDate = from
        }
        if (this.maxDate.valueOf() !== to.valueOf()) {
          this.maxDate = to
        }
        if (this.eq !== eq) {
          this.eq = eq
        }
        if (this.workCenterID !== workCenterID) {
          this.workCenterID = workCenterID
        }
      })

    this.dashboardService.refresh$
      .pipe(untilDestroyed(this))
      .subscribe((refresh) => {
        this.refresh()
      })

    setChartData(this)
    setChartOptions(this)
  }

  refresh(): void {
    setChartOptions(this)
    if (this.eq != null && this.workCenterID != null) {
      this.retrieveDataFromBackend(
        this.minDate,
        this.maxDate,
        this.eq,
        this.workCenterID
      )
    }
  }

  retrieveDataFromBackend(
    from: Date,
    to: Date,
    eq: number,
    workCenterID: number
  ): void {
    this.loading$.next(true)
    this.resetAll()
    const cycleDataRetrieved$ = this.getCycleData(from, to, eq)
    const kpiDataRetrieved$ = this.getKpiData(from, to, eq)
    const orders$ = retrieveOrders(this, from, to, workCenterID)
    const refValuesFinished$ = new Subject<boolean>()

    orders$.pipe(untilDestroyed(this)).subscribe(
      (orders) => {
        if (orders != null && orders.length !== 0) {
          const referenceValuesRetrieved$ = this.getReferenceValueData(
            from,
            to,
            eq,
            orders
          )
          referenceValuesRetrieved$.pipe(untilDestroyed(this)).subscribe(
            (val) => {
              refValuesFinished$.next(true)
            },
            (error) => {
              refValuesFinished$.next(true)
            }
          )
        } else {
          refValuesFinished$.next(true)
        }
      },
      (error) => {
        refValuesFinished$.next(true)
      }
    )

    cycleDataRetrieved$
      .pipe(combineLatestWith([kpiDataRetrieved$, refValuesFinished$]))
      .pipe(untilDestroyed(this))
      .subscribe(
        (finishedArray) => {
          const finishedRetrieving = finishedArray.reduce((prev, cur) => {
            return prev && cur
          })
          if (finishedRetrieving) {
            this.loading$.next(false)
          }
        },
        (error: HttpErrorResponse) => {
          this.snackBar.open(`${error.status}: ${error.statusText}` , 'Close')
          this.loading$.next(false)
        }
      )
  }

  resetAll(): void {
    this.allowanceData = []
    this.maximumAllowance = 0
    this.minimumAllowance = 0
    this.allowanceControl.setValue(0)
    this.averageAllowance = 0
    this.suggestionAllowance = 0
    this.suggestionAllowancePosition = []
    this.referenceAllowance = []
    this.referenceAllowancePosition = []
    this.limitCurrentAllowance = []
    this.updateChart(1)
  }

  getCycleData(from: Date, to: Date, eq: number): Subject<boolean> {
    const finished$ = new Subject<boolean>()
    this.cycleService
      .cycleEqGet(eq.toString(),'CurrentAllowance', from.toISOString(), to.toISOString())
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (values) => {
          if (
            values == null ||
            values.cycles == null ||
            values.cycles.length === 0
          ) {
            of(1)
              .pipe(timeout(10))
              .subscribe((val) => {
                finished$.next(true)
              })
            return
          }
          
          const cycles = values
          this.allowanceData = cycles.cycles.map((cycle) => {
            const currentAllowance = cycle.parameters.filter(
              (parameter) => {
                return parameter.name === 'CurrentAllowance'
              }
            )[0] as Parameter
            if(currentAllowance.double === undefined) {
              return NaN
            }
            return +currentAllowance.double * 1000
          })
          this.maximumAllowance = +Math.max(...this.allowanceData).toFixed(3)
          this.minimumAllowance = +Math.min(...this.allowanceData).toFixed(3)
          let xAxisInterval
          if (this.maximumAllowance < 20) {
            xAxisInterval = +(this.maximumAllowance / 20).toFixed(3)
          } else {
            xAxisInterval = helper.round(this.maximumAllowance / 20, -3)
          }
          this.allowanceControl.setValue(xAxisInterval)
          this.updateChart(xAxisInterval)
          finished$.next(true)
        },
        error: (error: HttpErrorResponse) => {
          this.snackBar.open(`${error.status}: ${error.statusText}` , 'Close')
          finished$.error(error)
        },
      })
    return finished$
  }

  getKpiData(from: Date, to: Date, eq: number): Subject<boolean> {
    const finished$ = new Subject<boolean>()
    this.allowanceKpi
      .kpiAllowancemonitorEqGet(
        eq.toString(),
        from.toISOString(),
        to.toISOString()
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        (allowanceMonitorKPIs: AllowanceMonitorKPIs) => {
          if (allowanceMonitorKPIs.averageCurrentAllowance === undefined) {
            this.averageAllowance = NaN
          } else {
            this.averageAllowance = helper.round(
              +allowanceMonitorKPIs.averageCurrentAllowance.value * 1000,
              3
            )
          }
          if (allowanceMonitorKPIs.suggestionAllowancePosition === undefined) {
            this.suggestionAllowance = NaN
            this.suggestionAllowancePosition = [NaN]
          } else {
            this.suggestionAllowance = helper.round(
              allowanceMonitorKPIs.suggestionAllowancePosition.value * 1000,
              3
            )
            this.suggestionAllowancePosition = [this.suggestionAllowance]
          }
          if (!allowanceMonitorKPIs.allowancePosition) {
            this.referenceAllowancePosition = [NaN]
          } else {
            const length = allowanceMonitorKPIs.allowancePosition.length
            this.referenceAllowancePosition = [
              helper.round(
                allowanceMonitorKPIs.allowancePosition[length - 1].value * 1000,
                3
              ),
            ]
          }
          this.updateChart(this.allowanceControl.value)
          finished$.next(true)
        },
        (error: HttpErrorResponse) => {
          this.snackBar.open(`${error.status}: ${error.statusText}` , 'Close')
          finished$.error(error)
        }
      )

    return finished$
  }

  getReferenceValueData(
    fromDate: Date,
    toDate: Date,
    eq: number,
    orders: Order[]
  ): Subject<boolean> {
    const finished$ = new Subject<boolean>()
    const latestOrder = orders.slice(-1)[0]
    let endDate = latestOrder.end
    if (endDate === '') {
      endDate = toDate.toISOString()
    }
    const to = new Date(endDate)
    let from = new Date(latestOrder.start)
    if (orders.length < 2) {
      from = fromDate
    }
    const matNumber = isNaN(parseInt(latestOrder.materialnumber, 10))
      ? '0'
      : latestOrder.materialnumber
    this.referenceValuesService
      .referencevaluesEqMaterialnumberGet(eq.toString(), matNumber)
      .pipe(untilDestroyed(this))
      .subscribe(
        (referenceValues) => {
          if (!referenceValues) {
            finished$.next(true)
            return
          }
          this.referenceAllowance = this._insertReferenceValue(
            referenceValues,
            from,
            to,
            ReferenceValueNames.ReferenceAllowancePosition
          )
          this.limitCurrentAllowance = this._calculateLimits(
            referenceValues,
            from,
            to
          )
          finished$.next(true)
        },
        (error: HttpErrorResponse) => {
          this.snackBar.open(`${error.status}: ${error.statusText}` , 'Close')
          finished$.error(error)
        }
      )

    return finished$
  }

  updateChart(allowanceNumber: number): void {
    if (
      (allowanceNumber < 10 && this.maximumAllowance >= 20) ||
      allowanceNumber === 0 ||
      allowanceNumber == null ||
      allowanceNumber === undefined
    ) {
      return
    }
    this.histogramData = []
    this.xAxisLabel = []
    const chartStepSize = this.maximumAllowance / allowanceNumber
    for (let i = 0; i < chartStepSize + 1; i++) {
      this.xAxisLabel[i] = i * allowanceNumber
      this.histogramData[i] = 0
    }
    helper.generateHistogram(
      this.allowanceData,
      this.histogramData,
      allowanceNumber,
      this.xAxisLabel
    )
    setChartData(this)
    setChartOptions(this)
  }

  private _calculateLimits(
    referenceValues: ReferenceValues,
    from: Date,
    to: Date
  ): number[] {
    const filteredForReferenceTolarance = referenceValues.values
      .filter((refValue) => {
        return (
          refValue.name === ReferenceValueNames.ReferenceAllowanceTolerance &&
          new Date(refValue.validSince) <= to
        )
      })
      .map((refValue) => {
        return { ts: refValue.validSince, value: +refValue.value } as Result
      })

    const filteredForReferenceAllowance = referenceValues.values
      .filter((refValue) => {
        return (
          refValue.name === ReferenceValueNames.ReferenceAllowance &&
          new Date(refValue.validSince) <= to
        )
      })
      .map((refValue) => {
        return { ts: refValue.validSince, value: +refValue.value } as Result
      })

    const upperLimit = upperLimitsCurrentAllowance(
      filteredForReferenceAllowance,
      filteredForReferenceTolarance
    )

    let firstValidUpperLimit = new Date(0)
    for (let i = 0; i < upperLimit.length; i++) {
      firstValidUpperLimit = new Date(upperLimit[i].ts)
      if (new Date(upperLimit[i].ts) >= from) {
        break
      }
    }

    const lowerLimit = lowerLimitsCurrentAllowance(
      filteredForReferenceAllowance,
      filteredForReferenceTolarance
    )

    let firstValidLowerLimit = new Date(0)
    for (let i = 0; i < lowerLimit.length; i++) {
      firstValidLowerLimit = new Date(lowerLimit[i].ts)
      if (new Date(lowerLimit[i].ts) >= from) {
        break
      }
    }

    const finalUpperLimit = upperLimit
      .filter(
        (limit) =>
          new Date(limit.ts).valueOf() >= firstValidUpperLimit.valueOf()
      )
      .map((limit) => limit.value)

    const finalLowerLimit = lowerLimit
      .filter(
        (limit) =>
          new Date(limit.ts).valueOf() >= firstValidLowerLimit.valueOf()
      )
      .map((limit) => limit.value)

    return finalLowerLimit.concat(finalUpperLimit)
  }

  private _insertReferenceValue(
    referenceValues: ReferenceValues,
    from: Date,
    to: Date,
    referenceValueName: ReferenceValueNames
  ): number[] {
    let extractedReferenceValues: number[] = []
    if (referenceValues) {
      const filteredReferenceValues = referenceValues.values.filter(
        (refValue) => {
          return (
            refValue.name === referenceValueName &&
            new Date(refValue.validSince) <= to
          )
        }
      )

      // Get latest referenceValue
      const latestReferenceTime = Math.max(
        ...filteredReferenceValues.map((pos) =>
          new Date(pos.validSince).getTime()
        )
      )

      // Add either latest or corresponding referenceValues
      if (latestReferenceTime < from.getTime()) {
        const latestReference = filteredReferenceValues.filter((refValue) => {
          return new Date(refValue.validSince).getTime() === latestReferenceTime
        })
        extractedReferenceValues.push(+latestReference[0].value)
      } else {
        extractedReferenceValues = filteredReferenceValues
          .filter((refValue) => {
            return new Date(refValue.validSince) > from
          })
          .map((data) => +data.value)
      }
    }
    return extractedReferenceValues
  }
}
