/**
 * MES info
 * This API manages MES information.
 *
 * OpenAPI spec version: 0.4.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Order {
  id: number
  start: string
  end: string
  materialtext: string
  materialnumber: string
  targetcycletime: number
  targetcycletimeunit: string
  targetcycletimefactor: number
  /**
   * Unit specified in targetcycletimeunit
   */
  targetquantity: number
  /**
   * Unit is a SI unit
   */
  referencecycletimetotalorder: number
}
