import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  SharedAzureAuthModule,
} from '@dms-frontend/shared/services/azure-auth'

@NgModule({
  imports: [CommonModule, SharedAzureAuthModule],
})
export class SharedGuardsModule {}
