import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core'
import { FormControl } from '@angular/forms'
import { ReferenceValueNames } from '@dms-frontend/tds/services/api'
import { ReferenceValueDummy } from '../shared/reference-value-helper'

@Component({
  selector: 'dms-frontend-reference-values',
  templateUrl: './reference-values.component.html',
  styleUrls: ['./reference-values.component.scss'],
})
export class ReferenceValuesComponent implements OnInit, OnChanges {
  // True if component is in a valid state.
  @Output() validChange = new EventEmitter<boolean>()
  private _valid = true

  @Input() referenceValues: ReferenceValueDummy[] = []
  @Output() referenceValuesChange = new EventEmitter<ReferenceValueDummy[]>()

  allowancePositionControl = new FormControl()
  allowanceControl = new FormControl()
  toleranceControl = new FormControl()
  grindingTimeControl = new FormControl()

  allowancePositionValidSinceControl = new FormControl()
  allowanceValidSinceControl = new FormControl()
  toleranceValidSinceControl = new FormControl()
  grindingTimeValidSinceControl = new FormControl()

  ngOnInit(): void {
    // Store last valid state of this component to this._valid
    this.validChange.subscribe((valid) => {
      this._valid = valid
    })

    // Initialise each reference value and its valid since input
    this._initValidSince(
      this.allowancePositionValidSinceControl,
      [
        this.allowanceValidSinceControl,
        this.toleranceValidSinceControl,
        this.grindingTimeValidSinceControl,
      ],
      this.allowancePositionControl,
      ReferenceValueNames.ReferenceAllowancePosition
    )

    this._initValidSince(
      this.allowanceValidSinceControl,
      [
        this.allowancePositionValidSinceControl,
        this.toleranceValidSinceControl,
        this.grindingTimeValidSinceControl,
      ],
      this.allowanceControl,
      ReferenceValueNames.ReferenceAllowance
    )

    this._initValidSince(
      this.toleranceValidSinceControl,
      [
        this.allowancePositionValidSinceControl,
        this.allowanceValidSinceControl,
        this.grindingTimeValidSinceControl,
      ],
      this.toleranceControl,
      ReferenceValueNames.ReferenceAllowanceTolerance
    )

    this._initValidSince(
      this.grindingTimeValidSinceControl,
      [
        this.allowancePositionValidSinceControl,
        this.allowanceValidSinceControl,
        this.toleranceValidSinceControl,
      ],
      this.grindingTimeControl,
      ReferenceValueNames.ReferenceCycleTimeGapGrinding
    )

    const now = new Date()

    this.allowancePositionValidSinceControl.setValue(now.toISOString())
    this.allowancePositionControl.setValue('')
    this.allowanceValidSinceControl.setValue(now.toISOString())
    this.allowanceControl.setValue('')
    this.toleranceValidSinceControl.setValue(now.toISOString())
    this.toleranceControl.setValue('')
    this.grindingTimeValidSinceControl.setValue(now.toISOString())
    this.grindingTimeControl.setValue('')
  }

  ngOnChanges(): void {
    // Get the allowance position, allowance, tolerance and grinding time and
    // Set the values of the according FormControls
    const referenceAllowancePosition = this._getReferenceValue(
      ReferenceValueNames.ReferenceAllowancePosition
    )
    const referenceAllowance = this._getReferenceValue(
      ReferenceValueNames.ReferenceAllowance
    )
    const referenceTolerance = this._getReferenceValue(
      ReferenceValueNames.ReferenceAllowanceTolerance
    )
    const referenceGrindingTime = this._getReferenceValue(
      ReferenceValueNames.ReferenceCycleTimeGapGrinding
    )

    const newReferenceValues: ReferenceValueDummy[] = []
    // Update all FormControl inputs to their corresponding component´s inputs
    if (referenceAllowancePosition != null) {
      this.allowancePositionValidSinceControl.setValue(
        referenceAllowancePosition.validSince
      )
      this.allowancePositionControl.setValue(
        referenceAllowancePosition.value?.replace('.', ',')
      )
      newReferenceValues.push(referenceAllowancePosition)
    }
    if (referenceAllowance != null) {
      this.allowanceValidSinceControl.setValue(referenceAllowance.validSince)
      this.allowanceControl.setValue(
        referenceAllowance.value?.replace('.', ',')
      )
      newReferenceValues.push(referenceAllowance)
    }
    if (referenceTolerance != null) {
      this.toleranceValidSinceControl.setValue(referenceTolerance.validSince)
      this.toleranceControl.setValue(
        referenceTolerance.value?.replace('.', ',')
      )
      newReferenceValues.push(referenceTolerance)
    }
    if (referenceGrindingTime != null) {
      this.grindingTimeValidSinceControl.setValue(
        referenceGrindingTime.validSince
      )
      this.grindingTimeControl.setValue(
        referenceGrindingTime.value?.replace('.', ',')
      )
      newReferenceValues.push(referenceGrindingTime)
    }
    this.referenceValues = newReferenceValues
  }

  /**
   * Returns the reference value with the given name
   * Returns undefined on name not found in reference values
   * @param name
   * @returns
   */
  private _getReferenceValue(
    name: ReferenceValueNames
  ): ReferenceValueDummy | undefined {
    return this.referenceValues.filter((referenceValue) => {
      return referenceValue.name === name
    })[0]
  }
  /**
   * Init valid since FormControl input.
   * Adding validator to valid since control.
   * Emitting new valid since on new valid since date set.
   * Emitting components validity of validity change.
   * @param validSinceControl the FormControl of the valid since input
   * @param allOtherValidSinceControls all other valid since controls
   * @param getReferenceValue function getting the reference value for the validSinceControl
   * @param validSinceEmitter output to which to emit new valid values
   */
  private _initValidSince(
    validSinceControl: FormControl,
    allOtherValidSinceControls: FormControl[],
    referenceValueControl: FormControl,
    name: ReferenceValueNames
  ): void {
    // On valid reference value:
    // 1. check valid-since is valid, too
    // 2. Emit reference value to output
    referenceValueControl.valueChanges.subscribe((referenceValue) => {
      if (!referenceValueControl.valid) {
        return
      }
      validSinceControl.updateValueAndValidity()
      const date =
        validSinceControl.value === ''
          ? ''
          : new Date(validSinceControl.value).toISOString()
      this._setValue(
        this.referenceValues,
        name,
        referenceValue?.toLocaleString('de-DE'),
        date
      )
      this.referenceValuesChange.emit(this.referenceValues)
    })
    // Validate: If reference value is set, valid since needs to be set, too
    validSinceControl.addValidators((control) => {
      const forbidden =
        referenceValueControl.value != null &&
        (control.value == null || control.value === '')

      const allOtherValidSinceValid = allOtherValidSinceControls
        .map((control) => control.valid)
        .reduce((prev, cur) => {
          return prev && cur
        })

      // If component validity changes emit new validity of component
      const componentValid = !forbidden && allOtherValidSinceValid
      if (this._valid !== componentValid) {
        this.validChange.emit(componentValid)
      }
      // Set validity of this valid since control
      return forbidden ? { forbiddenName: { value: control.value } } : null
    })
    // Emit on value change
    validSinceControl.valueChanges.subscribe((value) => {
      if (validSinceControl.valid) {
        if (typeof value === 'object') {
          const valueAsDate = value as Date
          this._setValidSince(
            this.referenceValues,
            name,
            valueAsDate.toISOString()
          )
        } else {
          this._setValidSince(this.referenceValues, name, value)
        }
        this.referenceValuesChange.emit(this.referenceValues)
      }
    })
  }

  /**
   * Finds the first ReferenceValue with the name name of an array of referenceValues and sets its value to value
   * "in situ" ["in place"]
   * @param referenceValues
   * @param name
   * @param value
   * @returns
   */
  private _setValue(
    referenceValues: ReferenceValueDummy[],
    name: ReferenceValueNames,
    value: string,
    validSince: string
  ): ReferenceValueDummy[] {
    let nameFound = false
    for (let i = 0; i < referenceValues.length; i++) {
      if (referenceValues[i].name === name) {
        referenceValues[i].value = value
        nameFound = true
        break
      }
    }

    if (!nameFound) {
      let unit = ''
      /* eslint-disable */
      switch (name) {
        case ReferenceValueNames.ReferenceAllowance:
          unit = 'µm'
          break
        case ReferenceValueNames.ReferenceAllowancePosition:
          unit = 'µm'
          break
        case ReferenceValueNames.ReferenceCycleTimeGapGrinding:
          unit = 's'
          break
        case ReferenceValueNames.ReferenceAllowanceTolerance:
          unit = 'µm'
          break
      }
      /* eslint-enable */

      const referenceValue: ReferenceValueDummy = {
        name: name,
        value: value,
        unit: unit,
        validSince: validSince,
      }
      referenceValues.push(referenceValue)
    }

    return referenceValues
  }

  /**
   * Finds the first ReferenceValue with the name name of an array of referenceValues and sets its validSince to validSince
   * "in situ" ["in place"]
   * @param referenceValues
   * @param name
   * @param validSince
   * @returns
   */
  private _setValidSince(
    referenceValues: ReferenceValueDummy[],
    name: ReferenceValueNames,
    validSince: string
  ): ReferenceValueDummy[] {
    for (let i = 0; i < referenceValues.length; i++) {
      if (referenceValues[i].name === name) {
        referenceValues[i].validSince = validSince
        break
      }
    }
    return referenceValues
  }
}
