export const dashboard = 'dashboard'
export const administration = 'administration'

export const routePaths = {
  code: {
    path: 'code',
  },
  empty: {
    path: '',
  },
  error: {
    path: 'error',
  },
  overview: {
    path: `${dashboard}/overview`,
  },
  allowanceMonitor: {
    path: `${dashboard}/allowanceMonitor`,
  },
  processConfig: {
    path: `${dashboard}/processConfig`,
  },
  cycleTimeMonitor: {
    path: `${dashboard}/cycleTimeMonitor`,
  },
  dressingMonitor: {
    path: `${dashboard}/dressingMonitor`,
  },
  referenceValues: {
    path: `${administration}/referenceValues`,
  },
}
