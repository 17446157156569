import { untilDestroyed } from '@ngneat/until-destroy'
import { Subject, combineLatest, delay, of, timeout } from 'rxjs'
import { DECIMAL_PLACES, TSValue } from '../../config/config'
import { DressingTimingDiagramComponent } from '../dressing-timing-diagram.component'
import { HttpErrorResponse } from '@angular/common/http'
import { Parameter } from '@dms-frontend/tds/services/api'

export function getCycleData(
  component: DressingTimingDiagramComponent,
  parameter: string,
  from: Date,
  to: Date,
  eq: number
): Subject<boolean> {
  const finished$ = new Subject<boolean>()

  component.cycleService
    .cycleEqGet(eq.toString(), parameter, from.toISOString(), to.toISOString())
    .pipe(delay(1000))
    .pipe(untilDestroyed(component))
    .subscribe(
      (cycles) => {
        if (
          cycles == null ||
          cycles.cycles == null ||
          cycles.cycles.length === 0
        ) {
          of(1)
            .pipe(timeout(10))
            .subscribe((val) => {
              finished$.next(true)
            })
          return
        }
        const cycleValues = cycles.cycles
        const currentGrindingDiameters = cycleValues
          .map((cycle) => {

            const filteredParameter = cycle.parameters.filter(
              (unfilteredParameter) => {
                return unfilteredParameter.name === parameter
              }
            )[0] as Parameter

            return filteredParameter.double === undefined
              ? null
              : {
                ts: cycle.ts,
                value: parseFloat(
                  (filteredParameter.double * 1000).toFixed(DECIMAL_PLACES)
                ),
              }
          })
          .filter((el) => el != null) as TSValue[]

        switch (parameter) {
        case 'CurrentGrindingWheelDiameter':
          component.grindingWheelDiameter = currentGrindingDiameters
          break
        case 'GrindingWheelDiameterWornOut':
          component.grindingWheelDiameterWornOut = currentGrindingDiameters
          break
        }
        finished$.next(true)
      },
      (error: HttpErrorResponse) => {
        component.snackBar.open(`${error.status}: ${error.statusText}` , 'Close')
        finished$.error(true)
      }
    )

  return finished$
}

export function retrieveGrindingDiameter(
  component: DressingTimingDiagramComponent,
  from: Date,
  to: Date,
  eq: number
): Subject<boolean> {
  const finished$ = new Subject<boolean>()

  const currentGrindingWheelDiameter$ = getCycleData(
    component,
    'CurrentGrindingWheelDiameter',
    from,
    to,
    eq
  )

  const grindingWheelDiameterWornOut$ = getCycleData(
    component,
    'GrindingWheelDiameterWornOut',
    from,
    to,
    eq
  )

  
  combineLatest([currentGrindingWheelDiameter$, grindingWheelDiameterWornOut$])
    .pipe(untilDestroyed(component))
    .subscribe(
      (finishedArray) => {
        const finishedRetrieving = finishedArray.reduce((prev, cur) => {
          return prev && cur
        })
        finished$.next(finishedRetrieving)
      },
      (error: HttpErrorResponse) => {
        component.snackBar.open(`${error.status}: ${error.statusText}` , 'Close')
      }
    )

  return finished$
}

export function retrieveManualDressing(
  component: DressingTimingDiagramComponent,
  from: Date,
  to: Date,
  eq: number
): Subject<boolean> {
  const finished$ = new Subject<boolean>()

  component.kpiService
    .kpiDressingmonitorIntervalEqGet(
      eq.toString(),
      from.toISOString(),
      to.toISOString()
    )
    .pipe(untilDestroyed(component))
    .subscribe(
      (dressingMonitorKPIs) => {
        if (dressingMonitorKPIs.manualDressingDiameterInterval) {
          component.manualDressing =
            dressingMonitorKPIs.manualDressingDiameterInterval.map((el) => {
              return {
                ts: el.ts,
                value: parseFloat((el.value * 1000).toFixed(DECIMAL_PLACES)),
              }
            })
        }
        finished$.next(true)
      },
      (error: HttpErrorResponse) => {
        component.snackBar.open(`${error.status}: ${error.statusText}` , 'Close')
        finished$.error(error)
      }
    )
  return finished$
}
