import { Chart, CoreScaleOptions, Element, Scale } from 'chart.js'
import { AnyObject } from 'chart.js/dist/types/basic'

export interface LineChartData {
  x: number
  y: number
}

export interface LineCoordinate<T = AnyObject, O = AnyObject>
  extends Element<T, O> {
  x: number
  y: number
}

export interface BarCoordinate<T = AnyObject, O = AnyObject>
  extends Element<T, O> {
  x: number
  y: number
  width: number
}

export interface LocalScale<O extends CoreScaleOptions = CoreScaleOptions>
  extends Scale<O> {
  _startPixel: number
}

export function resetHeightOfLine(datasetIndex: number, chart: Chart): void {
  const metaData = chart.getDatasetMeta(datasetIndex)
  for (const [index, element] of metaData.data.entries()) {
    if (index % 3 === 1) {
      // eslint-disable-next-line dot-notation
      const yScale = chart.scales['y'] as LocalScale
      const coordinate = element as LineCoordinate
      coordinate.y = yScale._startPixel
    }
  }
}

export function fillLineChartData(inputData: number[]): LineChartData[] {
  const outputChartData: LineChartData[] = []
  for (let i = 0; i < inputData.length * 3; i = i + 3) {
    outputChartData[i] = { x: inputData[i / 3], y: 0 }
    outputChartData[i + 1] = { x: inputData[i / 3], y: 0 }
    outputChartData[i + 2] = { x: NaN, y: NaN }
  }
  return outputChartData
}

export function generateHistogram(
  allowanceData: number[],
  histogramData: number[],
  allowanceNumber: number,
  xAxisLabel: number[]
): void {
  for (let i = 0; i < allowanceData.length; i++) {
    for (const [index, value] of xAxisLabel.entries()) {
      if (
        allowanceData[i] >= value &&
        allowanceData[i] < value + allowanceNumber
      ) {
        histogramData[index]++
        break
      }
    }
  }
}

// Deprecated
export function roundToNearest10(value: number): number {
  return Math.round(value / 10) * 10
}

export function round(value: number, precision: number = 0): number {
  const multiplier = Math.pow(10, precision)
  return Math.round(value * multiplier) / multiplier
}
