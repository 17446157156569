<div id="navigation-container" class="flex flex-col h-screen">
  <nav
    class="flex w-full items-center justify-between header-height bg-white text-medium-emphasis border-b-2 border-border z-10 md:w-screen"
  >
    <div class="flex items-center h-full">
      <button
        *ngIf="!hideSideNavigation"
        type="button"
        class="mx-2"
        (click)="toggle()"
      >
        <svg
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          class="w-6 h-6"
        >
          <path d="M4 6h16M4 12h16M4 18h16"></path>
        </svg>
      </button>

      <!-- Logo -->
      <div class="h-full">
        <ng-content [select]="logo"></ng-content>
      </div>
    </div>
    <div
      class="text-h6 font-[Roboto] font-medium hover:cursor-pointer"
      [class.ml-4]="!hideSideNavigation"
      (click)="navigateHome()"
    >
      {{ title }}
    </div>
    <div class="flex items-center">
      <!-- Navigation Header Injection | F.e. user for Lang Selection or Profile -->
      <ng-content select="[header-menu]"></ng-content>
      <!-- Back Icon -> Back to App Portal-->
      <!-- TODO: to support back navigation to app portal remove the *ngif-->
      <!-- this is currently a hidden feature -->
      <div (click)="navigateToAppPortal()" *ngIf="false">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 -960 960 960"
          width="24"
          class="fill-medium-emphasis hover:fill-primary hover:cursor-pointer"
        >
          <path d="M640-80 240-480l400-400 71 71-329 329 329 329-71 71Z" />
        </svg>
      </div>
    </div>
  </nav>
  <mat-sidenav-container
    class="flex z-10 h-full w-screen app-background-color"
    autosize
  >
    <mat-sidenav
      *ngIf="!hideSideNavigation"
      #sidenav
      class="h-full"
      mode="side"
      [class.w-full]="isMenuOpen"
      [class.w-0]="!isMenuOpen"
      [class.sm:w-60]="isMenuOpen"
      [class.sm:w-0]="!isMenuOpen"
      [class.md:w-60]="isMenuOpen"
      [class.md:w-16]="!isMenuOpen && !hideIcons"
      opened
    >
      <div class="flex flex-col h-full">
        <div class="border-b-2 border-border md:hidden"></div>
        <div class="lg:overflow-y-hidden">
          <ng-content select="[menu-points]"></ng-content>
        </div>
        <div class="flex flex-grow items-end">
          <div
            *ngIf="version !== ''"
            class="relative bottom-0 left-0 p-2 text-right w-full text-overline text-medium-emphasis"
          >
            Ui Version: {{ version }}
          </div>
        </div>
      </div>
    </mat-sidenav>

    <!-- content of site: > medium sized: margin-left = 4 + mat-sidenav-width -->
    <mat-sidenav-content
      #sidenavcontent
      class="h-full w-full"
      [class.md:ml-20]="!isMenuOpen && !hideIcons && !hideSideNavigation"
      [class.md:ml-64]="isMenuOpen && !hideSideNavigation"
    >
      <div class="w-full h-full">
        <ng-content select="[main-content]"></ng-content>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
