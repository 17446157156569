/* eslint-disable */
/**
 * KPI Service
 * This API returns calculated KPIs
 *
 * OpenAPI spec version: 0.2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */


 // TODO: cycleTimeMonitoring, state and dressingMonitor(?) endpoints should be implemented in kpi now
 // => remove other services, add functions here

import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http'
import { Inject, Injectable, Optional } from '@angular/core'
import { CustomHttpUrlEncodingCodec } from '../encoder'

import { Observable } from 'rxjs'

import { Result } from '../model/result'
import { SingleResult } from '../model/singleResult'

import { Configuration } from '../configuration'
import { CycleTimeMonitorIntervalKPIs } from '../model/cycleTimeMonitorIntervalKPIs'
import { DressingMonitorIntervalKPIs } from '../model/dressingMonitorIntervalKPIs'
import { EquipmentStates } from '../model/equipmentStates'
import { BASE_PATH_CONNECIVITY } from '../variables'

@Injectable()
export class KpiService {
  protected basePath = 'https://{customIP}:{port}/'
  defaultHeaders = new HttpHeaders()
  configuration = new Configuration()

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH_CONNECIVITY) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath
    }
    if (configuration) {
      this.configuration = configuration
      this.basePath = basePath || configuration.basePath || this.basePath
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data'
    for (const consume of consumes) {
      if (form === consume) {
        return true
      }
    }
    return false
  }

  /**
   * Get KPI allowanceposition of equipment number
   *
   * @param eq Equipment number to get values from
   * @param from Start of time range of values
   * @param to End of time range of values
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  kpiAllowancepositionEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<Result>>
  kpiAllowancepositionEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Result>>>
  kpiAllowancepositionEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Result>>>
  kpiAllowancepositionEqGet(
    eq: string,
    from: string,
    to: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (eq == null) {
      throw new Error(
        'Required parameter eq was null or undefined when calling kpiAllowancepositionEqGet.'
      )
    }

    if (from == null) {
      throw new Error(
        'Required parameter from was null or undefined when calling kpiAllowancepositionEqGet.'
      )
    }

    if (to == null) {
      throw new Error(
        'Required parameter to was null or undefined when calling kpiAllowancepositionEqGet.'
      )
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    })
    if (from != null) {
      queryParameters = queryParameters.set('from', <any>from)
    }
    if (to != null) {
      queryParameters = queryParameters.set('to', <any>to)
    }

    let headers = this.defaultHeaders

    // To determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json']
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    // To determine the Content-Type header
    const consumes: string[] = []

    return this.httpClient.request<Array<Result>>(
      'get',
      `${this.basePath}/kpi/allowanceposition/${encodeURIComponent(
        String(eq)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }

  /**
   * Get KPI AverageCurrentAllowance of equipment number
   *
   * @param eq Equipment number to get values from
   * @param from Start of time range of values
   * @param to End of time range of values
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  kpiAveragecurrentallowanceEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SingleResult>
  kpiAveragecurrentallowanceEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SingleResult>>
  kpiAveragecurrentallowanceEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SingleResult>>
  kpiAveragecurrentallowanceEqGet(
    eq: string,
    from: string,
    to: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (eq == null) {
      throw new Error(
        'Required parameter eq was null or undefined when calling kpiAveragecurrentallowanceEqGet.'
      )
    }

    if (from == null) {
      throw new Error(
        'Required parameter from was null or undefined when calling kpiAveragecurrentallowanceEqGet.'
      )
    }

    if (to == null) {
      throw new Error(
        'Required parameter to was null or undefined when calling kpiAveragecurrentallowanceEqGet.'
      )
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    })
    if (from != null) {
      queryParameters = queryParameters.set('from', <any>from)
    }
    if (to != null) {
      queryParameters = queryParameters.set('to', <any>to)
    }

    let headers = this.defaultHeaders

    // To determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json']
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    // To determine the Content-Type header
    const consumes: string[] = []

    return this.httpClient.request<SingleResult>(
      'get',
      `${this.basePath}/kpi/averagecurrentallowance/${encodeURIComponent(
        String(eq)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }

  /**
   * Get KPI AverageCycleTimeProcessing of equipment number
   *
   * @param eq Equipment number to get values from
   * @param from Start of time range of values
   * @param to End of time range of values
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  kpiAveragecycletimeprocessingEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SingleResult>
  kpiAveragecycletimeprocessingEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SingleResult>>
  kpiAveragecycletimeprocessingEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SingleResult>>
  kpiAveragecycletimeprocessingEqGet(
    eq: string,
    from: string,
    to: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (eq == null) {
      throw new Error(
        'Required parameter eq was null or undefined when calling kpiAveragecycletimeprocessingEqGet.'
      )
    }

    if (from == null) {
      throw new Error(
        'Required parameter from was null or undefined when calling kpiAveragecycletimeprocessingEqGet.'
      )
    }

    if (to == null) {
      throw new Error(
        'Required parameter to was null or undefined when calling kpiAveragecycletimeprocessingEqGet.'
      )
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    })
    if (from != null) {
      queryParameters = queryParameters.set('from', <any>from)
    }
    if (to != null) {
      queryParameters = queryParameters.set('to', <any>to)
    }

    let headers = this.defaultHeaders

    // To determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json']
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    // To determine the Content-Type header
    const consumes: string[] = []

    return this.httpClient.request<SingleResult>(
      'get',
      `${this.basePath}/kpi/averagecycletimeprocessing/${encodeURIComponent(
        String(eq)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }

  /**
   * Get average KPI GapTimeActual of equipment number
   *
   * @param eq Equipment number to get values from
   * @param from Start of time range of values
   * @param to End of time range of values
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  kpiAveragegaptimeactualEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SingleResult>
  kpiAveragegaptimeactualEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SingleResult>>
  kpiAveragegaptimeactualEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SingleResult>>
  kpiAveragegaptimeactualEqGet(
    eq: string,
    from: string,
    to: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (eq == null) {
      throw new Error(
        'Required parameter eq was null or undefined when calling kpiAveragegaptimeactualEqGet.'
      )
    }

    if (from == null) {
      throw new Error(
        'Required parameter from was null or undefined when calling kpiAveragegaptimeactualEqGet.'
      )
    }

    if (to == null) {
      throw new Error(
        'Required parameter to was null or undefined when calling kpiAveragegaptimeactualEqGet.'
      )
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    })
    if (from != null) {
      queryParameters = queryParameters.set('from', <any>from)
    }
    if (to != null) {
      queryParameters = queryParameters.set('to', <any>to)
    }

    let headers = this.defaultHeaders

    // To determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json']
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    // To determine the Content-Type header
    const consumes: string[] = []

    return this.httpClient.request<SingleResult>(
      'get',
      `${this.basePath}/kpi/averagegaptimeactual/${encodeURIComponent(
        String(eq)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }

  /**
   * Get KPI CycleTimeProcessing of equipment number
   *
   * @param eq Equipment number to get values from
   * @param from Start of time range of values
   * @param to End of time range of values
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  kpiCycletimeprocessingEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<Result>>
  kpiCycletimeprocessingEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Result>>>
  kpiCycletimeprocessingEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Result>>>
  kpiCycletimeprocessingEqGet(
    eq: string,
    from: string,
    to: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (eq == null) {
      throw new Error(
        'Required parameter eq was null or undefined when calling kpiCycletimeprocessingEqGet.'
      )
    }

    if (from == null) {
      throw new Error(
        'Required parameter from was null or undefined when calling kpiCycletimeprocessingEqGet.'
      )
    }

    if (to == null) {
      throw new Error(
        'Required parameter to was null or undefined when calling kpiCycletimeprocessingEqGet.'
      )
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    })
    if (from != null) {
      queryParameters = queryParameters.set('from', <any>from)
    }
    if (to != null) {
      queryParameters = queryParameters.set('to', <any>to)
    }

    let headers = this.defaultHeaders

    // To determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json']
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    // To determine the Content-Type header
    const consumes: string[] = []

    return this.httpClient.request<Array<Result>>(
      'get',
      `${this.basePath}/kpi/cycletimeprocessing/${encodeURIComponent(
        String(eq)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }

  /**
   * Get KPI DeltaTWithSuggestionAllowancePosition of equipment number
   *
   * @param eq Equipment number to get values from
   * @param from Start of time range of values
   * @param to End of time range of values
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  kpiDeltatwithsuggestionallowancepositionEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<Result>>
  kpiDeltatwithsuggestionallowancepositionEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Result>>>
  kpiDeltatwithsuggestionallowancepositionEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Result>>>
  kpiDeltatwithsuggestionallowancepositionEqGet(
    eq: string,
    from: string,
    to: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (eq == null) {
      throw new Error(
        'Required parameter eq was null or undefined when calling kpiDeltatwithsuggestionallowancepositionEqGet.'
      )
    }

    if (from == null) {
      throw new Error(
        'Required parameter from was null or undefined when calling kpiDeltatwithsuggestionallowancepositionEqGet.'
      )
    }

    if (to == null) {
      throw new Error(
        'Required parameter to was null or undefined when calling kpiDeltatwithsuggestionallowancepositionEqGet.'
      )
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    })
    if (from != null) {
      queryParameters = queryParameters.set('from', <any>from)
    }
    if (to != null) {
      queryParameters = queryParameters.set('to', <any>to)
    }

    let headers = this.defaultHeaders

    // To determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json']
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    // To determine the Content-Type header
    const consumes: string[] = []

    return this.httpClient.request<Array<Result>>(
      'get',
      `${
        this.basePath
      }/kpi/deltatwithsuggestionallowanceposition/${encodeURIComponent(
        String(eq)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }

  /**
   * Get KPI NoGapControl of equipment number
   *
   * @param eq Equipment number to get values from
   * @param from Start of time range of values
   * @param to End of time range of values
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  kpiNogapcontrolEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SingleResult>
  kpiNogapcontrolEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SingleResult>>
  kpiNogapcontrolEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SingleResult>>
  kpiNogapcontrolEqGet(
    eq: string,
    from: string,
    to: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (eq == null) {
      throw new Error(
        'Required parameter eq was null or undefined when calling kpiNogapcontrolEqGet.'
      )
    }

    if (from == null) {
      throw new Error(
        'Required parameter from was null or undefined when calling kpiNogapcontrolEqGet.'
      )
    }

    if (to == null) {
      throw new Error(
        'Required parameter to was null or undefined when calling kpiNogapcontrolEqGet.'
      )
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    })
    if (from != null) {
      queryParameters = queryParameters.set('from', <any>from)
    }
    if (to != null) {
      queryParameters = queryParameters.set('to', <any>to)
    }

    let headers = this.defaultHeaders

    // To determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json']
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    // To determine the Content-Type header
    const consumes: string[] = []

    return this.httpClient.request<SingleResult>(
      'get',
      `${this.basePath}/kpi/nogapcontrol/${encodeURIComponent(String(eq))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }

  /**
   * Get KPI SuggestionAllowancePosition of equipment number
   *
   * @param eq Equipment number to get values from
   * @param from Start of time range of values
   * @param to End of time range of values
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  kpiSuggestionallowancepositionEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SingleResult>
  kpiSuggestionallowancepositionEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SingleResult>>
  kpiSuggestionallowancepositionEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SingleResult>>
  kpiSuggestionallowancepositionEqGet(
    eq: string,
    from: string,
    to: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (eq == null) {
      throw new Error(
        'Required parameter eq was null or undefined when calling kpiSuggestionallowancepositionEqGet.'
      )
    }

    if (from == null) {
      throw new Error(
        'Required parameter from was null or undefined when calling kpiSuggestionallowancepositionEqGet.'
      )
    }

    if (to == null) {
      throw new Error(
        'Required parameter to was null or undefined when calling kpiSuggestionallowancepositionEqGet.'
      )
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    })
    if (from != null) {
      queryParameters = queryParameters.set('from', <any>from)
    }
    if (to != null) {
      queryParameters = queryParameters.set('to', <any>to)
    }

    let headers = this.defaultHeaders

    // To determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json']
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    // To determine the Content-Type header
    const consumes: string[] = []

    return this.httpClient.request<SingleResult>(
      'get',
      `${this.basePath}/kpi/suggestionallowanceposition/${encodeURIComponent(
        String(eq)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }

    /**
   * Get all relevant KPIs for TDS feature cycle time monitor interval of equipment number
   *
   * @param eq Equipment number to get values from
   * @param from Start of time range of values
   * @param to End of time range of values
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
    kpiCycletimemonitorIntervalEqGet(
      eq: string,
      from: string,
      to: string,
      observe?: 'body',
      reportProgress?: boolean
    ): Observable<CycleTimeMonitorIntervalKPIs>
    kpiCycletimemonitorIntervalEqGet(
      eq: string,
      from: string,
      to: string,
      observe?: 'response',
      reportProgress?: boolean
    ): Observable<HttpResponse<CycleTimeMonitorIntervalKPIs>>
    kpiCycletimemonitorIntervalEqGet(
      eq: string,
      from: string,
      to: string,
      observe?: 'events',
      reportProgress?: boolean
    ): Observable<HttpEvent<CycleTimeMonitorIntervalKPIs>>
    kpiCycletimemonitorIntervalEqGet(
      eq: string,
      from: string,
      to: string,
      observe: any = 'body',
      reportProgress: boolean = false
    ): Observable<any> {
      if (eq == null) {
        throw new Error(
          'Required parameter eq was null or undefined when calling kpiCycletimemonitorIntervalEqGet.'
        )
      }

      if (from == null) {
        throw new Error(
          'Required parameter from was null or undefined when calling kpiCycletimemonitorIntervalEqGet.'
        )
      }

      if (to == null) {
        throw new Error(
          'Required parameter to was null or undefined when calling kpiCycletimemonitorIntervalEqGet.'
        )
      }

      let queryParameters = new HttpParams({
        encoder: new CustomHttpUrlEncodingCodec(),
      })
      if (from != null) {
        queryParameters = queryParameters.set('from', <any>from)
      }
      if (to != null) {
        queryParameters = queryParameters.set('to', <any>to)
      }

      let headers = this.defaultHeaders

      // To determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      const httpHeaderAcceptSelected: string | undefined =
        this.configuration.selectHeaderAccept(httpHeaderAccepts)
      if (httpHeaderAcceptSelected !== undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected)
      }

      // To determine the Content-Type header
      const consumes: string[] = []

      return this.httpClient.request<CycleTimeMonitorIntervalKPIs>(
        'get',
        `${this.basePath}/kpi/cycletimemonitor/interval/${encodeURIComponent(
          String(eq)
        )}`,
        {
          params: queryParameters,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress,
        }
      )
    }

  /**
   * Get all allowance monitor states of equipmentnumber
   *
   * @param eq Equipmentnumber to get states from
   * @param from Start time to get states from
   * @param to End time to get states form
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  stateAllowancemonitorEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<EquipmentStates>
  stateAllowancemonitorEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<EquipmentStates>>
  stateAllowancemonitorEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<EquipmentStates>>
  stateAllowancemonitorEqGet(
    eq: string,
    from: string,
    to: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (eq == null || eq === undefined) {
      throw new Error(
        'Required parameter eq was null or undefined when calling stateAllowancemonitorEqGet.'
      )
    }

    if (from == null || from === undefined) {
      throw new Error(
        'Required parameter from was null or undefined when calling stateAllowancemonitorEqGet.'
      )
    }

    if (to == null || to === undefined) {
      throw new Error(
        'Required parameter to was null or undefined when calling stateAllowancemonitorEqGet.'
      )
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    })
    if (from !== undefined && from != null) {
      queryParameters = queryParameters.set('from', <any>from)
    }
    if (to !== undefined && to != null) {
      queryParameters = queryParameters.set('to', <any>to)
    }

    let headers = this.defaultHeaders

    // To determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json']
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    // To determine the Content-Type header
    const consumes: string[] = []

    return this.httpClient.request<EquipmentStates>(
      'get',
      `${this.basePath}/state/allowancemonitor/${encodeURIComponent(
        String(eq)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }

  /**
   * Get all process config states of equipmentnumber
   *
   * @param eq Equipmentnumber to get states from
   * @param from Start time to get states from
   * @param to End time to get states form
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  stateProcessconfigEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<EquipmentStates>
  stateProcessconfigEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<EquipmentStates>>
  stateProcessconfigEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<EquipmentStates>>
  stateProcessconfigEqGet(
    eq: string,
    from: string,
    to: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (eq == null || eq === undefined) {
      throw new Error(
        'Required parameter eq was null or undefined when calling stateProcessconfigEqGet.'
      )
    }

    if (from == null || from === undefined) {
      throw new Error(
        'Required parameter from was null or undefined when calling stateProcessconfigEqGet.'
      )
    }

    if (to == null || to === undefined) {
      throw new Error(
        'Required parameter to was null or undefined when calling stateProcessconfigEqGet.'
      )
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    })
    if (from !== undefined && from != null) {
      queryParameters = queryParameters.set('from', <any>from)
    }
    if (to !== undefined && to != null) {
      queryParameters = queryParameters.set('to', <any>to)
    }

    let headers = this.defaultHeaders

    // To determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json']
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    // To determine the Content-Type header
    const consumes: string[] = []

    return this.httpClient.request<EquipmentStates>(
      'get',
      `${this.basePath}/state/processconfig/${encodeURIComponent(String(eq))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }


  /**
   * Get all relevant KPIs for TDS feature dressing monitor interval of equipment number
   *
   * @param eq Equipment number to get values from
   * @param from Start of time range of values
   * @param to End of time range of values
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  kpiDressingmonitorIntervalEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DressingMonitorIntervalKPIs>
  kpiDressingmonitorIntervalEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DressingMonitorIntervalKPIs>>
  kpiDressingmonitorIntervalEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DressingMonitorIntervalKPIs>>
  kpiDressingmonitorIntervalEqGet(
    eq: string,
    from: string,
    to: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (eq == null || eq === undefined) {
      throw new Error(
        'Required parameter eq was null or undefined when calling kpiDressingmonitorIntervalEqGet.'
      )
    }

    if (from == null || from === undefined) {
      throw new Error(
        'Required parameter from was null or undefined when calling kpiDressingmonitorIntervalEqGet.'
      )
    }

    if (to == null || to === undefined) {
      throw new Error(
        'Required parameter to was null or undefined when calling kpiDressingmonitorIntervalEqGet.'
      )
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    })
    if (from !== undefined && from != null) {
      queryParameters = queryParameters.set('from', <any>from)
    }
    if (to !== undefined && to != null) {
      queryParameters = queryParameters.set('to', <any>to)
    }

    let headers = this.defaultHeaders

    // To determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json']
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    // To determine the Content-Type header
    const consumes: string[] = []

    return this.httpClient.request<DressingMonitorIntervalKPIs>(
      'get',
      `${this.basePath}/kpi/dressingmonitor/interval/${encodeURIComponent(
        String(eq)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }
}
/* eslint-enable */
