import { Component, Input } from '@angular/core'

@Component({
  selector: 'dms-frontend-tile-value-with-ref-value',
  templateUrl: './tile-value-with-refValue.component.html',
  styleUrls: ['./tile-value-with-refValue.component.scss'],
})
export class TileValueWithRefValueComponent {
  // Loading set to true will show loading spinner
  @Input() loading = false
  // Headline to be shown.
  @Input() headline: string | null = null
  // Value to be shown. Will be rounded to input decimalPlaces. @See [decimalPlaces]
  @Input() value?: number | null = null
  // Reference value comparison determines the color of the value.
  // Red on violation. Green on match. Black on no_comparison_possible.
  @Input() referenceValueComparison:
    | 'match'
    | 'small_violation'
    | 'large_violation'
    | 'no_comparison_possible' = 'no_comparison_possible'
  // Value will be rounded to decimalPlaces decimal places
  @Input()
    decimalPlaces = 3
  // Footer text to be shown before referenceValue
  @Input() referenceValueText: string | null = null
  // Reference value to be shown at bottom after referenceValueText.
  // Will be rounded to @See [decimalPlaces]
  @Input() referenceValue?: number | null
}
