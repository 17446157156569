<h5 class="font-bold">Reference Values</h5>
<br />

<!-- Reference Allowance Pos-->
<div class="container w-full flex flex-row justify-between">
  <form class="inline mr-4">
    <mat-form-field class="w-56" appearance="fill">
      <mat-label>Reference Allowance Pos. [mm]</mat-label>
      <input
        name="allowancePosition"
        id="allowancePosition"
        [formControl]="allowancePositionControl"
        size="5"
        type="text"
        placeholder="Set value"
        aria-label="Number"
        matInput
        dmsFrontendFloatNumber
      />
    </mat-form-field>
  </form>

  <form class="inline">
    <mat-form-field class="w-56" appearance="fill">
      <mat-label>Valid since</mat-label>
      <input
        name="allowancePositionValidSince"
        matInput
        [ngxMatDatetimePicker]="allowancePosFrom"
        [formControl]="allowancePositionValidSinceControl"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="$any(allowancePosFrom)"
      ></mat-datepicker-toggle>
      <ngx-mat-datetime-picker
        #allowancePosFrom
        [showSpinners]="true"
        [showSeconds]="true"
        [stepHour]="1"
        [stepMinute]="1"
        [stepSecond]="1"
        [touchUi]="false"
        [color]="'primary'"
        [disableMinute]="false"
        [hideTime]="false"
      >
      </ngx-mat-datetime-picker>
      <mat-error>Time not valid</mat-error>
    </mat-form-field>
  </form>
</div>

<!-- Reference Allowance -->
<div class="container w-full flex flex-row justify-between">
  <form class="inline mr-4">
    <mat-form-field class="w-56" appearance="fill">
      <mat-label>Reference Allowance [mm]</mat-label>
      <input
        name="allowance"
        id="allowance"
        [formControl]="allowanceControl"
        size="5"
        type="text"
        placeholder="Set value"
        aria-label="Number"
        matInput
        dmsFrontendFloatNumber
      />
    </mat-form-field>
  </form>

  <form class="inline">
    <mat-form-field class="w-56" appearance="fill">
      <mat-label>Valid since</mat-label>
      <input
        name="allowanceValidSince"
        matInput
        [ngxMatDatetimePicker]="referenceAllowanceFrom"
        [formControl]="allowanceValidSinceControl"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="$any(referenceAllowanceFrom)"
      ></mat-datepicker-toggle>
      <ngx-mat-datetime-picker
        #referenceAllowanceFrom
        [showSpinners]="true"
        [showSeconds]="true"
        [stepHour]="1"
        [stepMinute]="1"
        [stepSecond]="1"
        [touchUi]="false"
        [color]="'primary'"
        [disableMinute]="false"
        [hideTime]="false"
      >
      </ngx-mat-datetime-picker>
      <mat-error>Time not valid</mat-error>
    </mat-form-field>
  </form>
</div>

<!-- Tolerance -->
<div class="container w-full flex flex-row justify-between">
  <form class="inline mr-4">
    <mat-form-field class="w-56" appearance="fill">
      <mat-label>Reference Tolerance +- [mm]</mat-label>
      <input
        name="tolerance"
        id="tolerance"
        size="5"
        type="text"
        placeholder="Set value"
        aria-label="Number"
        matInput
        dmsFrontendFloatNumber
        [formControl]="toleranceControl"
      />
    </mat-form-field>
  </form>

  <form class="inline">
    <mat-form-field class="w-56" appearance="fill">
      <mat-label>Valid since</mat-label>
      <input
        name="toleranceValidSince"
        matInput
        [ngxMatDatetimePicker]="referenceToleranceFrom"
        [formControl]="toleranceValidSinceControl"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="$any(referenceToleranceFrom)"
      ></mat-datepicker-toggle>
      <ngx-mat-datetime-picker
        #referenceToleranceFrom
        [showSpinners]="true"
        [showSeconds]="true"
        [stepHour]="1"
        [stepMinute]="1"
        [stepSecond]="1"
        [touchUi]="false"
        [color]="'primary'"
        [disableMinute]="false"
        [hideTime]="false"
      >
      </ngx-mat-datetime-picker>
      <mat-error>Time not valid</mat-error>
    </mat-form-field>
  </form>
</div>

<!-- Reference Grinding Time -->
<div class="container w-full flex flex-row justify-between">
  <form class="inline mr-4">
    <mat-form-field class="w-56" appearance="fill">
      <mat-label>Reference Gap Grinding Time [s]</mat-label>
      <input
        name="grindingTime"
        id="grindingTime"
        size="5"
        type="text"
        placeholder="Set value"
        aria-label="Number"
        matInput
        dmsFrontendFloatNumber
        [formControl]="grindingTimeControl"
      />
    </mat-form-field>
  </form>

  <form class="inline">
    <mat-form-field class="w-56" appearance="fill">
      <mat-label>Valid since</mat-label>
      <input
        name="grindingTimeValidSince"
        matInput
        [formControl]="grindingTimeValidSinceControl"
        [ngxMatDatetimePicker]="referenceGrindingTimeFrom"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="$any(referenceGrindingTimeFrom)"
      ></mat-datepicker-toggle>
      <ngx-mat-datetime-picker
        #referenceGrindingTimeFrom
        [showSpinners]="true"
        [showSeconds]="true"
        [stepHour]="1"
        [stepMinute]="1"
        [stepSecond]="1"
        [touchUi]="false"
        [color]="'primary'"
        [disableMinute]="false"
        [hideTime]="false"
      >
      </ngx-mat-datetime-picker>
      <mat-error>Time not valid</mat-error>
    </mat-form-field>
  </form>
</div>
