import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import {
  CategoryEnum,
  EquipmentStates,
  KpiService,
  Machine,
} from '@dms-frontend/tds/services/api'
import { MachineSelectionService } from '@dms-frontend/shared/services/machine-selection'
import { TimeSelectionService } from '@dms-frontend/shared/services/time-selection'
import { Subscription, combineLatest } from 'rxjs'

@Component({
  selector: 'dms-frontend-line-states',
  templateUrl: './line-states.component.html',
  styleUrls: ['./line-states.component.scss'],
})
export class LineStatesComponent implements OnInit, OnDestroy {
  line: Machine[] = []
  states: EquipmentStates[] = []

  // Subscription containing all subscription | Will be unsubscribed on destory
  subscriptions = new Subscription()

  constructor(
    private readonly machinesService: MachineSelectionService,
    private readonly timeService: TimeSelectionService,
    private readonly kpiService: KpiService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    let ignoreHttpRequest = false

    const from$ = this.timeService.from$
    const to$ = this.timeService.to$
    const selectedLine$ = this.machinesService.machinesInSelectedLine$

    const dateAndMachines = combineLatest([
      from$,
      to$,
      selectedLine$,
    ]).subscribe(([dateFrom, dateTo, machines]) => {
      // Ignore http requested states
      // If new machines are selected after http request is sent
      ignoreHttpRequest = true
      if (machines == null || machines.length === 0) {
        this.states = []
        this.line = []
        this.ref.detectChanges()
        return
      }
      this.line = machines
      const equipmentNumbers = this.line.map((machine) => machine.eqnr)

      ignoreHttpRequest = false

      this.states = this._fillStateArray(
        equipmentNumbers,
        dateFrom,
        dateTo,
        ignoreHttpRequest
      )
    })

    this.subscriptions.add(dateAndMachines)
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  selectMachine(equipmentNumber: number): void {
    this.machinesService.selectEquipment(equipmentNumber)
  }

  private _fillStateArray(
    equipmentNumbers: number[],
    dateFrom: Date,
    dateTo: Date,
    ignoreHttpRequest: boolean
  ): EquipmentStates[] {
    const states: EquipmentStates[] = []

    equipmentNumbers.forEach((eqNumber) => {
      const state$ = this.kpiService.stateAllowancemonitorEqGet(
        eqNumber.toString(),
        dateFrom.toISOString(),
        dateTo.toISOString()
      )
      state$.subscribe({
        next: (state) => {
          if (ignoreHttpRequest) {
            return
          }
          this._sortedPush(states, state)
        },
        error: () => {
          this._sortedPush(states, this._getDummyState(eqNumber))
        },
      })
    })

    return states
  }

  private _getDummyState(equipmentNumber: number): EquipmentStates {
    return {
      equipmentNumber: equipmentNumber,
      states: [
        {
          ok: false,
          category: CategoryEnum.NoDataFound,
          ts: '',
          states: [],
        },
      ],
    }
  }

  private _sortedPush(array: EquipmentStates[], value: EquipmentStates): void {
    array.splice(this._sortedIndex(array, value), 0, value)
  }

  private _sortedIndex(
    array: EquipmentStates[],
    value: EquipmentStates
  ): number {
    let low = 0
    let high = array.length

    while (low < high) {
      const mid = (low + high) >>> 1
      if (array[mid].equipmentNumber < value.equipmentNumber) {
        low = mid + 1
      } else {
        high = mid
      }
    }
    return low
  }
}
