<dms-frontend-navigation-bar
  class="h-12 inline-block"
  [title]="'Technology Diagnostic System'"
  [homeRoute]="''"
  [isMenuOpen]="true"
  [hideIcons]="false"
>
  <div header-menu>
    <dms-frontend-menu-header
      [iconStructure]="headerUser"
    ></dms-frontend-menu-header>
    <dms-frontend-menu-header
      [iconStructure]="headerInfo"
    ></dms-frontend-menu-header>
  </div>
  <div menu-points>
    <dms-frontend-menu-point
      [menuPoint]="home"
      [routerLink]="''"
    ></dms-frontend-menu-point>
    <dms-frontend-menu-point [menuPoint]="dashboard">
      <dms-frontend-menu-point
        [menuPoint]="overview"
        [routerLink]="routePaths.overview.path"
      ></dms-frontend-menu-point>
      <dms-frontend-menu-point
        [menuPoint]="cycleTimeMonitor"
        [routerLink]="routePaths.cycleTimeMonitor.path"
      ></dms-frontend-menu-point>
      <dms-frontend-menu-point
        [menuPoint]="allowanceMonitor"
        [routerLink]="routePaths.allowanceMonitor.path"
      >
      </dms-frontend-menu-point>
      <dms-frontend-menu-point
        [menuPoint]="processConfig"
        [routerLink]="routePaths.processConfig.path"
      ></dms-frontend-menu-point>
      
      <dms-frontend-menu-point
        [menuPoint]="dressingMonitor"
        [routerLink]="routePaths.dressingMonitor.path"
      ></dms-frontend-menu-point>
    </dms-frontend-menu-point>
    <dms-frontend-menu-point [menuPoint]="administration">
      <dms-frontend-menu-point
        [menuPoint]="referenceValues"
        [routerLink]="routePaths.referenceValues.path"
      >
      </dms-frontend-menu-point>
    </dms-frontend-menu-point>
  </div>
  <div main-content>
    <router-outlet></router-outlet>
  </div>
</dms-frontend-navigation-bar>
