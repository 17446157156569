export interface ReferenceValue {
  materialNumber: number
  // F.e. allowance, averageAllowance
  name: string
  value: number
  // Unit of the value given, f.e. kg for kilogramm or m for meter
  unit: string
  // UTC format
  validSince: string
}

export interface ReferenceValues {
  equipmentNumber: number
  values: ReferenceValue[]
}

export const REFERENCE_VALUESS: ReferenceValues[] = [
  {
    equipmentNumber: 10000000,
    values: [
      {
        materialNumber: 1623897314,
        name: 'material name',
        value: 1,
        unit: 'type',
        validSince: '01.01.2000'
      },
      {
        materialNumber: 1623897314,
        name: 'material name',
        value: 2,
        unit: 'type',
        validSince: '02.01.2000'
      },
      {
        materialNumber: 2623897314,
        name: 'material name',
        value: 2,
        unit: 'type',
        validSince: '02.01.2000'
      }
    ]
  },
  {
    equipmentNumber: 12345678,
    values: [
      {
        materialNumber: 1623897314,
        name: 'material name',
        value: 1,
        unit: 'type',
        validSince: '01.01.2000'
      },
      {
        materialNumber: 1623897314,
        name: 'material name',
        value: 2,
        unit: 'type',
        validSince: '02.01.2000'
      }
    ]
  },
  {
    equipmentNumber: 12345678,
    values: [
      {
        materialNumber: 1623897314,
        name: 'material name',
        value: 1,
        unit: 'type',
        validSince: '01.01.2000'
      },
      {
        materialNumber: 1623897314,
        name: 'material name',
        value: 2,
        unit: 'type',
        validSince: '02.01.2000'
      }
    ]
  },
  {
    equipmentNumber: 12345678,
    values: [
      {
        materialNumber: 1623897314,
        name: 'material name',
        value: 1,
        unit: 'type',
        validSince: '01.01.2000'
      },
      {
        materialNumber: 1623897314,
        name: 'material name',
        value: 2,
        unit: 'type',
        validSince: '02.01.2000'
      }
    ]
  },
  {
    equipmentNumber: 12345678,
    values: [
      {
        materialNumber: 1623897314,
        name: 'material name',
        value: 1,
        unit: 'type',
        validSince: '01.01.2000'
      },
      {
        materialNumber: 1623897314,
        name: 'material name',
        value: 2,
        unit: 'type',
        validSince: '02.01.2000'
      }
    ]
  }
]
