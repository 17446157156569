import { LiveAnnouncer } from '@angular/cdk/a11y'
import { Component, OnInit, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator'
import { MatSort, Sort } from '@angular/material/sort'
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table'
import { MachineSelectionService } from '@dms-frontend/shared/services/machine-selection'
import {
  CreateMaterialNumberDialogComponent,
  CreateMaterialNumberDialogInput,
  EditMaterialNumberDialogComponent,
  EditMaterialNumberDialogInput,
  HistoryReferenceValuesDialogComponent,
  HistoryReferenceValuesInput,
} from '@dms-frontend/tds-ui-material-number-dialogs'
import {
  MachinesService,
  ReferenceValueNames,
  ReferenceValuesService,
} from '@dms-frontend/tds/services/api'
import { BehaviorSubject } from 'rxjs'

export interface LinearisedReferenceValue {
  equipmentNumber: number
  materialNumber: number
  materialText: string
  name: ReferenceValueNames
  value: string
  unit: string
  validSince: string
}

interface FilterObject {
  equipmentNumber?: string
  materialNumber?: string
  materialText?: string
}

@Component({
  selector: 'dms-frontend-overview-materialnumbers',
  templateUrl: './overview-materialnumbers.component.html',
  styleUrls: ['./overview-materialnumbers.component.scss'],
})
export class OverviewMaterialnumbersComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null

  dataSource!: MatTableDataSource<LinearisedReferenceValue>

  displayedColumns: string[] = ['equipmentNumber', 'materialNumber', 'edit']
  @ViewChild(MatSort) sort: MatSort | null = null

  equipmentNumberControl = new FormControl()
  materialNumberControl = new FormControl()
  materialTextControl = new FormControl()

  filter: FilterObject = {}

  loading$ = new BehaviorSubject(true)

  usedEqMatCombination: string[] = []

  constructor(
    public dialog: MatDialog,
    public machineService: MachinesService,
    public referenceValuesService: ReferenceValuesService,
    private readonly machineSelectionService: MachineSelectionService,
    private _liveAnnouncer: LiveAnnouncer
  ) {}

  ngOnInit(): void {
    const getRefValueOnAllMachines =
      this.machineSelectionService.onGotAllMachines$.subscribe((ready) => {
        if (!ready) {
          return
        }

        const machines = this.machineSelectionService.allMachines
        const allEquipmentNumbers = machines.map((machine) =>
          machine.eqnr.toString()
        )

        if (allEquipmentNumbers.length === 0) {
          this.loading$.next(false)
          return
        }
        this.referenceValuesService
          .referencevaluesGet(allEquipmentNumbers)
          .subscribe((referenceValuesArray) => {
            if (
              referenceValuesArray == null ||
              referenceValuesArray.length === 0
            ) {
              this.loading$.next(false)
              return
            }

            let i = 0
            const linearisedReferenceValues = []

            for (const referenceValues of referenceValuesArray) {
              for (const referenceValue of referenceValues.values) {
                if (
                  this.containsCombination(
                    referenceValues.equipmentNumber,
                    referenceValue.materialNumber
                  )
                ) {
                  continue
                }
                this.pushCombination(
                  referenceValues.equipmentNumber,
                  referenceValue.materialNumber
                )

                linearisedReferenceValues.push({
                  equipmentNumber: referenceValues.equipmentNumber,
                  materialNumber: referenceValue.materialNumber,
                  materialText: `MATERIAL_TEXT_${i}`,
                  name: referenceValue.name,
                  value: referenceValue.value,
                  unit: referenceValue.unit,
                  validSince: referenceValue.validSince,
                })
                i++
              }
            }
            this.dataSource = new MatTableDataSource<LinearisedReferenceValue>(
              linearisedReferenceValues
            )
            this.addFilterFeature()

            this.loading$.next(false)
            getRefValueOnAllMachines.unsubscribe()
          })
      })
  }

  pushCombination(equipmentNumber: number, materialNumber: number): void {
    this.usedEqMatCombination.push(
      'EQ:' + equipmentNumber + 'MAT:' + materialNumber
    )
  }

  containsCombination(
    equipmentNumber: number,
    materialNumber: number
  ): boolean {
    return this.usedEqMatCombination.includes(
      'EQ:' + equipmentNumber + 'MAT:' + materialNumber
    )
  }

  addFilterFeature(): void {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort

    // Add filter support | Set dataSource.filter to the JSON.stringify of a Interface SearchObject
    // It will test a case insensitive regular expression for each attribute and
    // Filter, if all attributed match
    this.dataSource.filterPredicate = (data, filter): boolean => {
      const filterObject: FilterObject = JSON.parse(filter)

      const equipmentNumberFilter =
        filterObject.equipmentNumber == null
          ? '.*'
          : filterObject.equipmentNumber.toString()
      const materialNumberFilter =
        filterObject.materialNumber == null
          ? '.*'
          : filterObject.materialNumber.toString()
      const materialTextFilter =
        filterObject.materialText == null ? '.*' : filterObject.materialText

      const equipmentNumberTest = new RegExp(equipmentNumberFilter, 'i')
      const materialNumberTest = new RegExp(materialNumberFilter, 'i')
      const materialTextTest = new RegExp(materialTextFilter, 'i')

      const equipmentNumberMatch = equipmentNumberTest.test(
        data.equipmentNumber.toString()
      )
      const materialNumberMatch = materialNumberTest.test(
        data.materialNumber.toString()
      )
      const materialTextMatch = materialTextTest.test(
        data.materialText.toLowerCase()
      )

      return equipmentNumberMatch && materialNumberMatch && materialTextMatch
    }

    // Update equipment number filter on value changes
    this.equipmentNumberControl.valueChanges.subscribe(
      (equipmentNumberFilter) => {
        this.filter.equipmentNumber = equipmentNumberFilter
        this.dataSource.filter = JSON.stringify(this.filter)
      }
    )
    // Update material number filter on value changes
    this.materialNumberControl.valueChanges.subscribe(
      (materialNumberFilter) => {
        this.filter.materialNumber = materialNumberFilter
        this.dataSource.filter = JSON.stringify(this.filter)
      }
    )
    // Update material text filter on value changes
    this.materialTextControl.valueChanges.subscribe((materialTextFilter) => {
      this.filter.materialText = materialTextFilter
      this.dataSource.filter = JSON.stringify(this.filter)
    })
  }

  edit(element: LinearisedReferenceValue): void {
    const editDialogInput: EditMaterialNumberDialogInput = {
      equipmentNumber: element.equipmentNumber,
      materialNumber: element.materialNumber,
      materialText: 'TEXT',
    }
    this.dialog.open(EditMaterialNumberDialogComponent, {
      data: editDialogInput,
    })
  }

  delete(element: LinearisedReferenceValue): void {
    throw new Error('Not implemented')
  }

  showHistory(element: LinearisedReferenceValue): void {
    const data: HistoryReferenceValuesInput = {
      equipmentNumber: element.equipmentNumber,
      materialNumber: element.materialNumber,
    }
    this.dialog.open(HistoryReferenceValuesDialogComponent, { data })
  }

  copy(element: LinearisedReferenceValue): void {
    const data: CreateMaterialNumberDialogInput = {
      materialText: 'TEXT',
      equipmentNumber: element.equipmentNumber,
      materialNumber: element.materialNumber,
      allowExistingMaterialNumber: true,
    }
    this.dialog.open(CreateMaterialNumberDialogComponent, { data })
  }

  announceSortChange(sortState: Sort): void {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`)
    } else {
      this._liveAnnouncer.announce('Sorting cleared')
    }
  }
}
