import { CycleTimeGapGrindingDiagramComponent } from './cycle-time-gap-grinding-diagram.component'

export function setChartData(
  component: CycleTimeGapGrindingDiagramComponent
): void {
  // Cycle time gap grinding
  const cycleTimeGapGrinding = component.cycleTimeGapGrinding.map((el) => {
    return { x: el.ts, y: el.value }
  })

  // Average cycle time
  const averageCycleTime = component.averageCycleTimeGapGrinding.map((el) => {
    return { x: el.ts, y: el.value }
  })

  // Upper limit cycle time
  const upperLimitCycleTimeGapGrinding =
    component.upperLimitCycleTimeGapGrinding.map((el) => {
      return { x: el.ts, y: el.value }
    })
  const lastUpperLimitCycleTimGapGrinding =
    upperLimitCycleTimeGapGrinding[upperLimitCycleTimeGapGrinding.length - 1]
  if (lastUpperLimitCycleTimGapGrinding != null) {
    upperLimitCycleTimeGapGrinding.push({
      x: component.maxDate.toISOString(),
      y: lastUpperLimitCycleTimGapGrinding.y,
    })
  }

  // Delta T with suggestion allowance position
  const deltaT = component.deltaTWithSuggestionAllowancePosition.map((el) => {
    return { x: el.ts, y: el.value }
  }).filter(
    (el) => !isNaN(el.y)
  ).sort((a, b) => {
    const aDate = new Date(a.x)
    const bDate = new Date(b.x)
    return aDate.getTime() - bDate.getTime()
  })


  // Outliers
  const outliers = component.outliers.map((el) => {
    return { x: el.ts, y: el.value }
  })

  component.chartData = {
    datasets: [
      {
        label: 'Average cycle time gap grinding',
        type: 'line',
        stepped: 'before',
        data: averageCycleTime,
        backgroundColor: 'green',
        borderColor: 'green',
        pointRadius: 0,
        pointHitRadius: 4,
        datalabels: {
          display: (context: any): boolean => {
            return context.dataIndex === averageCycleTime.length - 1
          },
          borderColor: 'green',
          anchor: 'end',
          color: 'green',
          backgroundColor: 'rgba(0,0,0,0)',
        },
        order: 1,
      },
      {
        label: 'Upper limit cycle time gap grinding',
        type: 'line',
        stepped: 'before',
        data: upperLimitCycleTimeGapGrinding,
        borderDash: [10, 5],
        backgroundColor: 'green',
        borderColor: 'green',
        datalabels: {
          display: (context: any): boolean => {
            return context.dataIndex < upperLimitCycleTimeGapGrinding.length
          },
          borderColor: 'green',
          anchor: 'end',
          color: 'green',
          backgroundColor: 'rgba(0,0,0,0)',
        },
        order: 2,
      },
      {
        label: 'Δt with suggestion allowance position',
        type: 'line',
        stepped: 'before',
        data: deltaT,
        borderColor: 'blue',
        pointRadius: 0,
        pointHitRadius: 4,
        datalabels: {
          display: (context: any): boolean => {
            return context.dataIndex === deltaT.length - 1
          },
          borderColor: 'blue',
          anchor: 'end',
          color: 'blue',
          backgroundColor: 'rgba(0,0,0,0)',
          padding: {
            top: 2,
            left: 4,
            right: 4,
            bottom: 0,
          },
        },
        order: 3,
      },
      {
        label: 'Outlier',
        pointRaduis: 5,
        borderColor: 'red',
        backgroundColor: 'green',
        data: outliers,
        order: 4,
      },
      {
        label: 'Cycle time gap grinding',
        type: 'scatter',
        data: cycleTimeGapGrinding,
        backgroundColor: 'green',
        borderColor: 'green',
        pointRadius: 2,
        order: 5,
      },
    ],
  }
}
