<div class="container flex flex-col align-middle justify-center" [class.pointer-events-none]="loading$ | async">
  <h5 tabindex="0" class="font-bold">Create New Material Number</h5>
  <br />
  <!-- Equipment Number -->
  <form class="inline">
    <mat-form-field class="w-full" appearance="fill">
      <mat-label>Equipment Number*</mat-label>
      <input #autoCompleteInput size="5" type="number" placeholder="Pick one" aria-label="Number" matInput
        [formControl]="equipmentNumberControl" [matAutocomplete]="equipmentNumberAutoComplete" />
      <mat-autocomplete #equipmentAutocomplete (closed)="equipmentNumbersClosed()"
        #equipmentNumberAutoComplete="matAutocomplete">
        <mat-option *ngFor="let option of filteredEquipmentNumbers | async" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
      <mat-error>Equipment number does not exist</mat-error>
    </mat-form-field>
  </form>
  <!-- Material Number -->
  <form class="inline">
    <mat-form-field class="w-full" appearance="fill">
      <mat-label>Material Number*</mat-label>
      <input size="5" type="number" placeholder="Set material number" aria-label="Number" matInput
        [formControl]="materialNumberControl" />
      <mat-error>Material number does already exist for this equipment</mat-error>
    </mat-form-field>
  </form>

  <!-- Material Text-->
  <!--
  <form *ngIf="showFullDialog" class="inline" [class.pointer-events-none]="loading$ | async">
    <mat-form-field class="w-full" appearance="fill">
      <mat-label>Material Text</mat-label>
      <input size="5" type="text" placeholder="Matrial Text" matInput disabled />
    </mat-form-field>
  </form>
  -->

  <dms-frontend-reference-values *ngIf="showFullDialog" [(referenceValues)]="referenceValues">
  </dms-frontend-reference-values>
  <br />

  <!-- Dialog Buttons -->
  <div class="container flex flex-row justify-end" [class.pointer-events-none]="loading$ | async">
    <button mat-button color="primary" class="mr-2" (click)="cancel()">
      CANCEL
    </button>
    <button mat-raised-button color="primary" (click)="next()" *ngIf="!showFullDialog">
      NEXT
    </button>
    <button mat-raised-button color="primary" (click)="create()" *ngIf="showFullDialog">
      CREATE
    </button>
  </div>
</div>

<mat-progress-spinner *ngIf="loading$ | async"
  class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" [mode]="'indeterminate'">
</mat-progress-spinner>
