import {
  Coordinate,
  Label,
  getClickedLabel,
} from './material-text-plugin.service'

export function materialTextToolTip(
  canvas: HTMLCanvasElement,
  tooltip: HTMLCanvasElement,
  onMatTxtClick: (label: Label) => void,
  yOffset: number,
  labels: Map<Coordinate, Label>
): void {
  const body = document.getElementsByTagName('body')[0]
  // Add click event listener for label clicks
  canvas.addEventListener('click', (event) => {
    const clickedLabel = getClickedLabel(event.offsetX, event.offsetY, labels)
    if (clickedLabel != null) {
      onMatTxtClick(clickedLabel)
    }
  })

  let mouseOverLabel = false
  canvas.addEventListener('mousemove', (event) => {
    const hoveredLabel = getClickedLabel(event.offsetX, event.offsetY, labels)
    const tipCtx = tooltip.getContext('2d')

    if (hoveredLabel != null) {
      body.style.cursor = 'pointer'
      mouseOverLabel = true
      if (tipCtx != null) {
        const ratio = window.devicePixelRatio
        tooltip.width = 250 * ratio
        tooltip.height = 90 * ratio
        tooltip.style.width = 250 + 'px'
        tooltip.style.height = 90 + 'px'
        tipCtx.scale(ratio, ratio)

        const tooltipX =
          hoveredLabel.box.left + tooltip.width >
          canvas.offsetWidth + canvas.offsetLeft
            ? canvas.offsetWidth + canvas.offsetLeft - tooltip.width
            : hoveredLabel.box.left + canvas.offsetLeft
        const tooltipY = hoveredLabel.box.top + canvas.offsetTop - yOffset - 60

        tooltip.style.left = tooltipX + 'px'
        tooltip.style.top = tooltipY + 'px'

        tipCtx.clearRect(0, 0, tooltip.width, tooltip.height)
        tipCtx.font = '16px san-serif'
        tipCtx.fillStyle = 'rgba(255, 255, 255, 1)'
        tipCtx.fillText('Material Number: ' + hoveredLabel.matNumber, 10, 25)
        tipCtx.fillText('Material Text: ' + hoveredLabel.matText, 10, 50)
        tipCtx.fillText('Order ID: ' + hoveredLabel.orderID, 10, 75)
      }
    }

    if (hoveredLabel == null && mouseOverLabel) {
      body.style.cursor = 'default'
      mouseOverLabel = false
      tooltip.style.left = -1000 + 'px'
    }
  })
}

export function materialTextLabelUnderXAxis(
  ctx: CanvasRenderingContext2D,
  bottom: number,
  yOffset: number,
  xScaleBegin: number,
  top: number,
  matText: string,
  matNumber: string,
  orderID: number,
  xBegin: number,
  xEnd: number,
  drawText: boolean,
  labels: Map<Coordinate, Label>
): void {
  const width = xEnd - xBegin

  const rectHeight = 30

  ctx.save()
  // Draw rect
  const x = xBegin
  const y = bottom + yOffset
  ctx.fillStyle = 'rgba(190, 190, 190, 0.4)'
  ctx.fillRect(x, y, width, rectHeight)
  ctx.strokeStyle = 'white'
  ctx.strokeRect(x, y, width, rectHeight)

  if (drawText) {
    // Draw text
    ctx.font = '16px san-serif'
    ctx.fillStyle = 'rgba(50, 50, 50, 1)'
    ctx.textAlign = 'center'

    ctx.fillText(
      matNumber,
      xBegin + 0.5 * width,
      bottom + yOffset + 0.5 * rectHeight
    )
  }

  // Push to labels for click event handling
  labels.set(
    { x: x, y: y },
    {
      matText: matText,
      matNumber: matNumber,
      orderID: orderID,
      box: {
        top: y,
        bottom: y + rectHeight,
        left: x,
        right: x + width,
      },
    }
  )
  // Draw vertical lines in chart as indicator for begin
  if (x !== xScaleBegin) {
    ctx.fillStyle = 'rgba(0, 0, 0, 0.3)'
    ctx.fillRect(x, bottom, 1, top - bottom)
  }
  ctx.restore()
}
