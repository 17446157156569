/**
 * Machine Information
 * This API delivers machine information stored in TDS
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Machine { 
    eqnr: number;
    line: string;
    segment: string;
    location: string;
    plantCode: string;
    machineSupplier: string;
    machineType: string;
    technology: string;
    mesworkcenterid?: number;
}