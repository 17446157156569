import { StateNames } from '@dms-frontend/tds/services/api'
import { SubsystemComponent } from './subsystem.component'

export interface SubsystemType {
  x: string | null
  y: string | null
}

function initAllUnkownArray(
  component: SubsystemComponent,
  automaticModeUnknown: SubsystemType[],
  gapControlUnknown: SubsystemType[],
  touchControlUnknown: SubsystemType[],
  autoRoughGrindingUnknown: SubsystemType[],
  autoSparkOutUnknown: SubsystemType[],
  overrideHundretUnknown: SubsystemType[],
  soundQualityUnknown: SubsystemType[]
): void {
  automaticModeUnknown.push({
    x: component.minDate.toISOString(),
    y: 'Automatic mode',
  })
  automaticModeUnknown.push({
    x: component.maxDate.toISOString(),
    y: 'Automatic mode',
  })
  gapControlUnknown.push({
    x: component.minDate.toISOString(),
    y: 'Gap control',
  })
  gapControlUnknown.push({
    x: component.maxDate.toISOString(),
    y: 'Gap control',
  })
  soundQualityUnknown.push({
    x: component.minDate.toISOString(),
    y: 'Sound quality',
  })
  soundQualityUnknown.push({
    x: component.maxDate.toISOString(),
    y: 'Sound quality',
  })
  touchControlUnknown.push({
    x: component.minDate.toISOString(),
    y: 'Touch control',
  })
  touchControlUnknown.push({
    x: component.maxDate.toISOString(),
    y: 'Touch control',
  })
  autoRoughGrindingUnknown.push({
    x: component.minDate.toISOString(),
    y: 'Auto rough grinding',
  })
  autoRoughGrindingUnknown.push({
    x: component.maxDate.toISOString(),
    y: 'Auto rough grinding',
  })
  autoSparkOutUnknown.push({
    x: component.minDate.toISOString(),
    y: 'Auto spark out',
  })
  autoSparkOutUnknown.push({
    x: component.maxDate.toISOString(),
    y: 'Auto spark out',
  })
  overrideHundretUnknown.push({
    x: component.minDate.toISOString(),
    y: 'Override 100%',
  })
  overrideHundretUnknown.push({
    x: component.maxDate.toISOString(),
    y: 'Override 100%',
  })
}

function initTrueArray(
  component: SubsystemComponent,
  trueArray: SubsystemType[],
  yAxisName: string,
  startDate: string
): void {
  trueArray.push({
    x: startDate,
    y: yAxisName,
  })
  trueArray.push({
    x: component.maxDate.toISOString(),
    y: yAxisName,
  })
}

function fillFalseArray(
  component: SubsystemComponent,
  falseArray: SubsystemType[],
  yAxisName: string,
  state: boolean,
  currentTimestamp: string,
  isLastIteration: boolean
): void {
  if (state) {
    falseArray.push({
      x: currentTimestamp,
      y: yAxisName,
    })
  } else {
    falseArray.push({
      x: null,
      y: null,
    })
    falseArray.push({
      x: currentTimestamp,
      y: yAxisName,
    })
    if (isLastIteration) {
      falseArray.push({
        x: component.maxDate.toISOString(),
        y: yAxisName,
      })
    }
  }
}

function fillStateDataArrays(
  component: SubsystemComponent,
  trueArray: SubsystemType[],
  falseArray: SubsystemType[],
  stateName: StateNames,
  yAxisName: string
): void {
  const dataArray = component.stateData.filter((states) => {
    return states.name === stateName
  })
  for (const [index, stateElement] of dataArray.entries()) {
    if (trueArray.length === 0 && falseArray.length === 0) {
      initTrueArray(component, trueArray, yAxisName, stateElement.ts)
    }
    fillFalseArray(
      component,
      falseArray,
      yAxisName,
      stateElement.state,
      stateElement.ts,
      index === dataArray.length - 1
    )
  }
}

export function setChartData(component: SubsystemComponent): void {
  const automaticModeTrue: SubsystemType[] = []
  const automaticModeFalse: SubsystemType[] = []
  const automaticModeUnknown: SubsystemType[] = []
  const gapControlTrue: SubsystemType[] = []
  const gapControlFalse: SubsystemType[] = []
  const gapControlUnknown: SubsystemType[] = []
  const touchControlTrue: SubsystemType[] = []
  const touchControlFalse: SubsystemType[] = []
  const touchControlUnknown: SubsystemType[] = []
  const autoRoughGrindingTrue: SubsystemType[] = []
  const autoRoughGrindingFalse: SubsystemType[] = []
  const autoRoughGrindingUnknown: SubsystemType[] = []
  const autoSparkOutTrue: SubsystemType[] = []
  const autoSparkOutFalse: SubsystemType[] = []
  const autoSparkOutUnknown: SubsystemType[] = []
  const overrideHundretTrue: SubsystemType[] = []
  const overrideHundretFalse: SubsystemType[] = []
  const overrideHundretUnknown: SubsystemType[] = []
  const soundQualityTrue: SubsystemType[] = []
  const soundQualityFalse: SubsystemType[] = []
  const soundQualityUnknown: SubsystemType[] = []

  initAllUnkownArray(
    component,
    automaticModeUnknown,
    gapControlUnknown,
    touchControlUnknown,
    autoRoughGrindingUnknown,
    autoSparkOutUnknown,
    overrideHundretUnknown,
    soundQualityUnknown
  )

  if (component.stateData.length !== 0) {
    fillStateDataArrays(
      component,
      automaticModeTrue,
      automaticModeFalse,
      StateNames.AutomaticMode,
      'Automatic mode'
    )
    fillStateDataArrays(
      component,
      gapControlTrue,
      gapControlFalse,
      StateNames.GapControl,
      'Gap control'
    )
    fillStateDataArrays(
      component,
      soundQualityTrue,
      soundQualityFalse,
      StateNames.GapControlValid,
      'Sound quality'
    )
    fillStateDataArrays(
      component,
      touchControlTrue,
      touchControlFalse,
      StateNames.TouchControl,
      'Touch control'
    )
    fillStateDataArrays(
      component,
      autoRoughGrindingTrue,
      autoRoughGrindingFalse,
      StateNames.AutoRoughGrinding,
      'Auto rough grinding'
    )
    fillStateDataArrays(
      component,
      autoSparkOutTrue,
      autoSparkOutFalse,
      StateNames.AutoSparkOut,
      'Auto spark out'
    )
    fillStateDataArrays(
      component,
      overrideHundretTrue,
      overrideHundretFalse,
      StateNames.Override100,
      'Override 100%'
    )
  }
  component.chartData = {
    datasets: [
      // {
      //   borderColor: 'grey',
      //   type: 'line',
      //   borderWidth: 50,
      //   data: automaticModeUnknown,
      //   order: 3,
      //   pointRadius: 0,
      // },
      // {
      //   borderColor: 'green',
      //   type: 'line',
      //   borderWidth: 50,
      //   data: automaticModeTrue,
      //   order: 2,
      //   pointRadius: 0,
      // },
      // {
      //   borderColor: 'red',
      //   type: 'line',
      //   borderWidth: 50,
      //   data: automaticModeFalse,
      //   order: 1,
      //   pointRadius: 0,
      // },
      {
        borderColor: 'grey',
        type: 'line',
        borderWidth: 50,
        data: gapControlUnknown,
        order: 3,
        pointRadius: 0,
      },
      {
        borderColor: 'green',
        type: 'line',
        borderWidth: 50,
        data: gapControlTrue,
        order: 2,
        pointRadius: 0,
      },
      {
        borderColor: 'red',
        type: 'line',
        borderWidth: 50,
        data: gapControlFalse,
        order: 1,
        pointRadius: 0,
      },
      {
        borderColor: 'grey',
        type: 'line',
        borderWidth: 50,
        data: soundQualityUnknown,
        order: 3,
        pointRadius: 0,
      },
      {
        borderColor: 'green',
        type: 'line',
        borderWidth: 50,
        data: soundQualityTrue,
        order: 2,
        pointRadius: 0,
      },
      {
        borderColor: 'red',
        type: 'line',
        borderWidth: 50,
        data: soundQualityFalse,
        order: 1,
        pointRadius: 0,
      },
      {
        borderColor: 'grey',
        type: 'line',
        borderWidth: 50,
        data: touchControlUnknown,
        order: 3,
        pointRadius: 0,
      },
      {
        borderColor: 'green',
        type: 'line',
        borderWidth: 50,
        data: touchControlTrue,
        order: 2,
        pointRadius: 0,
      },
      {
        borderColor: 'red',
        type: 'line',
        borderWidth: 50,
        data: touchControlFalse,
        order: 1,
        pointRadius: 0,
      },
      {
        borderColor: 'grey',
        type: 'line',
        borderWidth: 50,
        data: autoRoughGrindingUnknown,
        order: 3,
        pointRadius: 0,
      },
      {
        borderColor: 'green',
        type: 'line',
        borderWidth: 50,
        data: autoRoughGrindingTrue,
        order: 2,
        pointRadius: 0,
      },
      {
        borderColor: 'red',
        type: 'line',
        borderWidth: 50,
        data: autoRoughGrindingFalse,
        order: 1,
        pointRadius: 0,
      },
      {
        borderColor: 'grey',
        type: 'line',
        borderWidth: 50,
        data: autoSparkOutUnknown,
        order: 3,
        pointRadius: 0,
      },
      {
        borderColor: 'green',
        type: 'line',
        borderWidth: 50,
        data: autoSparkOutTrue,
        order: 2,
        pointRadius: 0,
      },
      {
        borderColor: 'red',
        type: 'line',
        borderWidth: 50,
        data: autoSparkOutFalse,
        order: 1,
        pointRadius: 0,
      },
      {
        borderColor: 'grey',
        type: 'line',
        borderWidth: 50,
        data: overrideHundretUnknown,
        order: 3,
        pointRadius: 0,
      },
      {
        borderColor: 'green',
        type: 'line',
        borderWidth: 50,
        data: overrideHundretTrue,
        order: 2,
        pointRadius: 0,
      },
      {
        borderColor: 'red',
        type: 'line',
        borderWidth: 50,
        data: overrideHundretFalse,
        order: 1,
        pointRadius: 0,
      },
    ],
  }
}
