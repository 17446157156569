<form class="inline" [class.pointer-events-none]="loading$ | async">
  <mat-form-field matTooltip="Filter equipment numbers containing the selected partial equipment number"
    dmsFrontendAutofocus class="w-56" appearance="fill">
    <mat-label>Equipment Number Filter</mat-label>
    <input dmsFrontendAutofocus tabindex="0" name="equipmentNumber" [formControl]="equipmentNumberControl" size="5"
      type="text" placeholder="Set filter" matInput />
  </mat-form-field>
</form>
<form class="inline" [class.pointer-events-none]="loading$ | async">
  <mat-form-field matTooltip="Filter material numbers containing the selected partial material   number"
    class="w-56 ml-4" appearance="fill">
    <mat-label>Material Number Filter</mat-label>
    <input tabindex="0" name="materialNumber" [formControl]="materialNumberControl" size="5" type="text"
      placeholder="Set filter" matInput />
  </mat-form-field>
</form>
<!--
<form class="inline" [class.pointer-events-none]="loading$ | async">
  <mat-form-field class="w-56 ml-4" appearance="fill">
    <mat-label>Material Text Filter</mat-label>
    <input name="materialText" [formControl]="materialTextControl" size="5" placeholder="Set value" matInput />
  </mat-form-field>
</form>
-->

<div class="container relative max-w-4xl shadow-md" [class.pointer-events-none]="loading$ | async">
  <table matSort mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-full shadow-none">

    <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

    <!-- EquipmentNumber Column -->
    <ng-container matColumnDef="equipmentNumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-h5">
        Equipment Number
      </th>
      <th mat-header-cell *matHeaderCellDef>Test</th>
      <td mat-cell *matCellDef="let element"> {{element.equipmentNumber}} </td>
    </ng-container>

    <!-- Number Column -->
    <ng-container matColumnDef="materialNumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-h5"> Material Number
      </th>
      <th mat-header-cell *matHeaderCellDef>Test</th>
      <td mat-cell *matCellDef="let element"> {{element.materialNumber}} </td>
    </ng-container>

    <!-- Text Column -->
    <!--
    <ng-container matColumnDef="materialText">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-h5"> Material Text </th>
      <th mat-header-cell *matHeaderCellDef>Test</th>
      <td mat-cell *matCellDef="let element"> {{element.materialText}} </td>
    </ng-container>
    -->

    <!-- Icons Column -->
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef class="text-h5"> Edit </th>
      <td mat-cell *matCellDef="let element">
        <div class="container flex justify-between w-32">
          <button (click)="edit(element)">
            <mat-icon>edit</mat-icon>
          </button>
          <!--
          <button (click)="delete(element)">
            <mat-icon>delete</mat-icon>
          </button>
          -->
          <button (click)="showHistory(element)">
            <mat-icon>description</mat-icon>
          </button>
          <button (click)="copy(element)">
            <mat-icon>file_copy</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
  <mat-paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page">
  </mat-paginator>

  <mat-progress-spinner *ngIf="loading$ | async"
    class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" [mode]="'indeterminate'">
  </mat-progress-spinner>
</div>
