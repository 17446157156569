<div class="md:pt-2">
  <dms-frontend-dashboard-header
    [refreshIsRefreshingSomething]="showIntervalInfo"
    [disableRefresh]="(cycleTimeIntervalLoading$ | async)!"
    [class.pointer-events-none]="cycleTimeIntervalLoading$ | async"
  >
  </dms-frontend-dashboard-header>

  <mat-accordion multi>
    <!-- Average Cycle Time - Current 
    <mat-expansion-panel class="mr-4">
      <mat-expansion-panel-header class="text-body-1">
        <mat-panel-title> Average Cycle Time - Current </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel> -->

    <!-- Average Cycle Time - Interval -->
    <mat-expansion-panel class="mr-4">
      <mat-expansion-panel-header
        (click)="showAverageCycleTimeInterval()"
        class="text-body-1"
      >
        <mat-panel-title> Average Cycle Time - Interval </mat-panel-title>
      </mat-expansion-panel-header>
      <div
        *ngIf="showIntervalInfo"
        class="pl-10 pr-10 pb-10 w-full h-full flex flex-col overflow-x-auto"
      >
        <!-- ROW 1 -->
        <div class="flex flex-row">
          <dms-frontend-tile-value-with-ref-value
            [headline]="'Total [min]'"
            [loading]="(cycleTimeIntervalLoading$ | async)!"
            [value]="
              cycleTimeMonitorIntervalKPIs?.averageCycleTimeTotalInterval
                ?.value !== undefined &&
              cycleTimeMonitorIntervalKPIs?.averageCycleTimeTotalInterval
                ?.value !== null
                ? cycleTimeMonitorIntervalKPIs?.averageCycleTimeTotalInterval
                    ?.value! / 60
                : undefined
            "
          ></dms-frontend-tile-value-with-ref-value>
          <dms-frontend-tile-value-with-ref-value
            class="pl-4"
            [headline]="'Produced Parts'"
            [loading]="(cycleTimeIntervalLoading$ | async)!"
            [value]="cycleTimeMonitorIntervalKPIs?.producedPartsInterval?.value"
            [decimalPlaces]="0"
          ></dms-frontend-tile-value-with-ref-value>
        </div>
        <!-- ROW 2-->
        <div class="flex flex-row pt-4">
          <dms-frontend-tile-value-with-ref-value
            class=""
            [headline]="'Total [s]'"
            [loading]="(cycleTimeIntervalLoading$ | async)!"
            [value]="
              cycleTimeMonitorIntervalKPIs?.averageCycleTimeTotalInterval?.value
            "
            [decimalPlaces]="2"
          ></dms-frontend-tile-value-with-ref-value>
          <dms-frontend-tile-value-with-ref-value
            class="pl-4"
            [headline]="'Processing [s]'"
            [loading]="(cycleTimeIntervalLoading$ | async)!"
            [value]="
              cycleTimeMonitorIntervalKPIs?.averageCycleTimeProcessingInterval
                ?.value
            "
            [decimalPlaces]="2"
          ></dms-frontend-tile-value-with-ref-value>
          <dms-frontend-tile-value-with-ref-value
            class="pl-4"
            [headline]="'Loading [s]'"
            [loading]="(cycleTimeIntervalLoading$ | async)!"
            [value]="
              cycleTimeMonitorIntervalKPIs?.averageCycleTimeLoadingInterval
                ?.value
            "
            [decimalPlaces]="2"
          ></dms-frontend-tile-value-with-ref-value>
          <dms-frontend-tile-value-with-ref-value
            class="pl-4"
            [headline]="'Dressing / Part [s]'"
            [loading]="(cycleTimeIntervalLoading$ | async)!"
            [value]="
              cycleTimeMonitorIntervalKPIs?.averageCycleTimeDressingInterval
                ?.value
            "
            [decimalPlaces]="2"
          ></dms-frontend-tile-value-with-ref-value>
          <dms-frontend-tile-value-with-ref-value
            class="pl-4 pr-8"
            [headline]="'Single Dressing [s]'"
            [loading]="(cycleTimeIntervalLoading$ | async)!"
            [value]="
              cycleTimeMonitorIntervalKPIs
                ?.averageCycleTimeSingleDressingInterval?.value
            "
            [decimalPlaces]="2"
          ></dms-frontend-tile-value-with-ref-value>
        </div>
      </div>
    </mat-expansion-panel>

    <!-- Average Cycle Time - Order 
    <mat-expansion-panel class="mr-4">
      <mat-expansion-panel-header class="text-body-1">
        <mat-panel-title> Average Cycle Time - Order </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel> -->
  </mat-accordion>
</div>
