import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core'
import { Configuration } from './configuration'
import { HttpClient } from '@angular/common/http'
import { MachinesService } from './api/machines.service'
import { KpiService } from './api/kpi.service'
import { AllowanceMonitorService } from './api/allowanceMonitor.service'
import { CycleReferencedProcessValuesService } from './api/cycleReferencedProcessValues.service'
import { ReferenceValuesService } from './api/referenceValues.service'
import { MESInfoService } from './api/mesInfo.service'

@NgModule({
  imports: [], // CommonModule
  declarations: [],
  exports: [],
  providers: [
    CycleReferencedProcessValuesService,
    KpiService,
    MachinesService,
    AllowanceMonitorService,
    CycleReferencedProcessValuesService,
    ReferenceValuesService,
    MESInfoService,
  ],
})
export class TdsServicesApiModule {
  static forRoot(
    configurationFactory: () => Configuration
  ): ModuleWithProviders<TdsServicesApiModule> {
    return {
      ngModule: TdsServicesApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }],
    }
  }

  constructor(
    @Optional() @SkipSelf() parentModule: TdsServicesApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error(
        'ApiModule is already loaded. Import in your base AppModule only.'
      )
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575'
      )
    }
  }
}
