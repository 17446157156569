import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core'
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator'
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table'
import { DATE_FORMAT } from '@dms-frontend/shared/injection-tokens'
import {
  ReferenceValue,
  ReferenceValueNames,
  ReferenceValuesService,
} from '@dms-frontend/tds/services/api'
import { BehaviorSubject, Subscription, map } from 'rxjs'
import { mapReferenceValuesToMillimeter } from '../shared/reference-value-helper'

export interface HistoryReferenceValues {
  allowancePosition: string
  allowancePositionValidSince: string
  allowance: string
  allowanceValidSince: string
  allowanceTolerance: string
  allowanceToleranceValidSince: string
  grindingTime: string
  grindingTimeValidSince: string
}

export interface HistoryReferenceValuesInput {
  equipmentNumber: number
  materialNumber: number
}

@Component({
  selector: 'dms-frontend-history-reference-values-dialog',
  templateUrl: './history-reference-values-dialog.component.html',
  styleUrls: ['./history-reference-values-dialog.component.scss'],
})
export class HistoryReferenceValuesDialogComponent
implements AfterViewInit, OnDestroy
{
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null

  dataSource = new MatTableDataSource<HistoryReferenceValues>([])

  headerColumns: string[] = [
    'Allowance Position [um]',
    'Allowance [um]',
    'Allowance Tolerance +- [um]',
    'Grinding Time [s]',
  ]
  displayedColumns: string[]

  loading$ = new BehaviorSubject(false)

  subscriptions = new Subscription()

  constructor(
    public dialogRef: MatDialogRef<HistoryReferenceValuesDialogComponent>,
    private referenceValuesService: ReferenceValuesService,
    @Inject(MAT_DIALOG_DATA) public data: HistoryReferenceValuesInput,
    @Inject(DATE_FORMAT) public dateFormat: string
  ) {
    // All reference values for a given validSince-Date
    const referenceValuesByDate = new Map<string, ReferenceValue[]>()
    // Const dates: string[] = []

    this.loading$.next(true)
    const referenceValuesSubscription = this.referenceValuesService
      .referencevaluesEqMaterialnumberGet(
        this.data.equipmentNumber.toString(),
        this.data.materialNumber.toString()
      )
      .pipe(map(mapReferenceValuesToMillimeter))
      .subscribe((referenceValues) => {
        // Initialise map with reference values
        for (const referenceValue of referenceValues.values) {
          const refDate = new Date(referenceValue.validSince)
          refDate.setMilliseconds(0)
          // Dates.push(refDate)
          const curRefValues = referenceValuesByDate.get(refDate.toISOString())
          const referenceValuesForDate =
            curRefValues == null ? [] : curRefValues
          referenceValuesForDate.push(referenceValue)
          referenceValuesByDate.set(
            refDate.toISOString(),
            referenceValuesForDate
          )
        }

        const dates = [...referenceValuesByDate.keys()]
        // Sort dates from newest to oldest
        dates.sort((a: string, b: string): number => {
          return new Date(a).valueOf() < new Date(b).valueOf() ? 1 : -1
        })

        // Get all historic values ordered by date
        const historicValues: HistoryReferenceValues[] = []
        for (const date of dates) {
          const refValues = referenceValuesByDate.get(date)
          if (refValues == null) {
            continue
          }

          const allowance = this.getReferenceValue(
            refValues,
            ReferenceValueNames.ReferenceAllowance
          )
          const allowancePosition = this.getReferenceValue(
            refValues,
            ReferenceValueNames.ReferenceAllowancePosition
          )
          const grindingTime = this.getReferenceValue(
            refValues,
            ReferenceValueNames.ReferenceCycleTimeGapGrinding
          )
          const tolerance = this.getReferenceValue(
            refValues,
            ReferenceValueNames.ReferenceAllowanceTolerance
          )

          const historicValue: HistoryReferenceValues = {
            allowancePosition: allowancePosition?.value,
            allowancePositionValidSince: allowancePosition?.validSince,
            allowance: allowance?.value,
            allowanceValidSince: allowance?.validSince,
            allowanceTolerance: tolerance?.value,
            allowanceToleranceValidSince: tolerance?.validSince,
            grindingTime: grindingTime?.value,
            grindingTimeValidSince: grindingTime?.validSince,
          }
          historicValues.push(historicValue)
        }

        this.dataSource = new MatTableDataSource<HistoryReferenceValues>(
          historicValues
        )

        this.loading$.next(false)
      })

    this.subscriptions.add(referenceValuesSubscription)
    // Generate 'Value', 'Valid Since' columns for each header column
    this.displayedColumns = this.headerColumns
      .map((columnName) => {
        return [columnName + ' Value', columnName + ' Valid Since']
      })
      .reduce((prev, cur) => {
        return [...prev, ...cur]
      })
  }

  private getReferenceValue(
    referenceValues: ReferenceValue[],
    name: ReferenceValueNames
  ): ReferenceValue {
    return referenceValues.filter((referenceValue) => {
      return referenceValue.name === name
    })[0]
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  close(): void {
    this.dialogRef.close()
  }
}
