import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TileValueWithRefValueComponent } from './tile-value-with-refValue/tile-value-with-refValue.component'
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card'
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner'

@NgModule({
  imports: [CommonModule, MatCardModule, MatProgressSpinnerModule],
  declarations: [TileValueWithRefValueComponent],
  exports: [TileValueWithRefValueComponent],
})
export class TdsUiTilesModule {}
