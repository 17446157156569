import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { EquipmentStatesDTO } from './dtos'

@Injectable({
  providedIn: 'root',
})
export class ApiStateService {
  constructor(
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    @Inject('API_STATE_PATH') private readonly statePath: string,
    private http: HttpClient
  ) { }

  getStates$(machineIDs: number[]): Observable<EquipmentStatesDTO[]> {
    const url = this.baseUrl + '/' + this.statePath + '/eqs'
    return this.http.get<EquipmentStatesDTO[]>(url)
  }
}
