import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HistogramComponent } from './histogram/histogram.component'
import { NgChartsModule } from 'ng2-charts'
import { SharedMaterialModule } from '@dms-frontend/shared/material'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AllowanceDiagrammComponent } from './allowance-diagramm/allowance-diagramm.component'
import { CycleTimeGapGrindingDiagramComponent } from './cycle-time-gap-grinding-diagram/cycle-time-gap-grinding-diagram.component'
import { TdsServicesApiModule } from '@dms-frontend/tds/services/api'
import { SharedDirectivesModule } from '@dms-frontend/shared/directives'
import { MatSnackBarModule } from '@angular/material/snack-bar'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgChartsModule,
    ReactiveFormsModule,
    SharedMaterialModule,
    TdsServicesApiModule,
    SharedDirectivesModule,
    SharedMaterialModule
  ],
  declarations: [
    HistogramComponent,
    AllowanceDiagrammComponent,
    CycleTimeGapGrindingDiagramComponent
  ],
  exports: [
    HistogramComponent,
    AllowanceDiagrammComponent,
    CycleTimeGapGrindingDiagramComponent
  ], 
  providers: [
    MatSnackBarModule
  ]
})
export class TdsUiAllowanceChartsModule {}

export * from './allowance-diagramm/allowance-diagramm.component'
export * from './cycle-time-gap-grinding-diagram/cycle-time-gap-grinding-diagram.component'
export * from './histogram/histogram.component'
