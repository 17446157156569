import {
  Order,
  ReferenceValue,
  sortedReferenceValues,
} from '@dms-frontend/tds/services/api'
import { Subject, of, timeout } from 'rxjs'
import { TSValue } from '../../config/config'
import { CycleTimeGapGrindingDiagramComponent } from '../cycle-time-gap-grinding-diagram.component'
import { untilDestroyed } from '@ngneat/until-destroy'

/**
 * Get all active reference values for a given time range.
 * @param materialNumber filter all reference values refValue for materialNumber
 * @param refValues all reference values of one type, f.e. of type ReferenceAllowance
 */
function getActiveReferenceValues(
  minDate: Date,
  maxDate: Date,
  materialNumber: string,
  refValues: ReferenceValue[]
): ReferenceValue[] {
  // Filter ref values for material number
  const refValuesForMaterial = refValues.filter((refValue) => {
    return refValue.materialNumber === +materialNumber
  })

  // Get last active reference value prior min date
  const priorReferenceValue = (
    min: Date,
    referenceValuesSorted: ReferenceValue[]
  ): ReferenceValue | null => {
    let priorReferenceValue = null
    for (const refValue of referenceValuesSorted) {
      if (new Date(refValue.validSince).valueOf() >= min.valueOf()) {
        break
      }
      priorReferenceValue = refValue
    }
    return priorReferenceValue
  }

  // Get last active reference values prior component´s min date
  const priorRefValue = priorReferenceValue(minDate, refValuesForMaterial)

  // Move prior reference point to min shown date of diagram
  if (priorRefValue) {
    priorRefValue.validSince = minDate.toISOString()
  }

  // Get reference values inside time range
  let refValueOnJobBeginExistent = false
  const refValuesInTimeRange = refValuesForMaterial.filter((el) => {
    const validSince = new Date(el.validSince)

    if (!refValueOnJobBeginExistent) {
      refValueOnJobBeginExistent =
        new Date(el.validSince).valueOf() === minDate.valueOf()
    }
    return (
      minDate.valueOf() <= validSince.valueOf() &&
      maxDate.valueOf() >= validSince.valueOf()
    )
  })

  const activeReferenceValues =
    priorRefValue && !refValueOnJobBeginExistent
      ? [priorRefValue, ...refValuesInTimeRange]
      : refValuesInTimeRange

  return activeReferenceValues
}

export function retrieveReferenceCycleTimeGapGrinding(
  component: CycleTimeGapGrindingDiagramComponent,
  eq: number,
  orders: Order[]
): Subject<boolean> {
  const finished$ = new Subject<boolean>()
  // Guard
  if (orders == null || orders.length === 0) {
    of(1)
      .pipe(timeout(10))
      .subscribe((val) => {
        finished$.next(true)
      })
    return finished$
  }

  // Get all reference allowance data and calculate limits
  const referenceCycleTimeGapGrindingData: TSValue[] = []
  const allReferenceValues$ = component.referenceValues.referencevaluesGet([
    eq.toString(),
  ])

  allReferenceValues$.pipe(untilDestroyed(component)).subscribe({
    next: (refValues) => {
      if (refValues == null || refValues.length === 0) {
        of(1)
          .pipe(timeout(10))
          .subscribe((val) => {
            finished$.next(true)
          })
        return
      }

      // Get all reference cycle time gap grinding
      let refCycleTimeGapGrinding = sortedReferenceValues(
        refValues[0].values.filter(
          (refVal) => refVal.name === 'ReferenceCycleTimeGapGrinding'
        )
      )

      // Sort reference cycle time gap grinding
      refCycleTimeGapGrinding = refCycleTimeGapGrinding.sort((a, b) => {
        return new Date(a.validSince).valueOf() <
          new Date(b.validSince).valueOf()
          ? -1
          : 0
      })

      const activeRefCycleTimeGapGrinding = []

      // Sort orders by start date
      const ordersSorted = orders.sort((a, b) => {
        return new Date(a.start).valueOf() < new Date(b.start).valueOf()
          ? -1
          : 0
      })

      // Calculate active reference values for each order
      for (let i = 0; i < ordersSorted.length; i++) {
        const order = ordersSorted[i]

        let minDate = order.start
        let maxDate = order.end

        if (i === 0) {
          minDate = component.minDate.toISOString()
        }

        if (i === ordersSorted.length - 1) {
          maxDate = component.maxDate.toISOString()
        }

        // Get active referencevalues for this order
        let activeRefCycleTimeGapGrindingOrder = getActiveReferenceValues(
          new Date(minDate),
          new Date(maxDate),
          order.materialnumber,
          refCycleTimeGapGrinding
        )

        // Inject NaN value if no reference values are given for order
        const injectNaNRefValueMinDate: ReferenceValue = {
          materialNumber: +order.materialnumber,
          name: 'ReferenceAllowance',
          value: 'NaN',
          unit: '',
          validSince: minDate,
        }

        activeRefCycleTimeGapGrindingOrder =
          activeRefCycleTimeGapGrindingOrder.length === 0
            ? [injectNaNRefValueMinDate]
            : activeRefCycleTimeGapGrindingOrder

        // Inject point on right border of order
        const repeatLastPointOnEndOfOrder = (
          refValues: ReferenceValue[]
        ): void => {
          const lastRefVal = refValues[refValues.length - 1]
          const injection: ReferenceValue = {
            ...lastRefVal,
            validSince: new Date(new Date(maxDate).valueOf() - 1).toISOString(),
          }
          refValues.push(injection)
        }
        repeatLastPointOnEndOfOrder(activeRefCycleTimeGapGrindingOrder)

        activeRefCycleTimeGapGrinding.push(
          ...activeRefCycleTimeGapGrindingOrder
        )
      }

      // Map active reference values to TSValue-Format
      for (const el of activeRefCycleTimeGapGrinding) {
        referenceCycleTimeGapGrindingData.push({
          ts: el.validSince,
          value: parseFloat(el.value),
        })
      }

      // Set component´s data
      component.upperLimitCycleTimeGapGrinding =
        referenceCycleTimeGapGrindingData
      finished$.next(true)
    },
    error: (err) => {
      finished$.error(err)
    },
  })
  return finished$
}
