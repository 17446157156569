import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TimeSelectionComponent } from './time-selection/time-selection.component'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker'
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter'
import { SharedMaterialModule } from '@dms-frontend/shared/material'
import { MatTimepickerModule, provideNativeDateTimeAdapter } from '@dhutaryan/ngx-mat-timepicker'

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxMatDatetimePickerModule,
    NgxMatMomentModule,
    SharedMaterialModule,
    MatTimepickerModule
  ],
  providers: [provideNativeDateTimeAdapter()],
  declarations: [TimeSelectionComponent],
  exports: [TimeSelectionComponent]
})
export class TimeSelectionModule {}
