import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

/**
 * MachinesService handling all selected machines.
 * You need to request all machines from the api service to
 * get any machines.
 * @see MachinesService
 */
@Injectable({
  providedIn: 'root',
})
export class TimeSelectionService {
  // Selected time from which to take into account | Init: 24 hours ago
  readonly from$ = new BehaviorSubject<Date>(
    new Date(Date.now() - 1 * 60 * 60 * 1000)
  )

  // Selected tim to which to take into account
  readonly to$ = new BehaviorSubject<Date>(new Date())

  selectFrom(date: Date): void {
    this.from$.next(date)
  }

  selectTo(date: Date): void {
    this.to$.next(date)
  }

  getFrom(): Date {
    return this.from$.value
  }

  getTo(): Date {
    return this.to$.value
  }
}
