/* eslint-disable */
/**
 * Cycle Referenced Process Values
 * This API manages cycle references process data
 *
 * OpenAPI spec version: 0.5.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core'
import { HttpClient, HttpEvent, HttpHeaders,
  HttpParams, HttpResponse } from '@angular/common/http'
import { CustomHttpUrlEncodingCodec } from '../encoder'

import { Observable } from 'rxjs'

import { Cycle } from '../model/cycle'

import { BASE_PATH_CONNECIVITY } from '../variables'
import { Configuration } from '../configuration'
import { Cycles } from '../model/cycles'


@Injectable()
export class CycleReferencedProcessValuesService {

  protected basePath = 'https://{customIP}:{port}/'
  defaultHeaders = new HttpHeaders()
  configuration = new Configuration()

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH_CONNECIVITY) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath
    }
    if (configuration) {
      this.configuration = configuration
      this.basePath = basePath || configuration.basePath || this.basePath
    }
  }

  /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data'
    for (const consume of consumes) {
      if (form === consume) {
        return true
      }
    }
    return false
  }


  /**
     * Get all process values of equipment number. History of 14 days in the past is available.
     *
     * @param eq Equipment number to get values from
     * @param parameterName Name of parameter
     * @param from Start of time range of values in RFC3339 format
     * @param to End of time range of values in RFC3339 format
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  cycleEqGet(eq: string, parameterName: string, from: string, to: string, observe?: 'body', reportProgress?: boolean): Observable<Cycles>;
  cycleEqGet(eq: string, parameterName: string, from: string, to: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Cycles>>;
  cycleEqGet(eq: string, parameterName: string, from: string, to: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Cycles>>;
  cycleEqGet(eq: string, parameterName: string, from: string, to: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (eq === null || eq === undefined) {
      throw new Error('Required parameter eq was null or undefined when calling cycleEqGet.')
    }

    if (parameterName === null || parameterName === undefined) {
      throw new Error('Required parameter parameterName was null or undefined when calling cycleEqGet.')
    }

    if (from === null || from === undefined) {
      throw new Error('Required parameter from was null or undefined when calling cycleEqGet.')
    }

    if (to === null || to === undefined) {
      throw new Error('Required parameter to was null or undefined when calling cycleEqGet.')
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()})
    if (parameterName !== undefined && parameterName !== null) {
      queryParameters = queryParameters.set('parameterName', <any>parameterName)
    }
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set('from', <any>from)
    }
    if (to !== undefined && to !== null) {
      queryParameters = queryParameters.set('to', <any>to)
    }

    let headers = this.defaultHeaders

    // To determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ]
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    // To determine the Content-Type header
    const consumes: string[] = [
    ]

    return this.httpClient.request<Array<Cycle>>('get',`${this.basePath}/cycle/${encodeURIComponent(String(eq))}`,
    // return this.httpClient.request<Array<Cycle>>('get',`http://localhost:8011/cycle/${encodeURIComponent(String(12469762))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    )
  }

}
/* eslint-enable */
