import { X_AXIS_OFFSET_PERCENTAGE } from '../config/config'
import { DressingTimingDiagramComponent } from './dressing-timing-diagram.component'

export function setChartOptions(
  component: DressingTimingDiagramComponent
): void {
  const xAxisLength = component.maxDate.valueOf() - component.minDate.valueOf()
  const minXAxis = new Date(
    component.minDate.valueOf() - (X_AXIS_OFFSET_PERCENTAGE / 100) * xAxisLength
  )
  const maxXAxis = new Date(
    component.maxDate.valueOf() + (X_AXIS_OFFSET_PERCENTAGE / 100) * xAxisLength
  )

  component.chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    animation: false,
    layout: {
      padding: {
        bottom: 50, // Needed for material-txt labels below x-axis
      },
    },
    scales: {
      y: {
        title: {
          text: 'Diameter in [mm]',
          font: {
            size: 14,
          },
          display: true,
        },
        offset: true,
      },
      x: {
        min: minXAxis.toISOString(),
        max: maxXAxis.toISOString(),
        offset: false,
        type: 'time',
        time: {
          tooltipFormat: 'yyyy-MM-dd HH:mm:ss',
          displayFormats: {
            millisecond: 'HH:mm',
            second: 'HH:mm',
            minute: 'HH:mm',
            hour: 'HH:mm',
            day: 'HH:mm',
            week: 'HH:mm',
            month: 'HH:mm',
            quarter: 'HH:mm',
            year: 'HH:mm',
          },
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  }
}
