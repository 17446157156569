import { Component, Input } from '@angular/core'
import { IconStructure } from '../type/icon-structure.interface'

@Component({
  selector: 'dms-frontend-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss']
})
export class MenuHeaderComponent {
   // Input properties
   @Input() iconStructure!: IconStructure
}
