<div class="container relative">
  <h5 class="ml-4 pt-4 text-h5 font-medium">History of Reference Values</h5>
  <hr class="hr mt-4 mb-2 text-grey-400" />
  <div class="container ml-4 flex flex-col text-h6">
    <div class="flex flex-row">
      <div class="w-56">Equipment Number:</div>
      <div>{{ data.equipmentNumber }}</div>
    </div>
    <div class="flex flex-row text-h6">
      <div class="w-56">Material Number:</div>
      <div>{{ data.materialNumber }}</div>
    </div>
    <!--
    <div class="flex flex-row justify-start text-h6">
      <div class="w-56">Material Text:</div>
      <div>{{data.materialText}}</div>
    </div>
    -->
  </div>
  <hr class="mt-2 mb-4 text-grey-400" />

  <!-- Table -->
  <table mat-table [dataSource]="dataSource" class="bg-background-dark ml-1">
    <!-- Allowance Position -->
    <ng-container matColumnDef="Allowance Position [um]">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="pl-4 text-h5 table-th-top"
        colspan="2"
      >
        Allowance Position [mm]
      </th>
    </ng-container>
    <ng-container matColumnDef="Allowance Position [um] Value">
      <th mat-header-cell *matHeaderCellDef class="pl-4 text-left">Value</th>
      <td mat-cell *matCellDef="let element" class="pl-4 text-left">
        {{ element.allowancePosition | number: '':'de-DE' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Allowance Position [um] Valid Since">
      <th mat-header-cell *matHeaderCellDef class="pl-4 text-left">
        Valid since
      </th>
      <td mat-cell *matCellDef="let element" class="pl-4 text-left">
        {{ element.allowancePositionValidSince | date: dateFormat }}
      </td>
    </ng-container>

    <!-- Allowance -->
    <ng-container matColumnDef="Allowance [um]">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="pl-4 text-h5 table-th-top"
        colspan="2"
      >
        Allowance [mm]
      </th>
    </ng-container>
    <ng-container matColumnDef="Allowance [um] Value">
      <th mat-header-cell *matHeaderCellDef class="pl-4 text-left">Value</th>
      <td mat-cell *matCellDef="let element" class="pl-4 text-left">
        {{ element.allowance | number: '':'de-DE' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Allowance [um] Valid Since">
      }
      <th mat-header-cell *matHeaderCellDef class="pl-4 text-left">
        Valid since
      </th>
      <td mat-cell *matCellDef="let element" class="pl-4 text-left">
        {{ element.allowanceValidSince | date: dateFormat }}
      </td>
    </ng-container>

    <!-- Allowance Tolerance -->
    <ng-container matColumnDef="Allowance Tolerance +- [um]">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="pl-4 text-h5 table-th-top"
        colspan="2"
      >
        Allowance Tolerance +- [mm]
      </th>
    </ng-container>
    <ng-container matColumnDef="Allowance Tolerance +- [um] Value">
      <th mat-header-cell *matHeaderCellDef class="pl-4 text-left">Value</th>
      <td mat-cell *matCellDef="let element" class="pl-4 text-left">
        {{ element.allowanceTolerance | number: '':'de-DE' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Allowance Tolerance +- [um] Valid Since">
      <th mat-header-cell *matHeaderCellDef class="pl-4 text-left">
        Valid since
      </th>
      <td mat-cell *matCellDef="let element" class="pl-4 text-left">
        {{ element.allowanceToleranceValidSince | date: dateFormat }}
      </td>
    </ng-container>

    <!-- Grinding Time -->
    <ng-container matColumnDef="Grinding Time [s]">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="pl-4 text-h5 table-th-top"
        colspan="2"
      >
        Grinding Time [s]
      </th>
    </ng-container>
    <ng-container matColumnDef="Grinding Time [s] Value">
      <th mat-header-cell *matHeaderCellDef class="pl-4 text-left">Value</th>
      <td mat-cell *matCellDef="let element" class="pl-4 text-left">
        {{ element.grindingTime | number: '':'de-DE' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Grinding Time [s] Valid Since">
      <th mat-header-cell *matHeaderCellDef class="pl-4 text-left">
        Valid since
      </th>
      <td mat-cell *matCellDef="let element" class="pl-4 text-left">
        {{ element.grindingTimeValidSince | date: dateFormat }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="headerColumns"></tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    class="bg-background-dark"
    [length]="100"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 25, 100]"
    aria-label="Select page"
  >
  </mat-paginator>
  <br />

  <!-- Cancel -->
  <div class="container flex flex-row justify-end">
    <button mat-raised-button color="primary" (click)="close()">CANCEL</button>
  </div>
</div>

<mat-progress-spinner
  *ngIf="loading$ | async"
  class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
  [mode]="'indeterminate'"
>
</mat-progress-spinner>
