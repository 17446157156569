import { CategoryEnum } from '@dms-frontend/tds/services/api'
import { Subject, delay, of, timeout } from 'rxjs'
import { StateValue } from '../config/config'
import { SubsystemComponent } from '../subsystem.component'

export function retrieveState(
  component: SubsystemComponent,
  from: Date,
  to: Date,
  eq: number
): Subject<boolean> {
  const finished$ = new Subject<boolean>()

  const subscription = component.kpiService
    .stateProcessconfigEqGet(
      eq.toString(),
      from.toISOString(),
      to.toISOString()
    )
    .pipe(delay(1000))
    .subscribe(
      (stateValues) => {
        if (
          stateValues == null ||
          stateValues.states == null ||
          stateValues.states.length === 0
        ) {
          of(1)
            .pipe(timeout(10))
            .subscribe((val) => {
              finished$.next(true)
            })
          return
        }

        const currentStates: StateValue[] = stateValues.states
          .filter((states) => {
            return states.category === CategoryEnum.ProcessConfig
          })
          .map((states) => {
            return {
              name: states.states[0].name,
              ts: states.ts,
              state: states.states[0].value,
            }
          })

        component.stateData = currentStates
        finished$.next(true)
      },
      (err) => {
        finished$.next(true)
      }
    )

  component.subscription.add(subscription)
  return finished$
}
