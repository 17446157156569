/**
 * KPI Service
 * This API returns calculated KPIs
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Result {
  /**
   * Calculated KPI value
   */
  value: number
  /**
   * Timestamp grouping a cylce in time
   */
  ts: string
}
