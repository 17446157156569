<div class="md:pt-2">
  <dms-frontend-dashboard-header
    [refreshIsRefreshingSomething]="anyDiagramRefreshable"
    [disableRefresh]="
      (dressingOverviewLoading && showOverview) ||
      (dressingTimingLoading && showTiming)
    "
    [class.pointer-events-none]="loading$ | async"
  ></dms-frontend-dashboard-header>
  <mat-accordion multi>
    <!-- Tool Consumption Overview -->
    <mat-expansion-panel class="mr-4">
      <mat-expansion-panel-header
        (click)="showToolConsumptionOverview()"
        class="text-body-1"
      >
        <mat-panel-title> Tool Consumption Overview </mat-panel-title>
      </mat-expansion-panel-header>
      <div
        class="pl-10 pr-10 pb-10 w-full h-full flex flex-col overflow-x-auto"
      >
        <dms-frontend-dressing-consumption-overview
          *ngIf="showOverview"
        ></dms-frontend-dressing-consumption-overview>
      </div>
    </mat-expansion-panel>

    <!-- Grinding Wheel Diameter - Timing Diagram -->
    <mat-expansion-panel class="mr-4">
      <mat-expansion-panel-header
        class="text-body-1"
        (click)="loadTimingDiagrams()"
      >
        <mat-panel-title>
          Grinding Wheel Diameter - Timing Diagram
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div>
        <div class="pl-12 pr-12 w-full h-full">
          <dms-frontend-dressing-timing-diagram
            *ngIf="showTiming"
          ></dms-frontend-dressing-timing-diagram>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
