/**
 * KPI Service
 * This API returns calculated KPIs
 *
 * OpenAPI spec version: 0.2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http'
import { Inject, Injectable, Optional } from '@angular/core'
import { CustomHttpUrlEncodingCodec } from '../encoder'

import { Observable } from 'rxjs'

import { AllowanceMonitorKPIs } from '../model/allowanceMonitorKPIs'

import { Configuration } from '../configuration'
import { BASE_PATH_CONNECIVITY } from '../variables'

@Injectable()
export class AllowanceMonitorService {
  protected basePath = 'https://{customIP}:{port}/'
  defaultHeaders = new HttpHeaders()
  configuration = new Configuration()

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH_CONNECIVITY) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath
    }
    if (configuration) {
      this.configuration = configuration
      this.basePath = basePath || configuration.basePath || this.basePath
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data'
    for (const consume of consumes) {
      if (form === consume) {
        return true
      }
    }
    return false
  }

  /**
   * Get all relevant KPIs for TDS feature allowance monitor of equipment number
   *
   * @param eq Equipment number to get values from
   * @param from Start of time range of values
   * @param to End of time range of values
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  kpiAllowancemonitorEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<AllowanceMonitorKPIs>
  kpiAllowancemonitorEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<AllowanceMonitorKPIs>>
  kpiAllowancemonitorEqGet(
    eq: string,
    from: string,
    to: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<AllowanceMonitorKPIs>>
  kpiAllowancemonitorEqGet(
    eq: string,
    from: string,
    to: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (eq == null) {
      throw new Error(
        'Required parameter eq was null or undefined when calling kpiAllowancemonitorEqGet.'
      )
    }

    if (from == null) {
      throw new Error(
        'Required parameter from was null or undefined when calling kpiAllowancemonitorEqGet.'
      )
    }

    if (to == null) {
      throw new Error(
        'Required parameter to was null or undefined when calling kpiAllowancemonitorEqGet.'
      )
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    })
    if (from != null) {
      queryParameters = queryParameters.set('from', <any>from)
    }
    if (to != null) {
      queryParameters = queryParameters.set('to', <any>to)
    }

    let headers = this.defaultHeaders

    // To determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json']
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    // To determine the Content-Type header
    const consumes: string[] = []

    return this.httpClient.request<AllowanceMonitorKPIs>(
      'get',
      `${this.basePath}/kpi/allowancemonitor/${encodeURIComponent(String(eq))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }
}
