import { SubsystemComponent } from './subsystem.component'

export function setChartOptions(component: SubsystemComponent): void {
  component.chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    animation: false,
    layout: {
      padding: {
        bottom: 50, // Needed for material-txt labels below x-axis
      },
    },
    scales: {
      x: {
        min: component.minDate.toISOString(),
        max: component.maxDate.toISOString(),
        grid: {
          display: false,
        },
        offset: false,
        type: 'time',
        time: {
          tooltipFormat: 'yyyy-MM-dd HH:mm:ss',
          displayFormats: {
            millisecond: 'HH:mm',
            second: 'HH:mm',
            minute: 'HH:mm',
            hour: 'HH:mm',
            day: 'HH:mm',
            week: 'HH:mm',
            month: 'HH:mm',
            quarter: 'HH:mm',
            year: 'HH:mm',
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        type: 'category',
        labels: [
          '',
          'Gap control',
          'Sound quality',
          'Touch control',
          'Auto rough grinding',
          'Auto spark out',
          'Override 100%',
          '',
        ],
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
  }
}
