import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDateFormats,
} from '@angular-material-components/datetime-picker'
import { registerLocaleData } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import localeDe from '@angular/common/locales/de'
import { NgModule, importProvidersFrom } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {
  MsalGuard,
  MsalInterceptor,
  MsalRedirectComponent,
} from '@azure/msal-angular'
import { MAT_TIMEPICKER_DEFAULT_OPTIONS, MAT_TIME_LOCALE, MatTimepickerDefaultOptions } from '@dhutaryan/ngx-mat-timepicker'
import { SharedMaterialModule } from '@dms-frontend/shared/material'
import {
  AzureConfig,
  MsalGuardConfig,
  MsalInstanceConfig,
  MsalInterceptorConfig,
  ProtectedResource,
  SharedAzureAuthModule,
} from '@dms-frontend/shared/services/azure-auth'
import { MachineSelectionModule } from '@dms-frontend/shared/ui/machine-selection'
import { SharedUiNavigationBarModule } from '@dms-frontend/shared/ui/navigation-bar'
import { SharedUiStatusTileModule } from '@dms-frontend/shared/ui/status-tile'
import { TIME_SELECTION_DATE_FORMAT, TIME_SELECTION_TIME_FORMAT, TimeSelectionModule } from '@dms-frontend/shared/ui/time-selection'
import {
  ApiMachinesService,
  TdsServicesApiOldModule,
} from '@dms-frontend/tds-services-api-old'
import { TdsUiAllowanceChartsModule } from '@dms-frontend/tds-ui-allowance-charts'
import { TdsUiLineStatesModule } from '@dms-frontend/tds-ui-line-states'
import { CreateMaterialNumberModule } from '@dms-frontend/tds-ui-material-number-dialogs'
import {
  BASE_PATH_CONNECIVITY,
  BASE_PATH_CONNETIVITY_LEGACY,
  BASE_PATH_TDS,
  TdsServicesApiModule,
} from '@dms-frontend/tds/services/api'
import { TdsUiDashboardHeaderModule } from '@dms-frontend/tds/ui/dashboard-header'
import { DressingConsumptionOverviewComponent } from '@dms-frontend/tds/ui/dressing-consumption-overview'
import { DressingTimingDiagramComponent } from '@dms-frontend/tds/ui/dressing-monitor-charts'
import { TdsUiOverviewMaterialnumbersModule } from '@dms-frontend/tds/ui/overview-materialnumbers'
import { SubsystemComponent } from '@dms-frontend/tds/ui/process-config-charts'
import { TdsUiTilesModule } from '@dms-frontend/tds/ui/tiles'
import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AllowanceMonitorComponent } from './pages/dashboard/allowance-monitor/allowance-monitor.component'
import { CycleTimeMonitorComponent } from './pages/dashboard/cycle-time-monitor/cycle-time-monitor.component'
import { OverviewComponent } from './pages/dashboard/overview/overview.component'
import { ProcessConfigComponent } from './pages/dashboard/process-config/process-config.component'
import { DressingMonitorComponent } from './pages/dressing-monitor/dressing-monitor.component'
import { HomeComponent } from './pages/home/home.component'
import { ReferenceValuesComponent } from './pages/reference-values/reference-values.component'
registerLocaleData(localeDe)

const API_URL = 'http://localhost:3333'

const azureConfig = new AzureConfig(
  new MsalInstanceConfig(
    environment.clientId,
    environment.tenantId,
    !environment.production
  ),
  new MsalInterceptorConfig([
    new ProtectedResource('/api/*', [environment.appScope]), // The resources the scope is needed
  ]),
  new MsalGuardConfig('/login-failed', [
    // Redirect URI for failed login requests
    environment.appScope,
  ])
)

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'YYYY-MM-DD HH:mm:ss',
  },
  display: {
    dateInput: 'YYYY-MM-DD HH:mm:ss',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
}

const CUSTOM_TIME_FORMATS: MatTimepickerDefaultOptions = {
  format: '24h',
  minuteInterval: 1,
  mode: 'dial',
  openAs: 'popup',
  showToggleModeButton: false,
  orientation: 'vertical'
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    OverviewComponent,
    ReferenceValuesComponent,
    AllowanceMonitorComponent,
    ProcessConfigComponent,
    CycleTimeMonitorComponent,
    DressingMonitorComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    SharedAzureAuthModule.forRoot(azureConfig),
    SharedMaterialModule,
    SharedUiNavigationBarModule,
    SharedUiStatusTileModule,
    TdsServicesApiOldModule,
    CreateMaterialNumberModule,
    TdsUiAllowanceChartsModule,
    TdsUiLineStatesModule,
    TdsUiOverviewMaterialnumbersModule,
    TdsServicesApiModule,
    TdsUiDashboardHeaderModule,
    MachineSelectionModule,
    TimeSelectionModule,
    TdsUiTilesModule,
    FormsModule,
    SubsystemComponent,
    DressingTimingDiagramComponent,
    DressingConsumptionOverviewComponent,
  ],
  providers: [
    importProvidersFrom(
      [MatSnackBarModule]
    ),
    ApiMachinesService,
    {
      provide: 'API_BASE_URL',
      useValue: API_URL,
    },
    {
      provide: BASE_PATH_TDS,
      useValue: environment.apiUrlTds,
    },
    {
      provide: BASE_PATH_CONNECIVITY,
      useValue: environment.apiUrlConnectivity,
    },
    {
      provide: BASE_PATH_CONNETIVITY_LEGACY,
      useValue: environment.apiUrlConnectivityLegacy,
    },
    {
      provide: 'API_MACHINES_PATH',
      useValue: 'machines',
    },
    {
      provide: 'API_STATE_PATH',
      useValue: 'state',
    },
    {
      provide: NGX_MAT_DATE_FORMATS,
      useValue: CUSTOM_DATE_FORMATS,
    },
    {
      provide: TIME_SELECTION_DATE_FORMAT,
      useValue: 'yyyy-MM-dd',
    },
    {
      provide: TIME_SELECTION_TIME_FORMAT,
      useValue: 'HH:mm',
    },
    {
      provide: 'APP_ID',
      useValue: environment.clientId,
    },
    {provide: MAT_TIME_LOCALE, useValue: 'de-DE'},
    {provide: MAT_TIMEPICKER_DEFAULT_OPTIONS, useValue: CUSTOM_TIME_FORMATS},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalGuard,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
