import { AllowanceMonitorKPIs, Order, Parameter } from '@dms-frontend/tds/services/api'
import {
  Observable,
  Subject,
  catchError,
  delay,
  forkJoin,
  map,
  of,
  timeout,
} from 'rxjs'
import { DECIMAL_PLACES, TSValue } from '../../config/config'
import { AllowanceDiagrammComponent } from '../allowance-diagramm.component'
import { untilDestroyed } from '@ngneat/until-destroy'
import { HttpErrorResponse } from '@angular/common/http'

export function retrieveCurrentAllowances(
  component: AllowanceDiagrammComponent,
  from: Date,
  to: Date,
  eq: number
): Subject<boolean> {
  const finished$ = new Subject<boolean>()

  component.cycleReferencedValues
    .cycleEqGet(eq.toString(), 'CurrentAllowance', from.toISOString(), to.toISOString())
    .pipe(delay(1000))
    .pipe(untilDestroyed(component))
    .subscribe(
      (cycles) => {
        if (
          cycles == null ||
          cycles.cycles == null ||
          cycles.cycles.length === 0
        ) {
          of(1)
            .pipe(timeout(10))
            .subscribe((val) => {
              finished$.next(true)
            })
          return
        }
        const cycleValues = cycles.cycles
        const currentAllowances = cycleValues
          .map((cycle) => {
            const currentAllowance = cycle.parameters.filter(
              (parameter) => {
                return parameter.name === 'CurrentAllowance'
              }
            )[0] as Parameter

            return currentAllowance.double === undefined
              ? null
              : {
                ts: cycle.ts,
                value: parseFloat(
                  (currentAllowance.double * 1000).toFixed(
                    DECIMAL_PLACES
                  )
                ),
              }
          })
          .filter((el) => el != null) as TSValue[]

        component.currentAllowances = currentAllowances
        finished$.next(true)
      },
      (error: HttpErrorResponse) => {
        component.snackBar.open(`${error.status}: ${error.statusText}` , 'Close')
        finished$.error(true)
      }
    )

  return finished$
}

export function retrieveKPIs(
  component: AllowanceDiagrammComponent,
  eq: number,
  orders: Order[]
): Subject<boolean> {
  const finished$ = new Subject<boolean>()

  if (orders.length === 0) {
    of(1)
      .pipe(timeout(10))
      .subscribe((val) => {
        finished$.next(true)
      })
    return finished$
  }

  const averageCurrentData: TSValue[] = []
  const suggestionsAllowancePositions: TSValue[] = []
  const allowanceMonitorKPIObs$: Observable<AllowanceMonitorKPIs>[] = []
  const allowanceMonitorKPIDummy: AllowanceMonitorKPIs = {
    allowancePosition: undefined,
    averageCurrentAllowance: undefined,
    averageCycleTimeProcessing: undefined,
    averageGapTimeActual: undefined,
    cycleTimeProcessing: undefined,
    deltaTWithSuggestionAllowancePosition: undefined,
    suggestionAllowancePosition: undefined,
  }

  for (const order of orders) {
    let endDate = order.end
    if (endDate === '') {
      endDate = component.maxDate.toISOString()
    }
    let startDate = order.start
    if (new Date(startDate) < component.minDate) {
      startDate = component.minDate.toISOString()
    }
    // MAP error in request to be retruned as 0 Values
    const averageCurrentAllowance$ = component.allowanceKpi
      .kpiAllowancemonitorEqGet(eq.toString(), startDate, endDate)
      .pipe(
        map((res) => res),
        catchError((e) => of(allowanceMonitorKPIDummy))
      )
    allowanceMonitorKPIObs$.push(averageCurrentAllowance$)
    averageCurrentData.push({
      ts: startDate,
      value: NaN,
    })
    suggestionsAllowancePositions.push({
      ts: startDate,
      value: NaN,
    })
  }
  forkJoin(allowanceMonitorKPIObs$)
    .pipe(untilDestroyed(component))
    .subscribe({
      next: (allowanceMonitorKPIs) => {
        for (const [i, v] of allowanceMonitorKPIs.entries()) {
          // Writing data for average current allowance
          if (v.averageCurrentAllowance === undefined) {
            averageCurrentData[i].value = NaN
          } else {
            averageCurrentData[i].value = v.averageCurrentAllowance.value * 1000
          }
          // Writing data for suggestion allowance position
          if (v.suggestionAllowancePosition === undefined) {
            suggestionsAllowancePositions[i].value = NaN
          } else {
            suggestionsAllowancePositions[i].value =
              v.suggestionAllowancePosition.value * 1000
          }
        }
        // Get correct end date
        let endDate = orders.slice(-1)[0].end
        if (endDate === '') {
          endDate = component.maxDate.toISOString()
        }

        if (suggestionsAllowancePositions.length !== 0) {
          suggestionsAllowancePositions.push({
            ts: endDate,
            value: suggestionsAllowancePositions.slice(-1)[0].value,
          })
        }
        component.suggestionsAllowancePositions = suggestionsAllowancePositions
        if (averageCurrentData.length !== 0) {
          averageCurrentData.push({
            ts: endDate,
            value: averageCurrentData.slice(-1)[0].value,
          })
        }
        component.averagesCurrentAllowance = averageCurrentData
        finished$.next(true)
      },
      error: (err) => {
        finished$.error(err)
      },
    })
  return finished$
}

export function retrieveAllowancePos(
  component: AllowanceDiagrammComponent,
  from: Date,
  to: Date,
  eq: number
): Subject<boolean> {
  const finished$ = new Subject<boolean>()

  component.allowanceKpi
    .kpiAllowancemonitorEqGet(
      eq.toString(),
      from.toISOString(),
      to.toISOString()
    )
    .pipe(untilDestroyed(component))
    .subscribe(
      (allowancePositionsKPIs) => {
        if (allowancePositionsKPIs.allowancePosition != null) {
          component.allowancePositions =
            allowancePositionsKPIs.allowancePosition.map((el) => {
              return {
                ts: el.ts,
                value: parseFloat((el.value * 1000).toFixed(DECIMAL_PLACES)),
              }
            })
        }
        finished$.next(true)
      },
      (error: HttpErrorResponse) => {
        component.snackBar.open(`${error.status}: ${error.statusText}` , 'Close')
        finished$.error(error)
      }
    )
  return finished$
}
