import {
  ReferenceValue,
  ReferenceValueNames,
  ReferenceValues,
  newestReferenceValue,
} from '@dms-frontend/tds/services/api'

/**
 * Partial of ReferenceValue needed as input for Reference Value Dialogs,
 * because materialNumber can possibly be undefined in dialog, if dummy
 * ReferenceValues are shown
 */
export interface ReferenceValueDummy extends Partial<ReferenceValue> {
  materialNumber?: number // Make materialNumber possibly undefined
  name: ReferenceValueNames
  value?: string // Make value possibly undefined
  unit?: string // Make unit possibly undefined
  validSince: string
}

/**
 * Filter an referenceValuesArray for the correct equipmentNumber
 * @param referenceValuesArray
 * @param equipmentNumber
 * @returns
 */
export function _filterForEQ(
  referenceValuesArray: ReferenceValues[],
  equipmentNumber: number | null | undefined
): ReferenceValues | null {
  if (equipmentNumber == null) {
    return null
  }

  const referenceValuesForEQ = referenceValuesArray.filter(
    (referenceValues) => {
      return referenceValues.equipmentNumber === equipmentNumber
    }
  )

  return referenceValuesForEQ[0]
}

/**
 * Get all newest ReferenceValues of name ReferenceValueNames.$name of an equipment.
 * $name ∈ {ReferenceAllowancePosition, ReferenceAllowance, ReferenceTolerance, ReferenceCycleTimeGapGrinding}
 *
 * @param referenceValuesArray all ReferenceValues for all equipments
 * @param equipmentNumber
 * @returns
 */
export function _getNewestReferenceValues(
  referenceValuesArray: ReferenceValues[],
  equipmentNumber: number | null | undefined,
  materialNumber: number | null | undefined
): ReferenceValueDummy[] {
  if (referenceValuesArray == null) {
    return []
  }
  const referenceValueForEQ = _filterForEQ(
    referenceValuesArray,
    equipmentNumber
  )
  if (referenceValueForEQ == null) {
    return []
  }

  const referenceAllowancePosition = newestReferenceValue(
    referenceValueForEQ,
    ReferenceValueNames.ReferenceAllowancePosition,
    materialNumber
  )
  const referenceAllowance = newestReferenceValue(
    referenceValueForEQ,
    ReferenceValueNames.ReferenceAllowance,
    materialNumber
  )
  const referenceTolerance = newestReferenceValue(
    referenceValueForEQ,
    ReferenceValueNames.ReferenceAllowanceTolerance,
    materialNumber
  )
  const referenceGrindingTime = newestReferenceValue(
    referenceValueForEQ,
    ReferenceValueNames.ReferenceCycleTimeGapGrinding,
    materialNumber
  )

  // Set all current reference values as input for reference values component
  const referenceValues: ReferenceValueDummy[] = []
  if (referenceAllowancePosition != null) {
    referenceValues.push(referenceAllowancePosition)
  }
  if (referenceAllowance != null) {
    referenceValues.push(referenceAllowance)
  }
  if (referenceTolerance != null) {
    referenceValues.push(referenceTolerance)
  }
  if (referenceGrindingTime != null) {
    referenceValues.push(referenceGrindingTime)
  }

  return referenceValues
}

/**
 * Maps the ReferenceValueNames.ReferenceAllowance, ReferenceValueNames.ReferenceAllowancePosition
 * and ReferenceValueNames.ReferenceAllowanceTolerance values to millimeter.
 * @warning
 * !!! ATTENTION !!! The conversion is executed by reference (in situ/in place!)
 * A value is only returned to support convenient usage in Rxjs map-Operator
 * @param referenceValues
 * @returns
 */
export function mapReferenceValuesToMillimeter(
  referenceValues: ReferenceValues
): ReferenceValues {
  for (const referenceValue of referenceValues.values) {
    if (
      referenceValue.name === ReferenceValueNames.ReferenceAllowance ||
      referenceValue.name === ReferenceValueNames.ReferenceAllowancePosition ||
      referenceValue.name === ReferenceValueNames.ReferenceAllowanceTolerance
    ) {
      referenceValue.value = (
        parseFloat(referenceValue.value) * 1000
      ).toString()
    }
  }
  return referenceValues
}

/**
 * Maps certain ReferenceValues to millimeter.
 * @warning
 * !!! ATTENTION !!! The conversion is executed by reference (in situ/in place!)
 * A value is only returned to support convenient usage in Rxjs map-Operator
 * @param referenceValuesArray
 * @returns
 */
export function mapReferenceValuesArrayToMillimeter(
  referenceValuesArray: ReferenceValues[]
): ReferenceValues[] {
  for (const referenceValues of referenceValuesArray) {
    mapReferenceValuesToMillimeter(referenceValues)
  }
  return referenceValuesArray
}

/**
 * Maps the ReferenceValueNames.ReferenceAllowance, ReferenceValueNames.ReferenceAllowancePosition
 * and ReferenceValueNames.ReferenceAllowanceTolerance values from millimeter to meter.
 * @warning
 * !!! ATTENTION !!! The conversion is executed by reference (in situ/in place!)
 * A value is only returned to support convenient usage in Rxjs map-Operator
 * @param referenceValues
 * @returns
 */
export function mapReferenceValuesToMeter(
  referenceValues: ReferenceValues
): ReferenceValues {
  for (const referenceValue of referenceValues.values) {
    if (
      referenceValue.name === ReferenceValueNames.ReferenceAllowance ||
      referenceValue.name === ReferenceValueNames.ReferenceAllowancePosition ||
      referenceValue.name === ReferenceValueNames.ReferenceAllowanceTolerance
    ) {
      referenceValue.value = (
        parseFloat(referenceValue.value) / 1000
      ).toString()
    }
  }
  return referenceValues
}
