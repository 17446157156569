/**
 * Reference Values
 * This API manages reference values stored in TDS
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
export type NameEnum =
  | 'ReferenceAllowancePosition'
  | 'ReferenceAllowance'
  | 'ReferenceAllowanceTolerance'
  | 'ReferenceCycleTimeGapGrinding'
export const NameEnum = {
  ReferenceAllowancePosition: 'ReferenceAllowancePosition' as NameEnum,
  ReferenceAllowance: 'ReferenceAllowance' as NameEnum,
  ReferenceAllowanceTolerance: 'ReferenceAllowanceTolerance' as NameEnum,
  ReferenceCycleTimeGapGrinding: 'ReferenceCycleTimeGapGrinding' as NameEnum
}

export interface ReferenceValue {
  materialNumber: number
  name: NameEnum
  value: string
  unit: string
  validSince: string
}
