import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import {
  CreateMaterialNumberDialogComponent,
  CreateMaterialNumberDialogInput,
  EditMaterialNumberDialogComponent,
  EditMaterialNumberDialogInput,
} from '@dms-frontend/tds-ui-material-number-dialogs'
import { BehaviorSubject, take } from 'rxjs'

import { MachineSelectionService } from '@dms-frontend/shared/services/machine-selection'
import { DashboardService } from '@dms-frontend/tds/services/dashboard'

// In milliseconds | 30 seconds
@Component({
  selector: 'dms-frontend-allowance-monitor',
  templateUrl: './allowance-monitor.component.html',
  styleUrls: ['./allowance-monitor.component.scss'],
})
export class AllowanceMonitorComponent implements OnInit {
  loading$ = new BehaviorSubject<boolean>(false)

  anyDiagramRefreshable = false

  // Lazy load diagrams to avoid network traffic
  showAllowance = false
  showTiming = false
  showHistogram = false

  allowanceDiagramLoading = false
  timingDiagramLoading = false
  histogramLoading = false

  constructor(
    public dialog: MatDialog,
    public dashboardService: DashboardService,
    private machineSelectionService: MachineSelectionService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.dashboardService.allowanceDiagramLoading$.subscribe(
      (allowanceDiagramLoading) => {
        this.allowanceDiagramLoading = allowanceDiagramLoading
        // Trigger change detection as value is changed by child component and therefore
        // Triggered after change detection for this component has been executed
        this.cd.detectChanges()
      }
    )
    this.dashboardService.timingDiagramLoading$.subscribe(
      (timingDiagramLoading) => {
        this.timingDiagramLoading = timingDiagramLoading
        // Trigger change detection as value is changed by child component and therefore
        // Triggered after change detection for this component has been executed
        this.cd.detectChanges()
      }
    )
    this.dashboardService.histogramLoading$.subscribe((histogramLoading) => {
      this.histogramLoading = histogramLoading
      // Trigger change detection as value is changed by child component and therefore
      // Triggered after change detection for this component has been executed
      this.cd.detectChanges()
    })
  }

  addMaterialDialog(): void {
    this.machineSelectionService.selectedEquipment$
      .pipe(take(1))
      .subscribe((selectedEquipment) => {
        const data: CreateMaterialNumberDialogInput = {
          equipmentNumber: selectedEquipment,
        }
        this.dialog.open(CreateMaterialNumberDialogComponent, { data: data })
      })
  }

  editMaterialDialog(): void {
    const data: EditMaterialNumberDialogInput = {
      materialText: 'MATERIAL_TEXT',
    }

    this.machineSelectionService.selectedEquipment$
      .pipe(take(1))
      .subscribe((selectedEquipment) => {
        if (selectedEquipment != null) {
          data.equipmentNumber = selectedEquipment
        }

        this.dialog.open(EditMaterialNumberDialogComponent, { data: data })
      })
  }

  loadAllowance(): void {
    this.showAllowance = !this.showAllowance
    this.anyDiagramLoadable()
  }

  loadTiming(): void {
    this.showTiming = !this.showTiming
    this.anyDiagramLoadable()
  }

  loadHistogram(): void {
    this.showHistogram = !this.showHistogram
    this.anyDiagramLoadable()
  }

  anyDiagramLoadable(): void {
    this.anyDiagramRefreshable = this.showHistogram || this.showAllowance || this.showTiming
  }
}
