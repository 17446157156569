<div class="container flex flex-col align-middle justify-center" [class.pointer-events-none]="loading$ | async">
  <h5 tabindex="0" class="font-bold">Edit Material Number</h5>
  <br />
  <!-- Equipment Number -->
  <form class="inline">
    <mat-form-field class="w-full" appearance="fill">
      <mat-label>Equipment Number*</mat-label>
      <input #autoCompleteInput size="5" type="number" placeholder="Pick one" aria-label="Number" matInput
        [formControl]="equipmentNumberControl" [matAutocomplete]="equipmentNumberAutocomplete" />
      <mat-autocomplete #equipmentNumberAutocomplete (closed)="equipmentNumbersClosed()">
        <mat-option *ngFor="let option of filteredEquipmentNumbers | async" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
      <mat-error>Equipment number does not exist</mat-error>
    </mat-form-field>
  </form>

  <!-- Material Number -->
  <form class="inline">
    <mat-form-field class="w-full" appearance="fill">
      <mat-label>Material Number*</mat-label>
      <input size="5" type="number" placeholder="Set material number" aria-label="Number" matInput
        [formControl]="materialNumberControl" [matAutocomplete]="materialNumberAutocomplete" />
      <mat-autocomplete #materialNumberAutocomplete (closed)="materialNumbersClosed()">
        <mat-option *ngFor="let option of filteredMaterialNumbers | async" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
      <mat-error>Material number does not exist for this equipment</mat-error>
    </mat-form-field>
  </form>

  <!-- Material Text-->
  <!--
  <form *ngIf="materialNumberSelected" class="inline">
    <mat-form-field class="w-full" appearance="fill">
      <mat-label>Material Text</mat-label>
      <input size="5" type="text" placeholder="Matrial Text" matInput disabled [value]="materialText" />
    </mat-form-field>
  </form>
-->

  <!-- Reference Values -->
  <dms-frontend-reference-values *ngIf="materialNumberSelected" [(referenceValues)]="referenceValues"
    (validChange)="referenceValuesValid = $event">
  </dms-frontend-reference-values>
  <br />

  <!-- Dialog Buttons -->
  <div class="container flex flex-row justify-end">
    <button mat-button color="primary" class="mr-2" (click)="cancel()">
      CANCEL
    </button>
    <button *ngIf="!materialNumberSelected" mat-raised-button color="primary" (click)="next()">
      NEXT
    </button>
    <button *ngIf="materialNumberSelected" mat-raised-button color="primary" (click)="save()">
      SAVE
    </button>
  </div>
</div>

<mat-progress-spinner *ngIf="loading$ | async"
  class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" [mode]="'indeterminate'">
</mat-progress-spinner>
