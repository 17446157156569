import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CreateMaterialNumberDialogComponent } from './create-material-number-dialog/create-material-number-dialog.component'
import { SharedMaterialModule } from '@dms-frontend/shared/material'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BrowserModule } from '@angular/platform-browser'
import { ReferenceValuesComponent } from './reference-values/reference-values.component'
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker'
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter'
import { EditMaterialNumberDialogComponent } from './edit-material-number-dialog/edit-material-number-dialog.component'
import { HistoryReferenceValuesDialogComponent } from './history-reference-values-dialog/history-reference-values-dialog.component'
import { SharedDirectivesModule } from '@dms-frontend/shared/directives'

@NgModule({
  imports: [
    CommonModule,
    SharedMaterialModule,
    SharedDirectivesModule,
    FormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    ReactiveFormsModule,
    NgxMatDatetimePickerModule,
    NgxMatMomentModule
  ],
  declarations: [
    CreateMaterialNumberDialogComponent,
    ReferenceValuesComponent,
    EditMaterialNumberDialogComponent,
    HistoryReferenceValuesDialogComponent
  ],
  exports: [
    CreateMaterialNumberDialogComponent,
    EditMaterialNumberDialogComponent,
    HistoryReferenceValuesDialogComponent
  ]
})
export class CreateMaterialNumberModule {}
