<!-- Header -->
<div
  class="flex flex-row flex-wrap"
  [class.pointer-events-none]="loading$ | async"
>
  <mat-slide-toggle
    class="mr-2 w-42 self-center mb-[21.5px]"
    [(ngModel)]="autoRefresh"
    [checked]="autoRefresh"
    [disabled]="disabled || disableAutoRefresh"
  >
    Auto Refresh
  </mat-slide-toggle>
  <dms-frontend-machine-selection class="mr-2"></dms-frontend-machine-selection>
  <dms-frontend-time-selection
    [disableFromSelection]="disableFromSelection"
  ></dms-frontend-time-selection>
  <!-- Refresh -->
  <button
    [disabled]="disableRefresh"
    class="ml-2 ml-2 inline-block rounded-full w-9 h-9 shadow-md bg-secondary self-center mb-[21.5px]"
    (click)="refresh()"
  >
    <div
      [class.bg-sunny-yellow]="newSelectionSinceLastRefresh && !disableRefresh"
      [class.bg-disabled-overlay]="disableRefresh"
      class="rounded-full flex justify-center align-middle w-9 h-9"
    >
      <mat-icon
        class="rounded-full m-0 flex justify-self-center self-center text-grey-800"
        >refresh</mat-icon
      >
    </div>
  </button>
</div>
