/*eslint-disable*/

/**
 * MES info
 * This API manages MES information.
 *
 * OpenAPI spec version: 0.4.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http'
import { Inject, Injectable, Optional } from '@angular/core'
import { CustomHttpUrlEncodingCodec } from '../encoder'

import { Observable } from 'rxjs'

import { Order } from '../model/order'
import { Workcenter } from '../model/workcenter'

import { Configuration } from '../configuration'
import { BASE_PATH_CONNETIVITY_LEGACY } from '../variables'

@Injectable()
export class MESInfoService {
  protected basePath = 'https://{customIP}:{port}/'
  defaultHeaders = new HttpHeaders()
  configuration = new Configuration()

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH_CONNETIVITY_LEGACY) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath
    }
    if (configuration) {
      this.configuration = configuration
      this.basePath = basePath || configuration.basePath || this.basePath
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data'
    for (const consume of consumes) {
      if (form === consume) {
        return true
      }
    }
    return false
  }

  /**
   * Get all orders
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  mesOrderGet(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<Workcenter>>
  mesOrderGet(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Workcenter>>>
  mesOrderGet(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Workcenter>>>
  mesOrderGet(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders

    // To determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json']
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    // To determine the Content-Type header
    const consumes: string[] = []

    return this.httpClient.request<Array<Workcenter>>(
      'get',
      `${this.basePath}/mes/order`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }

  /**
   * Get all orders for a specific workcenter in a given time range.
   *
   * @param wc Workcenter id to get orders from
   * @param from Start of time range of values
   * @param to End of time range of values
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  mesOrderWcGet(
    wc: string,
    from: string,
    to: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<Order>>
  mesOrderWcGet(
    wc: string,
    from: string,
    to: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Order>>>
  mesOrderWcGet(
    wc: string,
    from: string,
    to: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Order>>>
  mesOrderWcGet(
    wc: string,
    from: string,
    to: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (wc === null || wc === undefined) {
      throw new Error(
        'Required parameter wc was null or undefined when calling mesOrderWcGet.'
      )
    }

    if (from === null || from === undefined) {
      throw new Error(
        'Required parameter from was null or undefined when calling mesOrderWcGet.'
      )
    }

    if (to === null || to === undefined) {
      throw new Error(
        'Required parameter to was null or undefined when calling mesOrderWcGet.'
      )
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    })
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set('from', <any>from)
    }
    if (to !== undefined && to !== null) {
      queryParameters = queryParameters.set('to', <any>to)
    }

    let headers = this.defaultHeaders

    // To determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json']
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    // To determine the Content-Type header
    const consumes: string[] = []

    return this.httpClient.request<Array<Order>>(
      'get',
      `${this.basePath}/mes/order/${encodeURIComponent(String(wc))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }
}
/* eslint-enable*/
