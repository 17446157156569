/**
 * Category of a machine
 */
export enum ErrorCategory {
  AllowanceMonitor,
}

/**
 * EquipmentState represents the state of a equipment during a certain time point.
 */
export interface EquipmentStateDTO {
  // Indicating whether all values are ok
  ok: boolean
  // Indicating current allowance inside reference interval
  currentAllowanceOK: boolean
  // Indicating allowance position is reference value
  allowancePositionOK: boolean
  // Indicating Processing cycle time <= reference grinding time
  grindingTimeOK: boolean
  // Category of the machine in which deviation occured
  category: ErrorCategory
  // Time of the deviation occurence in UTC format
  timestamp: string
}

/**
 * MachineDeviations representing the deviations of one machine
 */
export interface EquipmentStatesDTO {
  // The unique equipment number of a machine
  equipmentNumber: number
  /**
   * The deviations occured withing this machine
   * Sorted from new to old, index 0 = newest, last index = oldest
   */
  states: EquipmentStateDTO[]
}
