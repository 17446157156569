import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { BehaviorSubject, Subscription } from 'rxjs'
import { addAutocompletion } from './autocompletion'
import { inputValidatorFactory } from './validator-factory'
import * as _ from 'underscore'
import { Machine, MachinesService } from '@dms-frontend/tds/services/api'
import { MachineSelectionService } from '@dms-frontend/shared/services/machine-selection'
import { RolesService } from '@dms-frontend/shared/services/azure-auth'
@Component({
  selector: 'dms-frontend-machine-selection',
  templateUrl: './machine-selection.component.html',
  styleUrls: ['./machine-selection.component.scss'],
})
export class MachineSelectionComponent implements OnInit, OnDestroy {
  // Plant
  plantControl = new FormControl()
  plantFilterOptions$ = new BehaviorSubject<string[]>([])

  // Segment
  segmentControl = new FormControl()
  _segmentDisabled = false
  get segmentDisabled(): boolean {
    return this._segmentDisabled
  }
  set segmentDisabled(disabled: boolean) {
    if (!disabled) {
      this.segmentControl.enable()
    }
    if (disabled) {
      this.segmentControl.disable()
      this.lineDisabled = true
    }
    this._segmentDisabled = disabled
  }
  segmentFilterOptions$ = new BehaviorSubject<string[]>([])

  // Line
  lineControl = new FormControl()
  _lineDisabled = false
  get lineDisabled(): boolean {
    return this._lineDisabled
  }
  set lineDisabled(disabled: boolean) {
    if (!disabled) {
      this.lineControl.enable()
    }
    if (disabled) {
      this.lineControl.disable()
    }
  }
  lineFilterOptions$ = new BehaviorSubject<string[]>([])

  // Equipment
  equipmentControl = new FormControl()
  equipmentDisabled = false
  equipmentFilterOptions$ = new BehaviorSubject<string[]>([])

  private subscriptions = new Subscription()

  constructor(
    private readonly machinesApi: MachinesService,
    private readonly machineSelection: MachineSelectionService,
    private readonly rolesService: RolesService
  ) {}

  ngOnInit(): void {
    this.segmentDisabled = true
    this.lineDisabled = true
    // Use machines to set autocomplete options | Not updating all machines!
    this.refreshMachines()

    // Update selected plant, segment, line and equipment number on service selections
    // With latest selected values
    const selectedPlantSubscription =
      this.machineSelection.selectedPlant$.subscribe((plant) => {
        this.plantControl.setValue(plant)
        this.segmentDisabled = plant == null || plant === ''
      })
    this.subscriptions.add(selectedPlantSubscription)

    const selectedSegmentSubscription =
      this.machineSelection.selectedSegment$.subscribe((segment) => {
        const segmentStr = segment == null ? null : segment.toString()
        this.segmentControl.setValue(segmentStr)
        this.lineDisabled = segmentStr == null || segmentStr === ''
      })
    this.subscriptions.add(selectedSegmentSubscription)

    const selectedLineSubscription =
      this.machineSelection.selectedLine$.subscribe((line) => {
        const lineStr = line == null ? null : line.toString()
        this.lineControl.setValue(lineStr)
      })
    this.subscriptions.add(selectedLineSubscription)

    const selectedEquipmentSubscription =
      this.machineSelection.selectedEquipment$.subscribe((equipment) => {
        const eqnrStr = equipment == null ? null : equipment.toString()
        this.equipmentControl.setValue(eqnrStr)
      })
    this.subscriptions.add(selectedEquipmentSubscription)

    this.addValidation()
    this.addAutocompletion()
  }

  refreshMachines(): void {
    this.machineSelection.refreshMachines().subscribe((machines) => {
      const selectablePlants = _.uniq(
        machines.map((machine) => machine.plantCode)
      )
      const selectableSegments = _.uniq(
        machines.map((machine) => machine.segment.toString())
      )
      const selectableLines = _.uniq(
        machines.map((machine) => machine.line.toString())
      )
      const selectableEquipments = _.uniq(
        machines.map((machine) => machine.eqnr.toString())
      )

      this.plantFilterOptions$.next(selectablePlants)
      this.segmentFilterOptions$.next(selectableSegments)
      this.lineFilterOptions$.next(selectableLines)
      this.equipmentFilterOptions$.next(selectableEquipments)
    })
  }

  addValidation(): void {
    const plantCheck = {
      getParam: (machine: Machine): string => machine.plantCode,
      control: this.plantControl,
    }
    const segmentCheck = {
      getParam: (machine: Machine): string => machine.segment.toString(),
      control: this.segmentControl,
    }
    const lineCheck = {
      getParam: (machine: Machine): string => machine.line.toString(),
      control: this.lineControl,
    }

    const validatePlantInput = inputValidatorFactory(
      (machine: Machine) => machine.plantCode,
      () => this.machineSelection.allMachines
    )
    const validateSegmentInput = inputValidatorFactory(
      (machine: Machine) => machine.segment.toString(),
      () => this.machineSelection.allMachines,
      [plantCheck]
    )
    const validateLineInput = inputValidatorFactory(
      (machine: Machine) => machine.line.toString(),
      () => this.machineSelection.allMachines,
      [plantCheck, segmentCheck]
    )
    const validateEquipmentInput = inputValidatorFactory(
      (machine: Machine) => machine.eqnr.toString(),
      () => this.machineSelection.allMachines,
      [plantCheck, segmentCheck, lineCheck]
    )

    this.plantControl.addValidators(validatePlantInput)
    this.segmentControl.addValidators(validateSegmentInput)
    this.lineControl.addValidators(validateLineInput)
    this.equipmentControl.addValidators(validateEquipmentInput)
  }

  addAutocompletion(): void {
    const plantFilter = {
      machineParameter: (machine: Machine): string => machine.plantCode,
      value: (): string | null | undefined =>
        this.machineSelection.selectedPlant,
    }
    const segmentFilter = {
      machineParameter: (machine: Machine): string =>
        machine.segment.toString(),
      value: (): string | null | undefined =>
        this.machineSelection.selectedSegment?.toString(),
    }
    const lineFilter = {
      machineParameter: (machine: Machine): string => machine.line.toString(),
      value: (): string | null | undefined =>
        this.machineSelection.selectedLine?.toString(),
    }
    const getAllMachines = (): Machine[] => this.machineSelection.allMachines

    // Add autocompletion to each FormControl
    addAutocompletion(
      this.plantControl,
      this.plantFilterOptions$,
      (machine: Machine) => machine.plantCode,
      getAllMachines,
      []
    )
    addAutocompletion(
      this.segmentControl,
      this.segmentFilterOptions$,
      (machine: Machine) => machine.segment.toString(),
      getAllMachines,
      [plantFilter]
    )
    addAutocompletion(
      this.lineControl,
      this.lineFilterOptions$,
      (machine: Machine) => machine.line.toString(),
      getAllMachines,
      [plantFilter, segmentFilter]
    )
    addAutocompletion(
      this.equipmentControl,
      this.equipmentFilterOptions$,
      (machine: Machine) => machine.eqnr.toString(),
      getAllMachines,
      [plantFilter, segmentFilter, lineFilter]
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  plantOptionsClosed(): void {
    if (!this.plantControl.valid) {
      return
    }
    const selectedPlant =
      this.plantControl.value === '' ? null : this.plantControl.value
    this.machineSelection.selectPlant(selectedPlant)

    if (selectedPlant == null) {
      this.machineSelection.selectSegment(null)
      this.machineSelection.selectLine(null)
      this.machineSelection.selectEquipment(null)
    }

    const selectedMachines = this.machineSelection.allMachines.filter(
      (machine) => machine.plantCode === selectedPlant || selectedPlant == null
    )
    this.equipmentFilterOptions$.next(
      selectedMachines.map((machine) => machine.eqnr.toString())
    )
  }

  segmentOptionsClosed(): void {
    if (!this.segmentControl.valid) {
      return
    }
    const selectedSegment: string | null =
      this.segmentControl.value === '' ? null : this.segmentControl.value
    this.machineSelection.selectSegment(selectedSegment)

    if (selectedSegment == null) {
      this.machineSelection.selectLine(null)
      this.machineSelection.selectEquipment(null)
    }

    const selectedMachines = this.machineSelection.allMachines
      .filter((machine) => machine.plantCode === this.plantControl.value)
      .filter(
        (machine) =>
          machine.segment === selectedSegment || selectedSegment == null
      )
    this.equipmentFilterOptions$.next(
      selectedMachines.map((machine) => machine.eqnr.toString())
    )
  }

  lineOptionsClosed(): void {
    if (!this.lineControl.valid) {
      return
    }
    const selectedLine: string | null =
      this.lineControl.value === '' ? null : this.lineControl.value
    this.machineSelection.selectLine(selectedLine)

    if (selectedLine == null) {
      this.machineSelection.selectEquipment(null)
    }

    const selectedMachines = this.machineSelection.allMachines
      .filter((machine) => machine.plantCode === this.plantControl.value)
      .filter(
        (machine) => machine.segment.toString() === this.segmentControl.value
      )
      .filter(
        (machine) => machine.line === selectedLine || selectedLine == null
      )
    this.equipmentFilterOptions$.next(
      selectedMachines.map((machine) => machine.eqnr.toString())
    )
  }

  equipmentOptionsClosed(): void {
    if (!this.equipmentControl.valid) {
      return
    }

    const selectedEquipment =
      this.equipmentControl.value === '' ? null : this.equipmentControl.value
    if (selectedEquipment != null) {
      const selectedMachine = this.machineSelection.allMachines.filter(
        (machine) => machine.eqnr.toString() === selectedEquipment
      )[0]
      this.machineSelection.selectPlant(selectedMachine.plantCode)
      this.machineSelection.selectSegment(selectedMachine.segment)
      this.machineSelection.selectLine(selectedMachine.line)
    }

    this.machineSelection.selectEquipment(selectedEquipment)
  }
}
