/**
 * Reference Values
 * This API manages reference values stored in TDS
 *
 * OpenAPI spec version: 0.2.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core'
import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http'

import { Observable } from 'rxjs'

import { ReferenceValues } from '../model/referenceValues'

import { BASE_PATH_TDS } from '../variables'
import { Configuration } from '../configuration'
import { CustomHttpUrlEncodingCodec } from '../encoder'

@Injectable()
export class ReferenceValuesService {
  protected basePath = 'https://{customIP}:{port}/'
  defaultHeaders = new HttpHeaders()
  configuration = new Configuration()

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH_TDS) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath
    }
    if (configuration) {
      this.configuration = configuration
      this.basePath = basePath || configuration.basePath || this.basePath
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data'
    for (const consume of consumes) {
      if (form === consume) {
        return true
      }
    }
    return false
  }

  /**
   * Get all reference values for an equipment and materialnumber
   *
   * @param eq Equipmentnumber to get reference values from
   * @param materialnumber Equipmentnumber to get reference values from
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  referencevaluesEqMaterialnumberGet(
    eq: string,
    materialnumber: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ReferenceValues>
  referencevaluesEqMaterialnumberGet(
    eq: string,
    materialnumber: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ReferenceValues>>
  referencevaluesEqMaterialnumberGet(
    eq: string,
    materialnumber: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ReferenceValues>>
  referencevaluesEqMaterialnumberGet(
    eq: string,
    materialnumber: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (eq == null || eq === undefined) {
      throw new Error(
        'Required parameter eq was null or undefined when calling referencevaluesEqMaterialnumberGet.'
      )
    }

    if (materialnumber == null || materialnumber === undefined) {
      throw new Error(
        'Required parameter materialnumber was null or undefined when calling referencevaluesEqMaterialnumberGet.'
      )
    }

    let headers = this.defaultHeaders

    // To determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json']
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    // To determine the Content-Type header
    const consumes: string[] = []

    return this.httpClient.request<ReferenceValues>(
      'get',
      `${this.basePath}/referencevalues/${encodeURIComponent(
        String(eq)
      )}/${encodeURIComponent(String(materialnumber))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }

  /**
   * Get all reference values for one ore multiple equipment(s)
   *
   * @param eq Equipmentnumber(s) to get reference values from.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  referencevaluesGet(
    eq?: Array<string>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<ReferenceValues>>
  referencevaluesGet(
    eq?: Array<string>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<ReferenceValues>>>
  referencevaluesGet(
    eq?: Array<string>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<ReferenceValues>>>
  referencevaluesGet(
    eq?: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    })
    if (eq) {
      const eqJsonString = JSON.stringify(eq)
      queryParameters = queryParameters.append('eq', eqJsonString)
    }

    let headers = this.defaultHeaders

    // To determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json']
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    // To determine the Content-Type header
    const consumes: string[] = []

    return this.httpClient.request<Array<ReferenceValues>>(
      'get',
      `${this.basePath}/referencevalues`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }

  /**
   * Add new reference values
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  referencevaluesPost(
    body: ReferenceValues,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>
  referencevaluesPost(
    body: ReferenceValues,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>
  referencevaluesPost(
    body: ReferenceValues,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>
  referencevaluesPost(
    body: ReferenceValues,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body == null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling referencevaluesPost.'
      )
    }

    let headers = this.defaultHeaders

    // To determine the Accept header
    const httpHeaderAccepts: string[] = []
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    // To determine the Content-Type header
    const consumes: string[] = ['application/json']
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected)
    }

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/referencevalues`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }

  /**
   * Update reference Values
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  referencevaluesPut(
    body: ReferenceValues,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>
  referencevaluesPut(
    body: ReferenceValues,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>
  referencevaluesPut(
    body: ReferenceValues,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>
  referencevaluesPut(
    body: ReferenceValues,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body == null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling referencevaluesPut.'
      )
    }

    let headers = this.defaultHeaders

    // To determine the Accept header
    const httpHeaderAccepts: string[] = []
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    // To determine the Content-Type header
    const consumes: string[] = ['application/json']
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected)
    }

    return this.httpClient.request<any>(
      'put',
      `${this.basePath}/referencevalues`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }
}
