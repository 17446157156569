import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FloatNumberDirective } from './float-number.directive'
import { AutofocusDirective } from './autofocus.directive'

@NgModule({
  imports: [CommonModule],
  declarations: [FloatNumberDirective, AutofocusDirective],
  exports: [FloatNumberDirective, AutofocusDirective]
})
export class SharedDirectivesModule { }
