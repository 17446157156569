import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  readonly realTime$ = new BehaviorSubject<boolean>(false)
  readonly refresh$ = new Subject<boolean>()

  readonly timingDiagramsLoading$: BehaviorSubject<boolean>[] = []

  readonly allowanceDiagramLoading$ = new BehaviorSubject<boolean>(false)
  readonly timingDiagramLoading$ = new BehaviorSubject<boolean>(false)
  readonly histogramLoading$ = new BehaviorSubject<boolean>(false)
  readonly subsystemLoading$ = new BehaviorSubject<boolean>(false)

  readonly cycleTimeCurrentLoading$ = new BehaviorSubject<boolean>(false)
  readonly cycleTimeIntervalLoading = new BehaviorSubject<boolean>(false)
  readonly cycleTimeOrderLoading$ = new BehaviorSubject<boolean>(false)

  readonly anyCycleTimeFeatureDiagramLoading$ = new BehaviorSubject<boolean>(
    false
  )

  readonly dressingOverviewLoading$ = new BehaviorSubject<boolean>(false)
  readonly dressingTimingLoading$ = new BehaviorSubject<boolean>(false)

  readonly disableRefresh$ = new BehaviorSubject<boolean>(false)

  private allowanceDiagramLoading = false
  private cycleTimeDiagramLoading = false

  setRealTime(realTime: boolean): void {
    this.realTime$.next(realTime)
  }

  getRealTime(): boolean {
    return this.realTime$.getValue()
  }

  refresh(): void {
    this.refresh$.next(true)
  }

  setAllowanceDiagramLoading(loading: boolean): void {
    this.allowanceDiagramLoading = loading
    this.allowanceDiagramLoading$.next(this.allowanceDiagramLoading)
  }

  setCycleTimeDiagramLoading(loading: boolean): void {
    this.cycleTimeDiagramLoading = loading
    this.timingDiagramLoading$.next(this.cycleTimeDiagramLoading)
  }

  setHistogramLoading(loading: boolean): void {
    this.histogramLoading$.next(loading)
  }

  setSubsystemLoading(loading: boolean): void {
    this.subsystemLoading$.next(loading)
  }

  setCycleTimeIntervalLoading(loading: boolean): void {
    this.cycleTimeIntervalLoading.next(loading)
  }

  setCycleTimeCurrentLoading(loading: boolean): void {
    this.cycleTimeCurrentLoading$.next(loading)
  }

  setCycleTimeOrderLoading(loading: boolean): void {
    this.cycleTimeOrderLoading$.next(loading)
  }

  setDressingOverviewLoading(loading: boolean): void {
    this.dressingOverviewLoading$.next(loading)
  }

  setDressingTimingLoading(loading: boolean): void {
    this.dressingTimingLoading$.next(loading)
  }
}
