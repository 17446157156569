import { Inject, Injectable, OnDestroy } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { Observable, Subject, filter, of, switchMap, takeUntil } from 'rxjs'
import { MsalBroadcastService, MsalService } from '@azure/msal-angular'
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser'
import { RolesService } from '@dms-frontend/shared/services/azure-auth'

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate, OnDestroy {
  private readonly _destroying$ = new Subject<void>()

  constructor(
    @Inject('APP_ID') private appID: string,
    private rolesService: RolesService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {}

  ngOnDestroy(): void {
    this._destroying$.next()
    this._destroying$.complete()
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const loginRequestNoPerm: RedirectRequest = {
      scopes: ['user.read'],
      prompt: 'consent',
    }

    const loginRequestFirstLogin: RedirectRequest = {
      scopes: ['user.read'],
      prompt: 'select_account',
    }

    return this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$),
      switchMap(() => {
        const accounts = this.authService.instance.getAllAccounts()
        if (accounts.length > 0) {
          if (accounts[0].idTokenClaims?.aud !== this.appID) {
            this.authService.loginRedirect()
            return of(true)
          }
          const roles = accounts[0].idTokenClaims?.roles
          this.rolesService.roles = roles == null ? [] : roles

          if (roles == null) {
            this.authService.loginRedirect(loginRequestNoPerm)
            return of(false)
          }
          return of(roles.length > 0)
        }
        this.authService.loginRedirect(loginRequestFirstLogin)
        return of(true)
      })
    )
  }
}
