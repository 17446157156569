import { Component, Inject, OnInit } from '@angular/core'
import 'chartjs-adapter-date-fns'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {
  AllowanceMonitorService,
  CycleReferencedProcessValuesService,
  MESInfoService,
  ReferenceValuesService,
  Result,
} from '@dms-frontend/tds/services/api'
import { BehaviorSubject, Subject, combineLatest, skip, take } from 'rxjs'
import { TimeSelectionService } from '@dms-frontend/shared/services/time-selection'
import { MachineSelectionService } from '@dms-frontend/shared/services/machine-selection'
import { setChartOptions } from './chart-options'
import { setChartData } from './chart-data'
import { TSValue, X_AXIS_OFFSET_PERCENTAGE } from '../config/config'
import { retrieveReferenceCycleTimeGapGrinding } from './data-retriever/reference-values-retriever'
import {
  retrieveCycleTimeGapGrinding,
  retrieveKPIs,
} from './data-retriever/cycle-time-gap-grinding-data-retriever'
import { DashboardService } from '@dms-frontend/tds/services/dashboard'
import {
  Label,
  MaterialTextPluginService,
} from '@dms-frontend/tds/utils/chart-plugins'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { MesChartComponent, retrieveOrders } from '@dms-frontend/tds/ui/charts'
import { HttpErrorResponse } from '@angular/common/http'
import { MatSnackBar } from '@angular/material/snack-bar'

@UntilDestroy()
@Component({
  selector: 'dms-frontend-cycle-time-gap-grinding-diagram',
  templateUrl: './cycle-time-gap-grinding-diagram.component.html',
  styleUrls: ['./cycle-time-gap-grinding-diagram.component.scss'],
})
export class CycleTimeGapGrindingDiagramComponent
  extends MesChartComponent
  implements OnInit
{
  /** =======================================================
   *                     CHART DATA
   *  =======================================================
   */
  _averageCycleTimeGapGrinding: TSValue[] = []
  get averageCycleTimeGapGrinding(): TSValue[] {
    return this._averageCycleTimeGapGrinding
  }
  set averageCycleTimeGapGrinding(averages: TSValue[]) {
    this._averageCycleTimeGapGrinding = averages
    setChartData(this)
  }

  _upperLimitCycleTimeGapGrinding: TSValue[] = []
  get upperLimitCycleTimeGapGrinding(): TSValue[] {
    return this._upperLimitCycleTimeGapGrinding
  }
  set upperLimitCycleTimeGapGrinding(limits: TSValue[]) {
    this._upperLimitCycleTimeGapGrinding = limits
    this.calculateOutliers()
    setChartData(this)
  }

  _deltaTWithSuggestionAllowancePosition: Result[] = []
  get deltaTWithSuggestionAllowancePosition(): Result[] {
    return this._deltaTWithSuggestionAllowancePosition
  }
  set deltaTWithSuggestionAllowancePosition(deltaT: Result[]) {
    this._deltaTWithSuggestionAllowancePosition = deltaT
    setChartData(this)
  }

  _outliers: TSValue[] = []
  get outliers(): TSValue[] {
    return this._outliers
  }
  set outliers(outliers: TSValue[]) {
    this._outliers = outliers
    setChartData(this)
  }

  _cycleTimeGapGrinding: TSValue[] = []
  get cycleTimeGapGrinding(): TSValue[] {
    return this._cycleTimeGapGrinding
  }
  set cycleTimeGapGrinding(cycleTimeGapGrinding: TSValue[]) {
    this._cycleTimeGapGrinding = cycleTimeGapGrinding
    this.calculateOutliers()
    setChartData(this)
  }

  loading$ = new BehaviorSubject<boolean>(false)

  constructor(
    public allowanceKpi: AllowanceMonitorService,
    public cycleReferencedValues: CycleReferencedProcessValuesService,
    public timeSelection: TimeSelectionService,
    public referenceValues: ReferenceValuesService,
    public materialTextPluginService: MaterialTextPluginService,
    public override snackBar: MatSnackBar,
    @Inject(DashboardService) dashboardService: DashboardService,
    @Inject(MachineSelectionService) machineSelection: MachineSelectionService,
    @Inject(MESInfoService) mesInfoService: MESInfoService
  ) {
    super(dashboardService, machineSelection, mesInfoService, snackBar)
    this.loading$.pipe(untilDestroyed(this)).subscribe((loading) => {
      this.dashboardService.setCycleTimeDiagramLoading(loading)
    })
  }

  ngOnInit(): void {
    // Workaround because of materialTextPluginService popping
    this.chartPlugins.push(ChartDataLabels)
    this.chartPlugins.push(ChartDataLabels)

    const from$ = this.timeSelection.from$
    const to$ = this.timeSelection.to$
    const eq$ = this.machineSelection.selectedEquipment$
    const workCenterID$ = this.machineSelection.selectedWorkCenterID$

    // Get first eq, from, to and workCenterID and refresh diagram
    combineLatest([eq$, from$, to$, workCenterID$])
      .pipe(take(1))
      .pipe(untilDestroyed(this))
      .subscribe(([eq, from, to, workCenterID]) => {
        if (this.minDate.valueOf() !== from.valueOf()) {
          this.minDate = from
        }
        if (this.maxDate.valueOf() !== to.valueOf()) {
          this.maxDate = to
        }
        if (this.eq !== eq) {
          this.eq = eq
        }
        if (this.workCenterID !== workCenterID) {
          this.workCenterID = workCenterID
        }
        this.refresh()
      })
    // Get all further eq, from, to and workCenterID for further diagram refreshes
    combineLatest([eq$, from$, to$, workCenterID$])
      .pipe(skip(1))
      .pipe(untilDestroyed(this))
      .subscribe(([eq, from, to, workCenterID]) => {
        if (this.minDate.valueOf() !== from.valueOf()) {
          this.minDate = from
        }
        if (this.maxDate.valueOf() !== to.valueOf()) {
          this.maxDate = to
        }
        if (this.eq !== eq) {
          this.eq = eq
        }
        if (this.workCenterID !== workCenterID) {
          this.workCenterID = workCenterID
        }
      })

    this.dashboardService.refresh$
      .pipe(untilDestroyed(this))
      .subscribe((refresh) => {
        this.refresh()
      })
  }

  /**
   * Retrieves data from backend and updates chart with new data
   */
  refresh(): void {
    setChartOptions(this)
    if (this.eq != null && this.workCenterID != null) {
      this.retrieveDataFromBackend(
        this.minDate,
        this.maxDate,
        this.eq,
        this.workCenterID
      )
    }
  }

  calculateOutliers(): void {
    // Calculate outliers
    const outliers = []
    for (const timeGapGrinding of this.cycleTimeGapGrinding) {
      // Find active upper limit for the time of timeGapGrinding
      let activeUpperLimit = null
      for (const upperLimit of this.upperLimitCycleTimeGapGrinding) {
        if (upperLimit.ts.valueOf() <= timeGapGrinding.ts.valueOf()) {
          activeUpperLimit = upperLimit
        } else {
          break
        }
      }

      // No upper limit found suitable for this timeGapGrinding
      if (activeUpperLimit == null) {
        break
      }

      if (timeGapGrinding.value > activeUpperLimit.value) {
        outliers.push(timeGapGrinding)
      }
    }
    this.outliers = outliers
  }

  // Retrieves data from backend and updates diagram
  retrieveDataFromBackend(
    from: Date,
    to: Date,
    eq: number,
    workCenterID: number
  ): void {
    this.loading$.next(true)
    this.resetAll()
    const cycleTimeRetrieved$ = retrieveCycleTimeGapGrinding(this, from, to, eq)
    const orders$ = retrieveOrders(this, from, to, workCenterID)
    const orderDependentDataRetrieved$ = new Subject<boolean>()

    orders$.pipe(untilDestroyed(this)).subscribe(
      (orders) => {
        if (orders == null) {
          orders = []
        }

        const canvas = <HTMLCanvasElement>(
          document.getElementById('cycle-time-chart')
        )
        const tooltip = <HTMLCanvasElement>(
          document.getElementById('cycle-time-tip')
        )
        const onLabelClick = (label: Label): void => {
          throw new Error('Not implemented yet')
        }
        const matTxtPlugin = this.materialTextPluginService.buildMatTxtPlugin(
          this.minDate,
          this.maxDate,
          orders,
          canvas,
          tooltip,
          X_AXIS_OFFSET_PERCENTAGE,
          onLabelClick
        )
        this.chartPlugins.pop()
        this.chartPlugins.push(matTxtPlugin)

        if (orders.length === 0) {
          orderDependentDataRetrieved$.next(true)
          return
        }
        const referenceRetrieved$ = retrieveReferenceCycleTimeGapGrinding(
          this,
          eq,
          orders
        )
        const kpisRetrieved$ = retrieveKPIs(this, eq, orders)

        // On reference and kpis retrieved orderDependentData is retrieved
        combineLatest([referenceRetrieved$, kpisRetrieved$])
          .pipe(untilDestroyed(this))
          .subscribe(
            (getsFinished) => {
              const finishedRetrieving = getsFinished.reduce((acc, cur) => {
                return acc && cur
              })
              orderDependentDataRetrieved$.next(!finishedRetrieving)
            },
            (error) => {
              orderDependentDataRetrieved$.error(error)
            }
          )
      },
      (error) => {
        orderDependentDataRetrieved$.error(error)
      }
    )

    combineLatest([cycleTimeRetrieved$, orderDependentDataRetrieved$])
      .pipe(untilDestroyed(this))
      .subscribe(
        (getsFinished) => {
          const finishedRetrieving = getsFinished.reduce((acc, cur) => {
            return acc && cur
          })
          this.loading$.next(finishedRetrieving)
        },
        (error: HttpErrorResponse) => {     
          this.snackBar.open(`${error.status}: ${error.statusText}` , 'Close')
          this.loading$.next(false)
        }
      )
  }

  resetAll(): void {
    this.cycleTimeGapGrinding = []
    this.deltaTWithSuggestionAllowancePosition = []
    this.upperLimitCycleTimeGapGrinding = []
    this.averageCycleTimeGapGrinding = []
  }
}
