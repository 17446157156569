import { HashLocationStrategy, LocationStrategy } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { RoleGuard } from '@dms-frontend/shared/guards'
import { AllowanceMonitorComponent } from './pages/dashboard/allowance-monitor/allowance-monitor.component'
import { CycleTimeMonitorComponent } from './pages/dashboard/cycle-time-monitor/cycle-time-monitor.component'
import { OverviewComponent } from './pages/dashboard/overview/overview.component'
import { ProcessConfigComponent } from './pages/dashboard/process-config/process-config.component'
import { DressingMonitorComponent } from './pages/dressing-monitor/dressing-monitor.component'
import { HomeComponent } from './pages/home/home.component'
import { ReferenceValuesComponent } from './pages/reference-values/reference-values.component'
import { routePaths } from './routes'

const routes: Routes = [
  {
    // Needed for hash routing
    path: routePaths.code.path,
    component: HomeComponent,
  },
  {
    path: routePaths.empty.path,
    component: HomeComponent,
    canActivate: [RoleGuard],
  },
  {
    // Needed for Error routing
    path: routePaths.error.path,
    component: HomeComponent,
  },
  {
    path: routePaths.overview.path,
    component: OverviewComponent,
    canActivate: [RoleGuard],
  },
  {
    path: routePaths.allowanceMonitor.path,
    component: AllowanceMonitorComponent,
    canActivate: [RoleGuard],
  },
  {
    path: routePaths.processConfig.path,
    component: ProcessConfigComponent,
    canActivate: [RoleGuard],
  },
  {
    path: routePaths.cycleTimeMonitor.path,
    component: CycleTimeMonitorComponent,
    canActivate: [RoleGuard],
  },
  {
    path: routePaths.dressingMonitor.path,
    component: DressingMonitorComponent,
    canActivate: [RoleGuard],
  },
  {
    path: routePaths.referenceValues.path,
    component: ReferenceValuesComponent,
    canActivate: [RoleGuard],
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
})
export class AppRoutingModule {}
