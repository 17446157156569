import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { MachineDTO } from './dtos'

@Injectable({
  providedIn: 'root',
})
export class ApiMachinesService {
  private _allMachines: MachineDTO[] = []
  get allMachines(): MachineDTO[] {
    return this._allMachines
  }
  set allMachines(allMachines: MachineDTO[]) {
    this._allMachines = allMachines
    this.allMachines$.next(allMachines)
  }

  /**
   * All machines initialised with []
   * Will be filled with allMachines from backend request
   */
  readonly allMachines$ = new BehaviorSubject<MachineDTO[]>([])

  constructor(
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    @Inject('API_MACHINES_PATH') private readonly machinesPath: string,
    private http: HttpClient
  ) {
    // Request all machines from backend
    this.requestAllMachines()
  }

  /**
   * Request all machines from backend.
   * this.allMachines$ will emit requested machines
   */
  requestAllMachines(): void {
    const url = this.baseUrl + '/' + this.machinesPath
    this.http.get<MachineDTO[]>(url).subscribe((machines) => {
      this.allMachines = machines
    })
  }
}
