<div class="flex flex-row">
  <!--    Plant selection -->
  <form class="inline">
    <mat-form-field class="w-24 mr-2" appearance="fill">
      <mat-label>Plant</mat-label>
      <input
        type="text"
        placeholder="Pick one"
        aria-label="Number"
        matInput
        [formControl]="plantControl"
        [matAutocomplete]="plantsAutoComplete"
      />
      <mat-autocomplete
        (closed)="plantOptionsClosed()"
        #plantsAutoComplete="matAutocomplete"
      >
        <mat-option
          *ngFor="let option of plantFilterOptions$ | async"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
  <!--    Segment selection -->
  <form class="inline">
    <mat-form-field class="w-24 mr-2" appearance="fill">
      <mat-label>Segment</mat-label>
      <input
        size="5"
        type="text"
        placeholder="Pick one"
        aria-label="Number"
        matInput
        [formControl]="segmentControl"
        [matAutocomplete]="segmentsAutoComplete"
      />
      <mat-autocomplete
        (closed)="segmentOptionsClosed()"
        #segmentsAutoComplete="matAutocomplete"
      >
        <mat-option
          *ngFor="let option of segmentFilterOptions$ | async"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
  <!--    Line selection -->
  <form class="inline">
    <mat-form-field class="w-24 mr-2" appearance="fill">
      <mat-label>Line</mat-label>
      <input
        size="5"
        type="text"
        placeholder="Pick one"
        aria-label="Number"
        matInput
        [formControl]="lineControl"
        [matAutocomplete]="linesAutoComplete"
      />
      <mat-autocomplete
        (closed)="lineOptionsClosed()"
        #linesAutoComplete="matAutocomplete"
      >
        <mat-option
          *ngFor="let option of lineFilterOptions$ | async"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
  <!--    Equipment selection -->
  <form class="inline">
    <mat-form-field class="w-32" appearance="fill">
      <mat-label>Equipment</mat-label>
      <input
        size="5"
        type="text"
        placeholder="Pick one"
        aria-label="Number"
        matInput
        [formControl]="equipmentControl"
        [matAutocomplete]="equipmentsAutoComplete"
      />
      <mat-autocomplete
        (closed)="equipmentOptionsClosed()"
        #equipmentsAutoComplete="matAutocomplete"
      >
        <mat-option
          *ngFor="let option of equipmentFilterOptions$ | async"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</div>
