import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { DressingMonitorIntervalKPIs } from '@dms-frontend/tds/services/api'
import { DashboardService } from '@dms-frontend/tds/services/dashboard'
import { BehaviorSubject } from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'dms-frontend-dressing-monitor',
  templateUrl: './dressing-monitor.component.html',
  styleUrls: ['./dressing-monitor.component.scss'],
})
export class DressingMonitorComponent implements OnInit {
  loading$ = new BehaviorSubject<boolean>(false)
  dressingMonitorIntervalKPIs: DressingMonitorIntervalKPIs | null = null

  showOverview = false
  showTiming = false

  anyDiagramRefreshable = false

  dressingTimingLoading = false
  dressingOverviewLoading = false

  eq: number | null = null
  from: Date | null = null
  to: Date | null = null

  constructor(
    private dashboardService: DashboardService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.dashboardService.dressingTimingLoading$
      .pipe(untilDestroyed(this))
      .subscribe((dressingTimingLoading) => {
        this.dressingTimingLoading = dressingTimingLoading
        // Trigger change detection as value is changed by child component and therefore
        // Triggered after change detection for this component has been executed
        this.cd.detectChanges()
      })
    this.dashboardService.dressingOverviewLoading$
      .pipe(untilDestroyed(this))
      .subscribe((dressingOverviewLoading) => {
        this.dressingOverviewLoading = dressingOverviewLoading
        // Trigger change detection as value is changed by child component and therefore
        // Triggered after change detection for this component has been executed
        this.cd.detectChanges()
      })
  }

  showToolConsumptionOverview(): void {
    this.showOverview = !this.showOverview
    this.anyDiagramLoadable()
  }

  loadTimingDiagrams(): void {
    this.showTiming = !this.showTiming
    this.anyDiagramLoadable()
  }

  anyDiagramLoadable(): void {
    this.anyDiagramRefreshable = this.showTiming || this.showOverview
  }
}
