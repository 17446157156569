import { CommonModule } from '@angular/common'
import { Component, OnDestroy, OnInit, Output } from '@angular/core'
import { ChartData, ChartOptions, Plugin } from 'chart.js'
import 'chartjs-adapter-date-fns'

import { KpiService, MESInfoService } from '@dms-frontend/tds/services/api'
import {
  BehaviorSubject,
  Subscription,
  combineLatest,
  combineLatestWith,
  skip,
  take,
} from 'rxjs'
import { setChartOptions } from './chart-options'
import { setChartData } from './chart-data'
import { MachineSelectionService } from '@dms-frontend/shared/services/machine-selection'
import { TimeSelectionService } from '@dms-frontend/shared/services/time-selection'
import { NgChartsModule } from 'ng2-charts'
import { SharedMaterialModule } from '@dms-frontend/shared/material'
import { SharedDirectivesModule } from '@dms-frontend/shared/directives'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { retrieveState } from './data-retriever/state-data-retriever'
import { DashboardService } from '@dms-frontend/tds/services/dashboard'
import { StateValue } from './config/config'
import { retrieveOrders } from './data-retriever/mes-data-retriever'
import {
  Label,
  MaterialTextPluginService,
} from '@dms-frontend/tds/utils/chart-plugins'
@Component({
  selector: 'dms-frontend-subsystem',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgChartsModule,
    ReactiveFormsModule,
    SharedMaterialModule,
    SharedDirectivesModule,
  ],
  templateUrl: './subsystem.component.html',
  styleUrls: ['./subsystem.component.scss'],
})
export class SubsystemComponent implements OnInit, OnDestroy {
  /** =======================================================
   *                    GENERAL MEMBER
   *  =======================================================
   */
  @Output() loading$ = new BehaviorSubject<boolean>(false)

  chartOptions!: ChartOptions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartData!: ChartData<any>

  chartPlugins: Plugin[] = []
  subscription = new Subscription()
  /** =======================================================
   *              MIN-MAX-DATE & EquipmentNumber
   *  =======================================================
   */
  // DeltaTSmallerThanTwoHours = false
  minDate = new Date(Date.now() - 24 * 60 * 60 * 1000)

  maxDate = new Date(Date.now())

  _eq: number | null = null
  get eq(): number | null {
    return this._eq
  }
  set eq(eq: number | null) {
    this._eq = eq
    if (eq != null) {
      const workCenterSubscription = this.machineSelection.selectedWorkCenterID$
        .pipe(take(1))
        .subscribe((workCenterID) => {
          this.workCenterID = workCenterID
        })
      this.subscription.add(workCenterSubscription)
    }
  }

  workCenterID: number | null = null

  /** =======================================================
   *                     CHART DATA
   *  =======================================================
   */
  _stateData: StateValue[] = []
  get stateData(): StateValue[] {
    return this._stateData
  }
  set stateData(value: StateValue[]) {
    this._stateData = value
    setChartData(this)
  }

  constructor(
    public kpiService: KpiService,
    private machineSelection: MachineSelectionService,
    private timeSelection: TimeSelectionService,
    private dashboardService: DashboardService,
    public mesInfoService: MESInfoService,
    private materialTextPlugin: MaterialTextPluginService
  ) {
    const loadingSubscription = this.loading$.subscribe((loading) => {
      this.dashboardService.setSubsystemLoading(loading)
    })
    this.subscription.add(loadingSubscription)

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  ngOnInit(): void {
    const from$ = this.timeSelection.from$
    const to$ = this.timeSelection.to$
    const eq$ = this.machineSelection.selectedEquipment$
    const workCenterID$ = this.machineSelection.selectedWorkCenterID$

    // Get first eq, from, to and workCenterID and refresh diagram
    const initSub = combineLatest([eq$, from$, to$, workCenterID$])
      .pipe(take(1))
      .subscribe(([eq, from, to, workCenterID]) => {
        if (this.minDate.valueOf() !== from.valueOf()) {
          this.minDate = from
        }
        if (this.maxDate.valueOf() !== to.valueOf()) {
          this.maxDate = to
        }
        if (this.eq !== eq) {
          this.eq = eq
        }
        if (this.workCenterID !== workCenterID) {
          this.workCenterID = workCenterID
        }
        this.refresh()
      })
    // Get all further eq, from, to and workCenterID for further diagram refreshes
    const updateSub = combineLatest([eq$, from$, to$, workCenterID$])
      .pipe(skip(1))
      .subscribe(([eq, from, to, workCenterID]) => {
        if (this.minDate.valueOf() !== from.valueOf()) {
          this.minDate = from
        }
        if (this.maxDate.valueOf() !== to.valueOf()) {
          this.maxDate = to
        }
        if (this.eq !== eq) {
          this.eq = eq
        }
        if (this.workCenterID !== workCenterID) {
          this.workCenterID = workCenterID
        }
      })
    this.subscription.add(initSub)
    this.subscription.add(updateSub)

    const refreshSub = this.dashboardService.refresh$.subscribe(() => {
      this.refresh()
    })
    this.subscription.add(refreshSub)

    setChartOptions(this)
    setChartData(this)
  }

  refresh(): void {
    setChartOptions(this)
    if (this.eq != null && this.workCenterID != null) {
      this.retrieveDataFromBackend(
        this.minDate,
        this.maxDate,
        this.eq,
        this.workCenterID
      )
    }
  }

  retrieveDataFromBackend(
    from: Date,
    to: Date,
    eq: number,
    workCenterID: number
  ): void {
    this.loading$.next(true)
    this.resetAll()
    const stateRetrieved$ = retrieveState(this, from, to, eq)

    const ordersRetrieved$ = retrieveOrders(this, from, to, workCenterID)
    ordersRetrieved$.subscribe((orders) => {
      if (orders == null) {
        orders = []
      }
      const canvas = <HTMLCanvasElement>(
        document.getElementById('subsystem-chart')
      )
      const tooltip = <HTMLCanvasElement>(
        document.getElementById('subsystem-tip')
      )
      const onLabelClick = (label: Label): void => {
        throw new Error('Not implemented yet')
      }
      const matTxtPlugin = this.materialTextPlugin.buildMatTxtPlugin(
        this.minDate,
        this.maxDate,
        orders,
        canvas,
        tooltip,
        0,
        onLabelClick
      )
      this.chartPlugins.pop()
      this.chartPlugins.push(matTxtPlugin)
    })

    stateRetrieved$
      .pipe(combineLatestWith(ordersRetrieved$))
      .subscribe((finished) => {
        this.loading$.next(false)
      })

    this.subscription.add(stateRetrieved$)
  }

  resetAll(): void {
    this.stateData = []
  }
}
