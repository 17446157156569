import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { MachineSelectionService } from '@dms-frontend/shared/services/machine-selection'
import {
  MachinesService,
  ReferenceValues,
  ReferenceValuesService,
} from '@dms-frontend/tds/services/api'
import { BehaviorSubject, Subscription, forkJoin, map, startWith } from 'rxjs'
import {
  ReferenceValueDummy,
  _getNewestReferenceValues,
  mapReferenceValuesArrayToMillimeter,
  mapReferenceValuesToMeter,
} from '../shared/reference-value-helper'

export interface CreateMaterialNumberDialogInput {
  // Preselected equipmentnumber. Will be shown in the dialog on init.
  equipmentNumber?: number | null
  // Preselected material number. Will be shown in the dialog on init.
  materialNumber?: number
  // Preset dialog with material text
  materialText?: string
  // Allow setting a material number, which is already used for the selected equipmentnumber
  allowExistingMaterialNumber?: boolean
}

@Component({
  selector: 'dms-frontend-create-material-number-dialog',
  templateUrl: './create-material-number-dialog.component.html',
  styleUrls: ['./create-material-number-dialog.component.scss'],
})
export class CreateMaterialNumberDialogComponent implements OnInit, OnDestroy {
  showFullDialog = false
  referenceValuesValid = true

  equipmentNumberControl = new FormControl()
  materialNumberControl = new FormControl()

  allReferenceValues: ReferenceValues[] = []
  allEquipmentNumbers: number[] = []
  filteredEquipmentNumbers = new BehaviorSubject<string[]>([])

  materialNumber?: number
  referenceValues: ReferenceValueDummy[] = []

  loading$ = new BehaviorSubject(false)

  // Subscription containing all subscription | Will be unsubscribed on destory
  subscriptions = new Subscription()

  constructor(
    public dialogRef: MatDialogRef<CreateMaterialNumberDialogComponent>,
    public machinesService: MachinesService,
    public machineSelection: MachineSelectionService,
    public referenceValuesService: ReferenceValuesService,
    @Inject(MAT_DIALOG_DATA) public data: CreateMaterialNumberDialogInput
  ) {}

  ngOnInit(): void {
    this.loading$.next(true)
    const machines$ = this.machineSelection.refreshMachines()
    const referenceValues$ = this.referenceValuesService
      .referencevaluesGet()
      .pipe(
        // Map values to millimeter
        map(mapReferenceValuesArrayToMillimeter)
      )

    const machinesAndRefValues = forkJoin([
      machines$,
      referenceValues$,
    ]).subscribe(([machines, referenceValues]) => {
      this.allEquipmentNumbers = machines.map((machine) => machine.eqnr)
      this.allReferenceValues = referenceValues
      this.referenceValues = _getNewestReferenceValues(
        referenceValues,
        this.data.equipmentNumber,
        this.data.materialNumber
      )
      this._addAutocompletion()
      this._addValidators()
      this.loading$.next(false)
    })
    this.subscriptions.add(machinesAndRefValues)

    this.materialNumber = this.data.materialNumber

    this.materialNumberControl.valueChanges.subscribe((materialNumber) => {
      this.materialNumber = materialNumber
    })

    // Preset equipment number and material number
    this.equipmentNumberControl.setValue(this.data.equipmentNumber)
    this.materialNumberControl.setValue(this.data.materialNumber)

    // Show full dialog on equipmentNumber and materialNumber preset
    if (this.data.equipmentNumber != null && this.data.materialNumber != null) {
      this.showFullDialog = true
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  _addAutocompletion(): void {
    // Set autocompletion for equipment numbers
    this.filteredEquipmentNumbers.next(
      this.allEquipmentNumbers.map((el) => el.toString())
    )
    const equiptmenNumberAutocompletion =
      this.equipmentNumberControl.valueChanges
        .pipe(
          startWith(''),
          map((value) => this._filter(value?.toString()))
        )
        .subscribe(this.filteredEquipmentNumbers)
    this.subscriptions.add(equiptmenNumberAutocompletion)
  }

  next(): void {
    if (
      this.equipmentNumberControl.value !== '' &&
      this.equipmentNumberControl.value != null &&
      this.materialNumberControl.value !== '' &&
      this.materialNumberControl.value != null &&
      this.equipmentNumberControl.valid &&
      this.materialNumberControl.valid
    ) {
      this.showFullDialog = true
      const machines$ = this.machineSelection.refreshMachines()
      const referenceValues$ = this.referenceValuesService
        .referencevaluesGet()
        .pipe(
          // Map values to millimeter
          map(mapReferenceValuesArrayToMillimeter)
        )

      const machinesAndRefValues = forkJoin([
        machines$,
        referenceValues$,
      ]).subscribe(([machines, referenceValues]) => {
        this.allEquipmentNumbers = machines.map((machine) => machine.eqnr)
        this.allReferenceValues = referenceValues
        this.referenceValues = _getNewestReferenceValues(
          referenceValues,
          parseInt(this.equipmentNumberControl.value, 10),
          parseInt(this.materialNumberControl.value, 10)
        )
        this._addAutocompletion()
        this._addValidators()
        this.loading$.next(false)
      })
      this.subscriptions.add(machinesAndRefValues)
    }
  }

  create(): void {
    if (
      this.equipmentNumberControl.value !== '' &&
      this.equipmentNumberControl.value != null &&
      this.materialNumberControl.value !== '' &&
      this.materialNumberControl.value != null &&
      this.equipmentNumberControl.valid &&
      this.materialNumberControl.valid &&
      this.referenceValuesValid
    ) {
      this.loading$.next(true)

      // If (this.equipmentNumberControl.value != null) {
      const referenceValuesForEQ: ReferenceValues = {
        equipmentNumber: parseInt(this.equipmentNumberControl.value, 10),
        values: this.referenceValues.map((refvalue) => {
          return {
            ...refvalue,
            materialNumber: parseInt(this.materialNumberControl.value, 10),
            unit: refvalue.unit as string,
            value: refvalue.value as string,
          }
        }),
      }

      const referenceValuesInSIUnits =
        mapReferenceValuesToMeter(referenceValuesForEQ)

      const referencevaluesPost = this.referenceValuesService
        .referencevaluesPost(
          referenceValuesInSIUnits,
        )
        .subscribe(() => {
          this.loading$.next(false)
          this.dialogRef.close()
        })
      this.subscriptions.add(referencevaluesPost)
    }
  }

  cancel(): void {
    this.dialogRef.close()
  }

  equipmentNumbersClosed(): void {
    if (
      this.materialNumberControl.value !== '' &&
      this.materialNumberControl.value != null
    ) {
      this.materialNumberControl.updateValueAndValidity()
    }
  }

  private _addValidators(): void {
    this.equipmentNumberControl.addValidators((control) => {
      const forbidden = !this.allEquipmentNumbers.includes(
        parseInt(control.value, 10)
      )
      return forbidden ? { forbiddenName: { value: control.value } } : null
    })

    // Check if there are already reference values for selected equipment number and material number
    this.materialNumberControl.addValidators((control) => {
      return this._materialNumberValid()
        ? null
        : { forbiddenName: { value: control.value } }
    })
  }

  private _materialNumberValid(): boolean {
    // Check whether material number does already exist for equipment number
    if (this.data != null && this.data.allowExistingMaterialNumber) {
      return true
    }
    const materialNumber = this.materialNumberControl.value
    let valid = true

    for (const refValues of this.allReferenceValues) {
      if (
        refValues.equipmentNumber !==
        parseInt(this.equipmentNumberControl.value, 10)
      ) {
        continue
      }
      for (const refValue of refValues.values) {
        if (refValue.materialNumber === parseInt(materialNumber, 10)) {
          valid = false
        }
      }
    }
    return valid
  }

  private _filter(value: string): string[] {
    if (value == null) {
      return this.allEquipmentNumbers.map((el) => el.toString())
    }
    const filterValue = value.toLowerCase()
    return this.allEquipmentNumbers
      .map((el) => el.toString())
      .filter((equipmentNumber) =>
        equipmentNumber.toLowerCase().includes(filterValue)
      )
  }
}
