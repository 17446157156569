import { X_AXIS_OFFSET_PERCENTAGE } from '../config/config'
import { CycleTimeGapGrindingDiagramComponent } from './cycle-time-gap-grinding-diagram.component'

export function setChartOptions(
  component: CycleTimeGapGrindingDiagramComponent
): void {
  const xAxisLength = component.maxDate.valueOf() - component.minDate.valueOf()
  const minXAxis = new Date(
    component.minDate.valueOf() - (X_AXIS_OFFSET_PERCENTAGE / 100) * xAxisLength
  )
  const maxXAxis = new Date(
    component.maxDate.valueOf() + (X_AXIS_OFFSET_PERCENTAGE / 100) * xAxisLength
  )
  component.chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    animation: false,
    layout: {
      padding: {
        bottom: 50, // Needed for material-txt labels below x-axis
      },
    },
    scales: {
      x: {
        type: 'time',
        min: minXAxis.toISOString(),
        max: maxXAxis.toISOString(),
        offset: false,
        time: {
          tooltipFormat: 'yyyy-MM-dd HH:mm:ss',
          displayFormats: {
            millisecond: 'HH:mm',
            second: 'HH:mm',
            minute: 'HH:mm',
            hour: 'HH:mm',
            day: 'HH:mm',
            week: 'HH:mm',
            month: 'HH:mm',
            quarter: 'HH:mm',
            year: 'HH:mm',
          },
        },
      },
      y: {
        beginAtZero: true,
        offset: true,
        title: {
          text: 'Cycle Time in [s]',
          font: {
            size: 14,
          },
          display: true,
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
        borderRadius: 4,
        color: 'green',
        font: {
          weight: 'bold',
          size: 14,
        },
        align: -35,
        offset: 7,
        borderWidth: 2,
        padding: {
          top: 2,
          left: 4,
          right: 2,
          bottom: 0,
        },
        backgroundColor: (context: any): any => {
          return context.dataset.backgroundColor
        },
        formatter: (value: any): string => {
          return value.y.toLocaleString('de-DE')
        },
      },
    },
  }
}
