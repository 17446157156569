<div class="md:pt-2">
  <dms-frontend-dashboard-header
    [refreshIsRefreshingSomething]="anyDiagramRefreshable"
    [disableRefresh]="
      (allowanceDiagramLoading && showAllowance) ||
      (timingDiagramLoading && showTiming) ||
      (histogramLoading && showHistogram)
    "
    [class.pointer-events-none]="loading$ | async"
  >
  </dms-frontend-dashboard-header>

  <mat-accordion multi>
    <mat-expansion-panel class="mr-4">
      <mat-expansion-panel-header class="text-body-1" (click)="loadAllowance()">
        <mat-panel-title> Timing Diagram Allowance </mat-panel-title>
      </mat-expansion-panel-header>
      <div>
        <dms-frontend-allowance-diagramm
          *ngIf="showAllowance"
        ></dms-frontend-allowance-diagramm>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel class="mr-4">
      <mat-expansion-panel-header class="text-body-1" (click)="loadTiming()">
        <mat-panel-title>
          Timing Diagram Cycle Time Gap Grinding
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div>
        <dms-frontend-cycle-time-gap-grinding-diagram
          *ngIf="showTiming"
        ></dms-frontend-cycle-time-gap-grinding-diagram>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel class="mr-4">
      <mat-expansion-panel-header class="text-body-1" (click)="loadHistogram()">
        <mat-panel-title> Histogram </mat-panel-title>
      </mat-expansion-panel-header>
      <div>
        <dms-frontend-histogram *ngIf="showHistogram"></dms-frontend-histogram>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <br />
  <button
    mat-raised-button
    color="primary"
    (click)="addMaterialDialog()"
    [class.pointer-events-none]="loading$ | async"
  >
    + ADD MATERIAL
  </button>
  <button
    class="ml-2"
    mat-raised-button
    color="primary"
    (click)="editMaterialDialog()"
    [class.pointer-events-none]="loading$ | async"
  >
    + EDIT MATERIAL
  </button>

  <mat-progress-spinner
    *ngIf="loading$ | async"
    class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
    [mode]="'indeterminate'"
  >
  </mat-progress-spinner>
</div>
