import { Component, Inject, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DashboardService } from '@dms-frontend/tds/services/dashboard'
import { MachineSelectionService } from '@dms-frontend/shared/services/machine-selection'
import {
  CycleReferencedProcessValuesService,
  KpiService,
  MESInfoService,
  Result,
} from '@dms-frontend/tds/services/api'
import { TimeSelectionService } from '@dms-frontend/shared/services/time-selection'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BehaviorSubject, Subject, combineLatest, skip, take } from 'rxjs'
import { setChartOptions } from './chart-options'
import { setChartData } from './chart-data'
import {
  retrieveGrindingDiameter,
  retrieveManualDressing,
} from './data-retriever/timing-retriever'
import {
  Label,
  MaterialTextPluginService,
} from '@dms-frontend/tds/utils/chart-plugins'
import { X_AXIS_OFFSET_PERCENTAGE } from '../config/config'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgChartsModule } from 'ng2-charts'
import { SharedMaterialModule } from '@dms-frontend/shared/material'
import { MesChartComponent, retrieveOrders } from '@dms-frontend/tds/ui/charts'
import { MatSnackBar } from '@angular/material/snack-bar'
import { HttpErrorResponse } from '@angular/common/http'

@UntilDestroy()
@Component({
  selector: 'dms-frontend-dressing-timing-diagram',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgChartsModule,
    ReactiveFormsModule,
    SharedMaterialModule,
  ],
  templateUrl: './dressing-timing-diagram.component.html',
  styleUrls: ['./dressing-timing-diagram.component.scss'],
})
export class DressingTimingDiagramComponent
  extends MesChartComponent
  implements OnInit
{
  _grindingWheelDiameter: Result[] = []
  get grindingWheelDiameter(): Result[] {
    return this._grindingWheelDiameter
  }
  set grindingWheelDiameter(grindingWheelDiameter: Result[]) {
    this._grindingWheelDiameter = grindingWheelDiameter
    setChartData(this)
  }

  _grindingWheelDiameterWornOut: Result[] = []
  get grindingWheelDiameterWornOut(): Result[] {
    return this._grindingWheelDiameterWornOut
  }
  set grindingWheelDiameterWornOut(grindingWheelDiameterWornOut: Result[]) {
    this._grindingWheelDiameterWornOut = grindingWheelDiameterWornOut
    setChartData(this)
  }

  _manualDressing: Result[] = []
  get manualDressing(): Result[] {
    return this._manualDressing
  }
  set manualDressing(manualDressing: Result[]) {
    this._manualDressing = manualDressing
    setChartData(this)
  }

  loading$ = new BehaviorSubject<boolean>(false)

  constructor(
    public timeSelection: TimeSelectionService,
    public cycleService: CycleReferencedProcessValuesService,
    public kpiService: KpiService,
    public materialTextPluginService: MaterialTextPluginService,
    public override snackBar: MatSnackBar,
    @Inject(DashboardService) dashboardService: DashboardService,
    @Inject(MachineSelectionService) machineSelection: MachineSelectionService,
    @Inject(MESInfoService) mesInfoService: MESInfoService
  ) {
    super(dashboardService, machineSelection, mesInfoService, snackBar)
    this.loading$.pipe(untilDestroyed(this)).subscribe((loading) => {
      this.dashboardService.setDressingTimingLoading(loading)
    })
  }

  ngOnInit(): void {
    const from$ = this.timeSelection.from$
    const to$ = this.timeSelection.to$
    const eq$ = this.machineSelection.selectedEquipment$
    const workCenterID$ = this.machineSelection.selectedWorkCenterID$

    // Get first eq, from, to and workCenterID and refresh diagram
    combineLatest([eq$, from$, to$, workCenterID$])
      .pipe(untilDestroyed(this))
      .pipe(take(1))
      .subscribe(([eq, from, to, workCenterID]) => {
        if (this.minDate.valueOf() !== from.valueOf()) {
          this.minDate = from
        }
        if (this.maxDate.valueOf() !== to.valueOf()) {
          this.maxDate = to
        }
        if (this.eq !== eq) {
          this.eq = eq
        }
        if (this.workCenterID !== workCenterID) {
          this.workCenterID = workCenterID
        }
        this.refresh()
      })
    // Get all further eq, from, to and workCenterID for further diagram refreshes
    combineLatest([eq$, from$, to$, workCenterID$])
      .pipe(untilDestroyed(this))
      .pipe(skip(1))
      .subscribe(([eq, from, to, workCenterID]) => {
        if (this.minDate.valueOf() !== from.valueOf()) {
          this.minDate = from
        }
        if (this.maxDate.valueOf() !== to.valueOf()) {
          this.maxDate = to
        }
        if (this.eq !== eq) {
          this.eq = eq
        }
        if (this.workCenterID !== workCenterID) {
          this.workCenterID = workCenterID
        }
      })

    this.dashboardService.refresh$
      .pipe(untilDestroyed(this))
      .subscribe((refresh) => {
        this.refresh()
      })

    setChartOptions(this)
    setChartData(this)
  }

  refresh(): void {
    
    if (this.eq != null && this.workCenterID != null) {
      this.retrieveDataFromBackend(
        this.minDate,
        this.maxDate,
        this.eq,
        this.workCenterID
      )
    }
    setChartOptions(this)
    setChartData(this)
  }

  retrieveDataFromBackend(
    from: Date,
    to: Date,
    eq: number,
    workCenterID: number
  ): void {
    this.loading$.next(true)
    this.resetAll()
    
    const orderDataRetrieved$ = new Subject<boolean>()

    retrieveOrders(this, from, to, workCenterID)
      .pipe(untilDestroyed(this))
      .subscribe(
        (orders) => {
          if (orders == null || orders.length === 0) {
            orders = []
            orderDataRetrieved$.next(true)
            return
          }

          const canvas = <HTMLCanvasElement>(
            document.getElementById('dressing-chart')
          )
          const tooltip = <HTMLCanvasElement>(
            document.getElementById('dressing-tip')
          )
          const onLabelClick = (label: Label): void => {
            throw new Error('Not implemented yet')
          }
          const matTxtPlugin = this.materialTextPluginService.buildMatTxtPlugin(
            this.minDate,
            this.maxDate,
            orders,
            canvas,
            tooltip,
            X_AXIS_OFFSET_PERCENTAGE,
            onLabelClick
          )
          this.chartPlugins.pop()
          this.chartPlugins.push(matTxtPlugin)
          orderDataRetrieved$.next(true)
        },
        (error) => {
          orderDataRetrieved$.error(true)
        }
      )

    const currentGrindingTimingRetrieved$ = retrieveGrindingDiameter(
      this,
      from,
      to,
      eq
    )
  
    const manualDressingRetrieved$ = retrieveManualDressing(this, from, to, eq)

    combineLatest([
      currentGrindingTimingRetrieved$,
      manualDressingRetrieved$,
      orderDataRetrieved$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(
        (getsFinished) => {
          const finishedRetrieving = getsFinished.reduce((acc, cur) => {
            return acc && cur
          })
          this.loading$.next(!finishedRetrieving)
        },
        (error: HttpErrorResponse) => {
          this.snackBar.open(`${error.status}: ${error.message}` , 'Close')
          this.loading$.next(false)
        }
      )
  }

  resetAll(): void {
    this.grindingWheelDiameter = []
    this.grindingWheelDiameterWornOut = []
    this.manualDressing = []
  }
}
