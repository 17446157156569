import { Injectable } from '@angular/core'

export const enum Roles {
  Admin = 'Admin',
}

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  roles: string[] = []
}
