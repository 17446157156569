import {
  ReferenceValue,
  ReferenceValueNames,
  ReferenceValues,
  Result,
} from '..'
import * as _ from 'underscore'

/**
 * Return the current active reference values of a reference value name.
 * F.e. the newest reference value for ReferenceAllowancePosition.
 * @param referenceValues the rereference values to search for the newest reference value of a reference value name
 * @param name
 * @returns
 */
export function newestReferenceValue(
  referenceValues: ReferenceValues,
  name: ReferenceValueNames,
  materialNumber: number | null | undefined
): ReferenceValue | null {
  const referenceValuesWithName = referenceValues.values.filter((value) => {
    // Filter all reference values which have the correct name, f.e. "ReferenceAllowancePosition"
    return value.name === name && value.materialNumber === materialNumber
  })

  // No ReferenceValue with given name found
  if (referenceValuesWithName.length === 0) {
    return null
  }

  return referenceValuesWithName.reduce((cur, prev) => {
    // Always return newest reference value
    return cur.validSince.valueOf() > prev.validSince.valueOf() ? cur : prev
  })
}

/**
 * Returns the newest ReferenceValue of an array of ReferenceValue
 * Returns null if there is no ReferenceValue with the given name
 * @param referenceValues
 * @param name
 * @returns
 */
export function newestReferenceValueFromArray(
  referenceValues: ReferenceValue[],
  name: ReferenceValueNames
): ReferenceValue | null {
  // Filter ReferenceValue with correct name
  const referenceValuesWithName = referenceValues.filter((referenceValue) => {
    return referenceValue.name === name
  })

  // No ReferenceValue with given name found
  if (referenceValuesWithName.length === 0) {
    return null
  }

  // Get newest ReferenceValue
  return referenceValuesWithName.reduce((prev, cur) => {
    return prev.validSince.valueOf() > cur.validSince.valueOf() ? prev : cur
  })
}

/**
 * Return reference values sorted by valid since from newest to oldest
 * @param referenceValues
 */
export function sortedReferenceValues(
  referenceValues: ReferenceValue[]
): ReferenceValue[] {
  return referenceValues.sort((prev, cur) => {
    return new Date(prev.validSince).valueOf() <
      new Date(cur.validSince).valueOf()
      ? 0
      : -1
  })
}

function computeLimitsCurrentAllowance(
  referenceAllowances: Result[],
  referenceAllowanceTolerances: Result[],
  upperLower: 'UPPER_LIMITS' | 'LOWER_LIMITS'
): Result[] {
  let times = []
  const tsToReferenceAllowance = new Map<number, Result>()
  const tsToReferenceTolerance = new Map<number, Result>()
  const limitsCurrentAllowances: Result[] = []

  for (const referenceAllowance of referenceAllowances) {
    times.push(new Date(referenceAllowance.ts))
    tsToReferenceAllowance.set(
      new Date(referenceAllowance.ts).valueOf(),
      referenceAllowance
    )
  }

  for (const referenceTolerance of referenceAllowanceTolerances) {
    times.push(new Date(referenceTolerance.ts))
    tsToReferenceTolerance.set(
      new Date(referenceTolerance.ts).valueOf(),
      referenceTolerance
    )
  }

  times.sort((a, b) => {
    return a.valueOf() < b.valueOf() ? -1 : 0
  })

  // Remove duplicates
  times = _.uniq(times, (time: any) => time.valueOf())

  let curRefAllowance: Result | null = null
  let curRefTolerance: Result | null = null

  for (const time of times) {
    // Set to new value. Do not change if there is no value in the map.
    curRefAllowance =
      tsToReferenceAllowance.get(time.valueOf()) ?? curRefAllowance
    curRefTolerance =
      tsToReferenceTolerance.get(time.valueOf()) ?? curRefTolerance

    if (!curRefAllowance || !curRefTolerance) {
      continue
    }

    if (upperLower === 'UPPER_LIMITS') {
      limitsCurrentAllowances.push({
        ts: time.toISOString(),
        value: curRefAllowance.value + curRefTolerance.value,
      })
    }
    if (upperLower === 'LOWER_LIMITS') {
      limitsCurrentAllowances.push({
        ts: time.toISOString(),
        value: curRefAllowance.value - curRefTolerance.value,
      })
    }
  }

  return limitsCurrentAllowances
}

/**
 * Compute upper limits of current allowance sorted from old to new.
 * @param referenceAllowances
 * @param referenceAllowanceTolerances
 * @returns
 */
export function upperLimitsCurrentAllowance(
  referenceAllowances: Result[],
  referenceAllowanceTolerances: Result[]
): Result[] {
  return computeLimitsCurrentAllowance(
    referenceAllowances,
    referenceAllowanceTolerances,
    'UPPER_LIMITS'
  )
}

/**
 * Compute lower limits of current allowance.
 * @param referenceAllowances
 * @param referenceAllowanceTolerances
 * @returns
 */
export function lowerLimitsCurrentAllowance(
  referenceAllowances: Result[],
  referenceAllowanceTolerances: Result[]
): Result[] {
  return computeLimitsCurrentAllowance(
    referenceAllowances,
    referenceAllowanceTolerances,
    'LOWER_LIMITS'
  )
}
