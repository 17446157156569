import { Component, Inject, Input, OnInit, Optional } from '@angular/core'
import { AbstractControl, FormControl } from '@angular/forms'
import { TimeSelectionService } from '@dms-frontend/shared/services/time-selection'
import { DashboardService } from '@dms-frontend/tds/services/dashboard'
import { formatDate } from '@angular/common'
import { TIME_SELECTION_DATE_FORMAT, TIME_SELECTION_TIME_FORMAT } from './time-selection-token'
import { MAT_TIME_LOCALE } from '@dhutaryan/ngx-mat-timepicker'

const FOURTEEN_DAYS_IN_MS = 14 * 24 * 60 * 60 * 1000

@Component({
  selector: 'dms-frontend-time-selection',
  templateUrl: './time-selection.component.html',
  styleUrls: ['./time-selection.component.scss'],
})
export class TimeSelectionComponent implements OnInit {
  dateFormat = 'yyyy-MM-dd'
  timeFormat = 'HH:mm'
  dateTimeFormat = `${this.dateFormat} ${this.timeFormat}`
  locale = 'de-DE'

  selected: Date | null = null

  _disableFromSelection = false
  get disableFromSelection(): boolean {
    return this._disableFromSelection
  }
  @Input() set disableFromSelection(disable: boolean) {
    this._disableFromSelection = disable
    if (disable) {
      this.dateTimeFromControl.disable()
    } else {
      this.dateTimeFromControl.enable()
    }
  }

  currentDateTimeFrom: Date
  currentTimeFrom: Date
  currentDateFrom: Date
  maxTimeFrom: Date
  minDateFrom = new Date(Date.now())
  prevDateFrom = new Date(Date.now())

  currentDateTimeTo: Date
  currentTimeTo: Date
  currentDateTo: Date
  minTimeTo: Date
  maxDateTo = new Date(Date.now())
  prevDateTo = new Date(Date.now())


  // Time selection 'from': TimeFormControl
  timeFromControl: FormControl
  dateFromControl: FormControl
  dateTimeFromControl: FormControl

  private _selectedTimeFrom: Date = new Date(0)
  get selectedTimeFrom(): Date {
    return this._selectedTimeFrom
  }
  set selectedTimeFrom(timeFrom: Date) {
    this._selectedTimeFrom = timeFrom
    this.timeSelectionService.selectFrom(timeFrom)
  }

  // Time selection 'to': TimeFormControl
  timeToControl: FormControl
  dateToControl: FormControl
  dateTimeToControl: FormControl

  private _selectedTimeTo: Date = new Date(0)
  get selectedTimeTo(): Date {
    return this._selectedTimeTo
  }
  set selectedTimeTo(timeTo: Date) {
    this._selectedTimeTo = timeTo
    this.timeSelectionService.selectTo(timeTo)
  }

  constructor(
    private readonly timeSelectionService: TimeSelectionService,
    private readonly dashboardService: DashboardService,
    @Optional() @Inject(TIME_SELECTION_DATE_FORMAT) dateFormat: string,
    @Optional() @Inject(TIME_SELECTION_TIME_FORMAT) timeFormat: string,
    @Optional() @Inject(MAT_TIME_LOCALE) locale: string
  ) {
    if(dateFormat && timeFormat) {
      this.dateTimeFormat = `${dateFormat} ${timeFormat}`
    }

    if(locale) {
      this.locale = locale
    }

    this.currentDateTimeFrom = this.timeSelectionService.getFrom()
    this.currentDateFrom = this.timeSelectionService.getFrom()
    this.currentTimeFrom = this.timeSelectionService.getFrom()

    this.currentDateTimeTo = this.timeSelectionService.getTo()
    this.currentDateTo = this.timeSelectionService.getTo()
    this.currentTimeTo = this.timeSelectionService.getTo()

    this.dateTimeFromControl = new FormControl(
      formatDate(this.currentDateTimeFrom, this.dateTimeFormat, locale),
      this.dateTimeFormatValidator
    )

    this.dateTimeToControl = new FormControl(
      formatDate(this.currentDateTimeTo, this.dateTimeFormat, this.locale),
      this.dateTimeFormatValidator
    )

    this.dateFromControl = new FormControl(this.currentTimeFrom)
    this.timeFromControl = new FormControl(this.currentTimeFrom)
    this.dateToControl = new FormControl(this.currentTimeTo)
    this.timeToControl = new FormControl(this.currentTimeTo)

    this.addValidators()

    this.dashboardService.realTime$.subscribe((realtimeFlag: boolean) => {
      if (realtimeFlag) {
        this.dateTimeToControl.disable()
        this.dateToControl.disable()
        this.timeToControl.disable()
      } else {
        this.dateTimeToControl.enable()
        this.dateToControl.enable()
        this.timeToControl.enable()
      }
    })

    this.addChangeSubscriptions()

    this.prevDateFrom = this.currentTimeFrom
    this.prevDateTo = this.currentTimeTo
    this.selectedTimeFrom = this.currentTimeFrom
    this.selectedTimeTo = this.currentTimeTo
    this.maxTimeFrom = this.currentTimeTo
    this.minTimeTo = this.currentTimeFrom
  }

  ngOnInit(): void {
    this.initTimeRanges()
  }

  setFromDateTime(): void {
    const currentTimeFrom = formatDate(this.currentTimeFrom, this.timeFormat, this.locale)
    const currentDateFrom = formatDate(
      this.currentDateFrom,
      this.dateFormat,
      this.locale
    )
    const currentDatTimeString = formatDate(
      new Date(currentDateFrom + ' ' + currentTimeFrom),
      this.dateTimeFormat,
      this.locale
    )
    const currentDateTime = new Date(currentDatTimeString)

    if (currentDateTime.toString() !== this.prevDateFrom.toString()) {
      this.updateFromDateTime(currentDateTime, currentDatTimeString)
    }
  }

  setToDateTime(): void {
    const currentTimeTo = formatDate(this.currentTimeTo, this.timeFormat, this.locale)
    const currentDateTo = formatDate(this.currentDateTo, this.dateFormat, this.locale)
    const currentDatTimeString = formatDate(
      new Date(currentDateTo + ' ' + currentTimeTo),
      this.dateTimeFormat,
      this.locale
    )
    const currentDateTime = new Date(currentDatTimeString)

    if (currentDateTime.toString() !== this.prevDateTo.toString()) {
      this.updateToDateTime(currentDateTime, currentDatTimeString)
    }
  }

  updateToDateTime(currentDateTime: Date, currentDateTimeString: string): void {
    this.prevDateTo = currentDateTime
    this.selectedTimeTo = currentDateTime
    this.dateTimeToControl.setValue(currentDateTimeString)
  }

  updateFromDateTime(
    currentDateTime: Date,
    currentDateTimeString: string
  ): void {
    this.prevDateFrom = currentDateTime
    this.selectedTimeFrom = currentDateTime
    this.dateTimeFromControl.setValue(currentDateTimeString)
  }

  initTimeRanges(): void {
    this.minDateFrom.setDate(this.minDateFrom.getDate() - 14)
    this.maxDateTo.setDate(this.maxDateTo.getDate() + 14)
  }

  getDiffDays(dateA: Date, dateB: Date): number {
    return Math.abs(dateA.getDay() - dateB.getDay())
  }

  dateTimeFormatValidator(control: AbstractControl): { invalidDateTime: true } | null {
    const dateTime = control.value
    const date = new Date(dateTime)
    if (isNaN(date.getTime())) {
      return { invalidDateTime: true }
    }
    return null
  }

  addValidators(): void {
    this.dateTimeFromControl.addValidators((control) => {
      const dateTime = control.value
      const date = new Date(dateTime)
      if (
        dateTime !== this.prevDateFrom.toString() &&
        date.getTime() >= this.selectedTimeTo.getTime()
      ) {
        return { invalidFromTime: true }
      } 
      else if (dateTime !== this.prevDateFrom.toString() &&
        this.selectedTimeTo.getTime() - date.getTime() > FOURTEEN_DAYS_IN_MS) {
        return { dateTimeRangeOverflow: true }
      }
  
      return null
    })

    this.dateTimeToControl.addValidators((control) => {
      const dateTime = control.value
      const date = new Date(dateTime)
      if (
        dateTime !== this.prevDateTo.toString() &&
        date.getTime() <= this.selectedTimeFrom.getTime()
      ) {
        return { invalidToTime: true }
      } else if (dateTime !== this.prevDateTo.toString() && 
        date.getTime() - this.selectedTimeFrom.getTime() > FOURTEEN_DAYS_IN_MS) {
        return { dateTimeRangeOverflow: true }
      }
      return null
    })
  }

  addChangeSubscriptions(): void {
    this.timeSelectionService.from$.subscribe((dateFrom: Date) => {
      if (dateFrom.toString() !== this.prevDateFrom.toString()) {
        this.timeFromControl.setValue(dateFrom)
        this.dateFromControl.setValue(dateFrom)
        this.setFromDateTime()
      }
    })

    this.timeSelectionService.to$.subscribe((dateTo: Date) => {
      if (dateTo.toString() !== this.prevDateTo.toString()) {
        this.timeToControl.setValue(dateTo)
        this.dateToControl.setValue(dateTo)
        this.setToDateTime()
      }
    })

    this.dateFromControl.valueChanges.subscribe((dateFrom: Date) => {
      this.currentDateFrom = dateFrom
      if (this.getDiffDays(this.selectedTimeTo, dateFrom) > 0) {
        this.maxTimeFrom = new Date(0, 0, 0, 23, 59, 59)
      } else {
        this.maxTimeFrom = this.selectedTimeTo
      }
  
      return null
    })

    this.timeFromControl.valueChanges.subscribe((dateFrom: Date) => {
      this.currentTimeFrom = dateFrom
    })

    this.dateToControl.valueChanges.subscribe((dateTo: Date) => {
      this.currentDateTo = dateTo

      if (this.getDiffDays(this.selectedTimeFrom, dateTo) > 0) {
        this.minTimeTo = new Date(0, 0, 0, 0, 0, 0)
      } else {
        this.minTimeTo = this.selectedTimeFrom
      }
      return null
    })

    this.timeToControl.valueChanges.subscribe((dateTo: Date) => {
      this.currentTimeTo = dateTo
    })

    this.dateTimeFromControl.valueChanges.subscribe((dateTimeFrom: string) => {
      if (this.dateTimeFromControl.valid) {
        const dateTimeFromDate = new Date(dateTimeFrom)
        this.dateFromControl.setValue(dateTimeFromDate)
        this.timeFromControl.setValue(dateTimeFromDate)
        this.prevDateFrom = dateTimeFromDate
        this.selectedTimeFrom = dateTimeFromDate
      }
    })

    this.dateTimeToControl.valueChanges.subscribe((dateTimeTo: string) => {
      if (this.dateTimeToControl.valid) {
        const dateTimeToDate = new Date(dateTimeTo)
        this.dateToControl.setValue(dateTimeToDate)
        this.timeToControl.setValue(dateTimeToDate)
        this.prevDateTo = dateTimeToDate
        this.selectedTimeTo = dateTimeToDate
      }
    })
  }
}
