import { MachineSelectionService } from '@dms-frontend/shared/services/machine-selection'
import { MESInfoService } from '@dms-frontend/tds/services/api'
import { DashboardService } from '@dms-frontend/tds/services/dashboard'
import { ChartData, ChartOptions, Plugin } from 'chart.js'
import { take } from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { MatSnackBar } from '@angular/material/snack-bar'

@UntilDestroy()
export abstract class MesChartComponent {
  /** =======================================================
   *                     MIN-MAX-DATE
   *  =======================================================
   */
  minDate = new Date(Date.now() - 24 * 60 * 60 * 1000)

  maxDate = new Date(Date.now())

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartData!: ChartData<any>
  chartOptions!: ChartOptions
  chartPlugins: Plugin[] = []

  _eq: number | null = null
  get eq(): number | null {
    return this._eq
  }
  set eq(eq: number | null) {
    this._eq = eq
    if (eq != null) {
      this.machineSelection.selectedWorkCenterID$
        .pipe(take(1))
        .pipe(untilDestroyed(this))
        .subscribe((workCenterID) => {
          this.workCenterID = workCenterID
        })
    }
  }

  workCenterID: number | null = null

  constructor(
    public dashboardService: DashboardService,
    public machineSelection: MachineSelectionService,
    public mesInfoService: MESInfoService,
    public snackBar: MatSnackBar
  ) {}

  abstract refresh(): void
}
