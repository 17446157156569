import { Component, Inject, OnInit } from '@angular/core'
import 'chartjs-adapter-date-fns'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {
  AllowanceMonitorService,
  CycleReferencedProcessValuesService,
  MESInfoService,
  ReferenceValuesService,
  Result,
  lowerLimitsCurrentAllowance,
  upperLimitsCurrentAllowance,
} from '@dms-frontend/tds/services/api'
import { BehaviorSubject, Subject, combineLatest, skip, take } from 'rxjs'
import { DECIMAL_PLACES, X_AXIS_OFFSET_PERCENTAGE } from '../config/config'
import {
  retrieveAllowancePos,
  retrieveCurrentAllowances,
  retrieveKPIs,
} from './data-retriever/allowance-data-retriever'
import { setChartOptions } from './chart-options'
import { setChartData } from './chart-data'
import { MachineSelectionService } from '@dms-frontend/shared/services/machine-selection'
import { TimeSelectionService } from '@dms-frontend/shared/services/time-selection'
import { retrieveReferenceAllowancePos } from './data-retriever/reference-values-retriever'
import { DashboardService } from '@dms-frontend/tds/services/dashboard'
import {
  Label,
  MaterialTextPluginService,
} from '@dms-frontend/tds/utils/chart-plugins'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { MesChartComponent, retrieveOrders } from '@dms-frontend/tds/ui/charts'
import { MatSnackBar } from '@angular/material/snack-bar'
import { HttpErrorResponse } from '@angular/common/http'

@UntilDestroy()
@Component({
  selector: 'dms-frontend-allowance-diagramm',
  templateUrl: './allowance-diagramm.component.html',
  styleUrls: ['./allowance-diagramm.component.scss'],
})
export class AllowanceDiagrammComponent
  extends MesChartComponent
  implements OnInit
{
  /** =======================================================
   *                     CHART DATA
   *  =======================================================
   */
  _currentAllowances: Result[] = []
  get currentAllowances(): Result[] {
    return this._currentAllowances
  }
  set currentAllowances(currentAllowances: Result[]) {
    this._currentAllowances = currentAllowances
    this.computeAllowanceOutliers()
    setChartData(this)
  }

  _averagesCurrentAllowance: Result[] = []
  get averagesCurrentAllowance(): Result[] {
    return this._averagesCurrentAllowance
  }
  set averagesCurrentAllowance(averageCurrentAllowances: Result[]) {
    this._averagesCurrentAllowance = averageCurrentAllowances
    setChartData(this)
  }

  _suggestionsAllowancePositions: Result[] = []
  get suggestionsAllowancePositions(): Result[] {
    return this._suggestionsAllowancePositions
  }
  set suggestionsAllowancePositions(suggestionsAllowancePositions: Result[]) {
    this._suggestionsAllowancePositions = suggestionsAllowancePositions
    setChartData(this)
  }

  _allowancePositions: Result[] = []
  get allowancePositions(): Result[] {
    return this._allowancePositions
  }
  set allowancePositions(allowancePositions: Result[]) {
    this._allowancePositions = allowancePositions
    this.computeAllowancePosOutliers()
    setChartData(this)
  }

  _referenceAllowances: Result[] = []
  get referenceAllowances(): Result[] {
    return this._referenceAllowances
  }
  set referenceAllowances(referenceAllowances: Result[]) {
    this._referenceAllowances = referenceAllowances
    this.computeUpperLimitsCurrentAllowance()
    this.computeLowerLimitsCurrentAllowance()
    this.computeAllowanceOutliers()
    setChartData(this)
  }

  _referenceAllowancePositions: Result[] = []
  get referenceAllowancePositions(): Result[] {
    return this._referenceAllowancePositions
  }
  set referenceAllowancePositions(referenceAllowancePositions: Result[]) {
    this._referenceAllowancePositions = referenceAllowancePositions
    this.computeAllowancePosOutliers()
    setChartData(this)
  }

  _referenceAllowanceTolerances: Result[] = []
  get referenceAllowanceTolerances(): Result[] {
    return this._referenceAllowanceTolerances
  }
  set referenceAllowanceTolerances(referenceAllowanceTolerances: Result[]) {
    this._referenceAllowanceTolerances = referenceAllowanceTolerances
    this.computeUpperLimitsCurrentAllowance()
    this.computeLowerLimitsCurrentAllowance()
    this.computeAllowanceOutliers()
    setChartData(this)
  }

  _lowerLimitsCurrentAllowance: Result[] = []
  get lowerLimitsCurrentAllowance(): Result[] {
    return this._lowerLimitsCurrentAllowance
  }
  set lowerLimitsCurrentAllowance(lowerLimitsCurrentAllowance: Result[]) {
    this._lowerLimitsCurrentAllowance = lowerLimitsCurrentAllowance
    setChartData(this)
  }

  _upperLimitsCurrentAllowance: Result[] = []
  get upperLimitsCurrentAllowance(): Result[] {
    return this._upperLimitsCurrentAllowance
  }
  set upperLimitsCurrentAllowance(upperLimitsCurrentAllowance: Result[]) {
    this._upperLimitsCurrentAllowance = upperLimitsCurrentAllowance
    setChartData(this)
  }

  _allowanceOutliers: Result[] = []
  get allowanceOutliers(): Result[] {
    return this._allowanceOutliers
  }
  set allowanceOutliers(allowanceOutliers: Result[]) {
    this._allowanceOutliers = allowanceOutliers
    setChartData(this)
  }

  _allowancePositionOutliers: Result[] = []
  get allowancePositionOutliers(): Result[] {
    return this._allowancePositionOutliers
  }
  set allowancePositionOutliers(allowancePositionOutliers: Result[]) {
    this._allowancePositionOutliers = allowancePositionOutliers
    setChartData(this)
  }

  loading$ = new BehaviorSubject<boolean>(false)

  constructor(
    public allowanceKpi: AllowanceMonitorService,
    public cycleReferencedValues: CycleReferencedProcessValuesService,
    public timeSelection: TimeSelectionService,
    public referenceValues: ReferenceValuesService,
    public materialTextPluginService: MaterialTextPluginService,
    public override snackBar: MatSnackBar,
    @Inject(DashboardService) dashboardService: DashboardService,
    @Inject(MachineSelectionService) machineSelection: MachineSelectionService,
    @Inject(MESInfoService) mesInfoService: MESInfoService
  ) {
    super(dashboardService, machineSelection, mesInfoService, snackBar)
    this.loading$.pipe(untilDestroyed(this)).subscribe((loading) => {
      this.dashboardService.setAllowanceDiagramLoading(loading)
    })


  }

  ngOnInit(): void {
    // Workaround because of materialTextPluginService popping
    this.chartPlugins.push(ChartDataLabels)
    this.chartPlugins.push(ChartDataLabels)

    const from$ = this.timeSelection.from$
    const to$ = this.timeSelection.to$
    const eq$ = this.machineSelection.selectedEquipment$
    const workCenterID$ = this.machineSelection.selectedWorkCenterID$

    // Get first eq, from, to and workCenterID and refresh diagram
    combineLatest([eq$, from$, to$, workCenterID$])
      .pipe(take(1))
      .pipe(untilDestroyed(this))
      .subscribe(([eq, from, to, workCenterID]) => {
        if (this.minDate.valueOf() !== from.valueOf()) {
          this.minDate = from
        }
        if (this.maxDate.valueOf() !== to.valueOf()) {
          this.maxDate = to
        }
        if (this.eq !== eq) {
          this.eq = eq
        }
        if (this.workCenterID !== workCenterID) {
          this.workCenterID = workCenterID
        }
        this.refresh()
      })
    // Get all further eq, from, to and workCenterID for further diagram refreshes
    combineLatest([eq$, from$, to$, workCenterID$])
      .pipe(skip(1))
      .pipe(untilDestroyed(this))
      .subscribe(([eq, from, to, workCenterID]) => {
        if (this.minDate.valueOf() !== from.valueOf()) {
          this.minDate = from
        }
        if (this.maxDate.valueOf() !== to.valueOf()) {
          this.maxDate = to
        }
        if (this.eq !== eq) {
          this.eq = eq
        }
        if (this.workCenterID !== workCenterID) {
          this.workCenterID = workCenterID
        }
      })

    this.dashboardService.refresh$
      .pipe(untilDestroyed(this))
      .subscribe((refresh) => {
        this.refresh()
      })

    setChartOptions(this)
    setChartData(this)
  }

  refresh(): void {
    setChartOptions(this)
    if (this.eq != null && this.workCenterID != null) {
      this.retrieveDataFromBackend(
        this.minDate,
        this.maxDate,
        this.eq,
        this.workCenterID
      )
    }
    setChartData(this)
  }

  // Retrieves data from backend and updates diagram
  retrieveDataFromBackend(
    from: Date,
    to: Date,
    eq: number,
    workCenterID: number
  ): void {
    this.loading$.next(true)
    this.resetAll()

    const orderDependentDataRetrieved$ = new Subject<boolean>()

    retrieveOrders(this, from, to, workCenterID)
      .pipe(untilDestroyed(this))
      .subscribe(
        (orders) => {
          if (orders == null || orders.length === 0) {
            orders = []
            orderDependentDataRetrieved$.next(true)
            return
          }
         
          const canvas = <HTMLCanvasElement>(
            document.getElementById('allowance-chart')
          )
          const tooltip = <HTMLCanvasElement>(
            document.getElementById('allowance-tip')
          )
          const onLabelClick = (label: Label): void => {
            throw new Error('Not implemented yet')
          }
          const matTxtPlugin = this.materialTextPluginService.buildMatTxtPlugin(
            this.minDate,
            this.maxDate,
            orders,
            canvas,
            tooltip,
            X_AXIS_OFFSET_PERCENTAGE,
            onLabelClick
          )
          this.chartPlugins.pop()
          this.chartPlugins.push(matTxtPlugin)
          this.chartPlugins.push(matTxtPlugin)


          const refAllowanceRetrieved$ = retrieveReferenceAllowancePos(
            this,
            eq,
            orders
          )
          const kpisRetrieved$ = retrieveKPIs(this, eq, orders)

          combineLatest([refAllowanceRetrieved$, kpisRetrieved$])
            .pipe(untilDestroyed(this))
            .subscribe(
              (getFinished) => {
                const finishedRetrieving = getFinished.reduce((acc, cur) => {
                  return acc && cur
                })
                orderDependentDataRetrieved$.next(finishedRetrieving)
              },
              (error) => {
                orderDependentDataRetrieved$.error(error)
              }
            )
        },
        (error) => {
          orderDependentDataRetrieved$.error(true)
        }
      )

    const currentAllowanceRetrieved$ = retrieveCurrentAllowances(
      this,
      from,
      to,
      eq
    )
    const allowancePosRetrieved$ = retrieveAllowancePos(this, from, to, eq)

    combineLatest([
      currentAllowanceRetrieved$,
      allowancePosRetrieved$,
      orderDependentDataRetrieved$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(
        (getsFinished) => {
          const finishedRetrieving = getsFinished.reduce((acc, cur) => {
            return acc && cur
          })
          this.loading$.next(!finishedRetrieving)
        },
        (error: HttpErrorResponse) => {
          this.snackBar.open(`${error.status}: ${error.statusText}` , 'Close')
          this.loading$.next(false)
        }
      )
  }

  resetAll(): void {
    this.currentAllowances = []
    this.allowancePositions = []
    this.suggestionsAllowancePositions = []
    this.referenceAllowances = []
    this.referenceAllowancePositions = []
    this.referenceAllowanceTolerances = []
    this.averagesCurrentAllowance = []
  }

  computeUpperLimitsCurrentAllowance(): void {
    const tmp = upperLimitsCurrentAllowance(
      this.referenceAllowances,
      this.referenceAllowanceTolerances
    ).map((el) => {
      const result: Result = {
        ...el,
        value: +el.value.toFixed(DECIMAL_PLACES),
      }
      return result
    })
    this.upperLimitsCurrentAllowance = tmp
  }

  computeLowerLimitsCurrentAllowance(): void {
    this.lowerLimitsCurrentAllowance = lowerLimitsCurrentAllowance(
      this.referenceAllowances,
      this.referenceAllowanceTolerances
    ).map((el) => {
      const result: Result = {
        ...el,
        value: +el.value.toFixed(DECIMAL_PLACES),
      }
      return result
    })
  }

  computeAllowancePosOutliers(): void {
    let curReferenceAllowancePosition: Result | null = null

    this.allowancePositionOutliers = this.allowancePositions.filter(
      (allowancePosition) => {
        for (let i = 0; i < this.referenceAllowancePositions.length; i++) {
          if (
            this.referenceAllowancePositions[i].ts.valueOf() <=
            allowancePosition.ts.valueOf()
          ) {
            curReferenceAllowancePosition = this.referenceAllowancePositions[i]
          } else {
            break
          }
        }

        const overUpperLimit = curReferenceAllowancePosition
          ? Math.abs(
            allowancePosition.value - curReferenceAllowancePosition.value
          ) >= 0.01
          : false
        return overUpperLimit
      }
    )
  }

  computeAllowanceOutliers(): void {
    let curUpperLimit: Result | null = null
    let curLowerLimit: Result | null = null

    this.allowanceOutliers = this.currentAllowances.filter((allowance) => {
      for (
        let i_lower = 0;
        i_lower < this.lowerLimitsCurrentAllowance.length;
        i_lower++
      ) {
        if (
          this.lowerLimitsCurrentAllowance[i_lower].ts.valueOf() <=
          allowance.ts.valueOf()
        ) {
          curLowerLimit = this.lowerLimitsCurrentAllowance[i_lower]
        } else {
          break
        }
      }

      for (
        let i_upper = 0;
        i_upper < this.upperLimitsCurrentAllowance.length;
        i_upper++
      ) {
        if (
          this.upperLimitsCurrentAllowance[i_upper].ts.valueOf() <=
          allowance.ts.valueOf()
        ) {
          curUpperLimit = this.upperLimitsCurrentAllowance[i_upper]
        } else {
          break
        }
      }

      const overUpperLimit = curUpperLimit
        ? allowance.value > curUpperLimit.value
        : false
      const underLowerLimit = curLowerLimit
        ? allowance.value < curLowerLimit.value
        : false
      return overUpperLimit || underLowerLimit
    })
  }
}
