import { Component, Inject, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular'

import { NavigationService } from '@dms-frontend/shared/services/navigation'
import {
  IconStructure,
  MenuIcon,
  MenuLevel,
  MenuStructure,
} from '@dms-frontend/shared/ui/navigation-bar'
import { AzureAuthService } from '@dms-frontend/shared/services/azure-auth'
import { filter } from 'rxjs'
import {
  AuthenticationResult,
  EventMessage,
  EventType,
} from '@azure/msal-browser'
import { routePaths } from './routes'

@Component({
  selector: 'dms-frontend-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  routePaths = routePaths
  title = 'tds'
  home: MenuStructure
  dashboard: MenuStructure
  overview: MenuStructure
  administration: MenuStructure
  referenceValues: MenuStructure
  allowanceMonitor: MenuStructure
  processConfig: MenuStructure
  cycleTimeMonitor: MenuStructure
  dressingMonitor: MenuStructure

  headerUser: IconStructure
  headerInfo: IconStructure

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Inject('APP_ID') private appID: string,
    private tdsAuthService: AzureAuthService
  ) {
    this.home = {
      name: 'Home',
      menuLevel: MenuLevel.Root,
      hasSubMenus: false,
      iconStructure: { icon: 'home', iconStyle: MenuIcon.filled },
    }
    this.overview = {
      name: 'Overview',
      menuLevel: MenuLevel.SecondLevel,
      hasSubMenus: false,
      iconStructure: { icon: 'grid_view', iconStyle: MenuIcon.outlined },
    }
    this.allowanceMonitor = {
      name: 'Allowance Monitor',
      menuLevel: MenuLevel.SecondLevel,
      hasSubMenus: false,
      iconStructure: { icon: 'speed', iconStyle: MenuIcon.outlined },
    }
    this.processConfig = {
      name: 'Process Config.',
      menuLevel: MenuLevel.SecondLevel,
      hasSubMenus: false,
      iconStructure: { icon: 'settings', iconStyle: MenuIcon.outlined },
    }
    this.cycleTimeMonitor = {
      name: 'Cycle Time Monitor',
      menuLevel: MenuLevel.SecondLevel,
      hasSubMenus: false,
      iconStructure: { icon: 'av_timer', iconStyle: MenuIcon.outlined },
    }
    this.dressingMonitor = {
      name: 'Dressing Monitor',
      menuLevel: MenuLevel.SecondLevel,
      hasSubMenus: false,
      iconStructure: { icon: 'diamond', iconStyle: MenuIcon.outlined },
    }
    this.dashboard = {
      name: 'Dashboard',
      menuLevel: MenuLevel.Root,
      hasSubMenus: true,
      subMenuList: [
        this.overview.name,
        this.allowanceMonitor.name,
        this.processConfig.name,
        this.cycleTimeMonitor.name,
        this.dressingMonitor.name,
      ],
      iconStructure: { icon: 'dashboard', iconStyle: MenuIcon.filled },
    }
    this.referenceValues = {
      name: 'Reference Values',
      menuLevel: MenuLevel.SecondLevel,
      hasSubMenus: false,
      iconStructure: {
        icon: 'workspace_premium',
        iconStyle: MenuIcon.outlined,
      },
    }
    this.administration = {
      name: 'Administration',
      menuLevel: MenuLevel.Root,
      hasSubMenus: true,
      subMenuList: [this.referenceValues.name],
      iconStructure: { icon: 'person', iconStyle: MenuIcon.filled },
    }
    this.headerUser = { icon: 'person', iconStyle: MenuIcon.filled }
    this.headerInfo = { icon: 'info', iconStyle: MenuIcon.filled }

    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        switch (e.url) {
        case '/':
          this.navigationService.setFocusSignal(this.home.name)
          break
        case routePaths.overview.path:
          this.navigationService.setFocusSignal(this.overview.name)
          break
        case routePaths.allowanceMonitor.path:
          this.navigationService.setFocusSignal(this.allowanceMonitor.name)
          break
        case routePaths.processConfig.path:
          this.navigationService.setFocusSignal(this.processConfig.name)
          break
        case routePaths.cycleTimeMonitor.path:
          this.navigationService.setFocusSignal(this.cycleTimeMonitor.name)
          break
        case routePaths.dressingMonitor.path:
          this.navigationService.setFocusSignal(this.dressingMonitor.name)
          break
        case routePaths.referenceValues.path:
          this.navigationService.setFocusSignal(this.referenceValues.name)
          break
        default:
          break
        }
      }
    })
  }

  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS
        )
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult
        this.authService.instance.setActiveAccount(payload.account)
      })
  }
}
