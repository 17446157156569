import { Component } from '@angular/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import {
  CreateMaterialNumberDialogComponent, CreateMaterialNumberDialogInput,
} from '@dms-frontend/tds-ui-material-number-dialogs'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'dms-frontend-reference-values',
  templateUrl: './reference-values.component.html',
  styleUrls: ['./reference-values.component.scss'],
})
export class ReferenceValuesComponent {

  loading$ = new BehaviorSubject<boolean>(false)

  constructor(
    public dialog: MatDialog,
  ) { }

  addMaterialDialog(): void {
    const data: CreateMaterialNumberDialogInput = {
      equipmentNumber: null
    }
    this.dialog.open(CreateMaterialNumberDialogComponent, { data: data })
  }
}
