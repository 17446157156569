/**
 * State
 * This API provides comparison result of machine process values
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { State } from './state'

export type CategoryEnum = 'AllowanceMonitor' | 'ProcessConfig' | 'NoDataFound'
export const CategoryEnum = {
  AllowanceMonitor: 'AllowanceMonitor' as CategoryEnum,
  ProcessConfig: 'ProcessConfig' as CategoryEnum,
  NoDataFound: 'NoDataFound' as CategoryEnum,
}

export interface EquipmentState {
  /**
   * Indicates that all states are 'OK'
   */
  ok: boolean
  ts: string
  category: CategoryEnum
  states: Array<State>
}
