import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DashboardHeaderComponent } from './dashboard-header/dashboard-header.component'
import { TdsUiLineStatesModule } from '@dms-frontend/tds-ui-line-states'
import { SharedMaterialModule } from '@dms-frontend/shared/material'
import { MachineSelectionModule } from '@dms-frontend/shared/ui/machine-selection'
import { TimeSelectionModule } from '@dms-frontend/shared/ui/time-selection'
import { FormsModule } from '@angular/forms'
import { TdsServicesDashboardModule } from '@dms-frontend/tds/services/dashboard'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TdsUiLineStatesModule,
    SharedMaterialModule,
    MachineSelectionModule,
    TimeSelectionModule,
    TdsServicesDashboardModule
  ],
  declarations: [DashboardHeaderComponent],
  exports: [DashboardHeaderComponent]
})
export class TdsUiDashboardHeaderModule {}
