import { Component } from '@angular/core'
import { MachineSelectionService } from '@dms-frontend/shared/services/machine-selection'
import { TimeSelectionService } from '@dms-frontend/shared/services/time-selection'
import {
  CycleTimeMonitorIntervalKPIs,
  KpiService,
  MESInfoService,
  Machine,
} from '@dms-frontend/tds/services/api'
import { DashboardService } from '@dms-frontend/tds/services/dashboard'
import {
  BehaviorSubject,
  Subject,
  Subscription,
  combineLatest,
  filter,
  switchMap,
  take,
} from 'rxjs'

export interface TileData {
  equipmentNumber: string
  value: number
  referenceValue: number
  comparison:
    | 'match'
    | 'small_violation'
    | 'large_violation'
    | 'no_comparison_possible'
  workcenterID: number
}

@Component({
  selector: 'dms-frontend-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent {
  allMachines: Machine[] = []
  selectedLines: string[] = []
  currentOpenLines: string[] = []
  possibleEQ = new Map<string, TileData[]>()
  realTime = false
  disabled = false
  loading$ = new BehaviorSubject<boolean>(false)
  from: Date | null = null
  to: Date | null = null
  subscriptions = new Subscription()

  constructor(
    private machineSelectionService: MachineSelectionService,
    private timeSelectionService: TimeSelectionService,
    private cycleTimeMonitorService: KpiService,
    private dashboardService: DashboardService,
    private mesInfoService: MESInfoService
  ) {
    this.machineSelectionService.allMachines$.subscribe(
      (machines) => (this.allMachines = machines)
    )
    this.machineSelectionService.machinesInSelectedLine$.subscribe(
      (machines) => {
        this.selectedLines = Array.from(
          new Set(machines.map((machine) => machine.line))
        )
      }
    )

    this.machineSelectionService.selectedSegment$.subscribe((segment) => {
      const filteredMachines = Array.from(
        new Set(
          this.allMachines
            .filter(
              (machine) =>
                machine.plantCode === this.machineSelectionService.selectedPlant
            )
            .filter((machine) => machine.segment === segment)
        )
      )
      this.selectedLines = filteredMachines.map((machine) => machine.line)
      this.selectedLines = this.selectedLines.filter(
        (n, i) => this.selectedLines.indexOf(n) === i
      )
      this.possibleEQ.clear()
      this.selectedLines.forEach((line) => {
        const tempTileData: TileData[] = []
        filteredMachines
          .filter((machine) => machine.line === line)
          .map((machine) => {
            return { eq: machine.eqnr, workcenterID: machine.mesworkcenterid }
          })
          .forEach((machine) => {
            tempTileData.push({
              equipmentNumber: machine.eq.toString(),
              value: 0,
              referenceValue: 0,
              comparison: 'no_comparison_possible',
              workcenterID: machine.workcenterID as number,
            })
          })
        this.possibleEQ.set(line, tempTileData)
      })
    })

    const timeOneHourPast = new Date(Date.now() - 1 * 1000 * 60 * 60)
    this.timeSelectionService.selectFrom(timeOneHourPast)
  }

  showAverageCycleTimeInterval(line: string): void {
    if (this.currentOpenLines.includes(line)) {
      const index = this.currentOpenLines.indexOf(line)
      const x = this.currentOpenLines.splice(index, 1)
    } else {
      this.currentOpenLines.push(line)
      this.retrieveDataForEQs(line)
    }
  }

  retrieveDataForEQs(line: string): void {
    const from$ = this.timeSelectionService.from$
    const to$ = this.timeSelectionService.to$
    new Subject<CycleTimeMonitorIntervalKPIs>()
    this.possibleEQ.get(line)?.forEach((tileData) => {
      const getCycleTImeMonitorIntervalKPIsSubscription = combineLatest([
        from$,
        to$,
      ])
        .pipe(
          take(1),
          filter(([from, to]) => {
            return from != null && to != null
          }),
          // Switch from one observable to another and use the value from the previous observable
          switchMap(([from, to]) => {
            this.from = from
            this.to = to
            this.loading$.next(true)
            return this.mesInfoService.mesOrderWcGet(
              tileData.workcenterID.toString(),
              from.toISOString(),
              to.toISOString()
            )
          })
        )
        .subscribe((orders) => {
          if (this.from && this.to) {
            let orderFrom = ''
            let refValue = 0
            if (orders == null || orders.length === 0) {
              orderFrom = this.from.toISOString()
            } else {
              refValue = orders[orders.length - 1].referencecycletimetotalorder
              if (new Date(orders[orders.length - 1].start) < this.from) {
                orderFrom = this.from.toISOString()
              } else {
                orderFrom = orders[orders.length - 1].start
              }
            }
            this.cycleTimeMonitorService
              .kpiCycletimemonitorIntervalEqGet(
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                tileData.equipmentNumber,
                orderFrom,
                this.to.toISOString()
              )
              .subscribe((cycleTimeMonitorIntervalKPIs) => {
                this.loading$.next(false)
                tileData.value = cycleTimeMonitorIntervalKPIs
                  .averageCycleTimeTotalInterval?.value as number
                if (orders) {
                  tileData.referenceValue = refValue
                  if (tileData.value <= refValue) {
                    tileData.comparison = 'match'
                  } else if (tileData.value <= 1.05 * refValue) {
                    tileData.comparison = 'small_violation'
                  } else if (tileData.value > 1.05 * refValue) {
                    tileData.comparison = 'large_violation'
                  }
                }
              })
          }
        })
      this.subscriptions.add(getCycleTImeMonitorIntervalKPIsSubscription)
    })
  }
}
