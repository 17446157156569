/**
 * Cycle Referenced Process Values
 * This API manages cycle references process data
 *
 * OpenAPI spec version: 0.5.3
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Parameter { 
  name: string
  bool?: boolean
  double?: number
  long?: number
  string?: string
  ts: string
}