import { Chart } from 'chart.js'
import * as helper from './../helper/histogram.helper'

export const buildHistogramPlugin = {
  id: 'histogramPlugin',
  flag: true,
  afterUpdate(): void {
    this.flag = false
  },
  beforeDraw(chart: Chart): void {
    if (!this.flag && chart.canvas.id === 'histogram-chart') {
      helper.resetHeightOfLine(0, chart)
      helper.resetHeightOfLine(1, chart)
      helper.resetHeightOfLine(2, chart)
      helper.resetHeightOfLine(3, chart)
      const barMetaData = chart.getDatasetMeta(4)
      for (const element of barMetaData.data) {
        const bar = element as helper.BarCoordinate
        bar.x = bar.x + bar.width
      }
      this.flag = true
    }
  }
}
