/* eslint-disable */
/**
 * Machine Information
 * This API delivers machine information stored in TDS
 *
 * OpenAPI spec version: 0.2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http'
import { Inject, Injectable, Optional } from '@angular/core'
import { CustomHttpUrlEncodingCodec } from '../encoder'

import { Observable } from 'rxjs'

import { Machine } from '../model/machine'

import { Configuration } from '../configuration'
import { BASE_PATH_TDS } from '../variables'

@Injectable()
export class MachinesService {
  protected basePath = 'https://{customIP}:{port}/'
  public defaultHeaders = new HttpHeaders()
  public configuration = new Configuration()

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH_TDS) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath
    }
    if (configuration) {
      this.configuration = configuration
      this.basePath = basePath || configuration.basePath || this.basePath
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data'
    for (const consume of consumes) {
      if (form === consume) {
        return true
      }
    }
    return false
  }

  /**
   * Get all machines in requested plants. If no plant is given all machines will be returned.
   *
   * @param plantCodes Codes of the plants to request machine information from
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public machinesGet(
    plantCodes?: Array<string>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<Machine>>
  public machinesGet(
    plantCodes?: Array<string>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Machine>>>
  public machinesGet(
    plantCodes?: Array<string>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Machine>>>
  public machinesGet(
    plantCodes?: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    })

    if (plantCodes) {
      const plantCodesJsonString = JSON.stringify(plantCodes)
      queryParameters = queryParameters.set('plantCodes', plantCodesJsonString)
    }

    let headers = this.defaultHeaders

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json']
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts)
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected)
    }

    // to determine the Content-Type header
    const consumes: string[] = []

    return this.httpClient.request<Array<Machine>>(
      'get',
      `${this.basePath}/machines`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }
}
/* eslint-enable */
