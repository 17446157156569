import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import { Machine } from '@dms-frontend/tds/services/api'

/**
 * Factory creating a validation function for a form control
 * @param getMachineParameter function for getting the machine parameter which should be validated
 * @param getAllMachines function for getting all machines
 * @param otherControlChecks objects for creation of other control checks.
 *    If other controls´ checks are not met the returned ValidatorFn will return false (not valid)
 *    Each objects contains a getParam for getting the machine parameter and
 *    the related control, which contains the current input selection for this machine parameter.
 *    @example Creating the Segment FromControl Validator-Function: If a segment is selected,
 *      which does not exist in the currently selected plant (plant control) the validator function
 *      need to return a validation error.
 * @returns
 */
export function inputValidatorFactory(
  getMachineParameter: (machine: Machine) => string,
  getAllMachines: () => Machine[],
  otherControlChecks?: {
    getParam: (machine: Machine) => string;
    control: AbstractControl;
  }[]
): ValidatorFn {
  const validator = (control: AbstractControl): ValidationErrors | null => {
    const valInMachines =
      getAllMachines().map(getMachineParameter).includes(control.value) ||
      control.value === '' ||
      control.value == null

    // All machines suiting this control value | F.e. all machines in segment 1
    const filteredMachines = getAllMachines().filter((machine) => {
      return (
        getMachineParameter(machine) === control.value ||
        control.value === '' ||
        control.value == null
      )
    })

    // Check whether machine of this control also is set in other controls
    // Example: equipment set to 1000000, check wether segment control value is valid
    const suitedToOtherControls =
      otherControlChecks == null
        ? true
        : otherControlChecks
          ?.map((controlCheck) => {
            // Return true if other control value is valid
            return (
              controlCheck.control.value === '' ||
                controlCheck.control.value == null ||
                filteredMachines
                  .map(controlCheck.getParam)
                  .includes(controlCheck.control.value)
            )
          })
          .reduce((prev, cur) => {
            // Reduce: All other control panels are valid
            return prev && cur
          })

    const forbidden = !valInMachines || !suitedToOtherControls
    return forbidden ? { forbiddenName: { value: control.value } } : null
  }
  return validator
}
