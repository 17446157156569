<mat-card class="w-[13rem] h-40 flex flex-col items-center bg-background-dark">
  <h6 class="h-[20%]">{{ headline }}</h6>
  <p
    *ngIf="value !== null && value !== undefined"
    [class.text-primary]="referenceValueComparison === 'match'"
    [class.text-error]="referenceValueComparison === 'large_violation'"
    [class.text-sunny-yellow]="referenceValueComparison === 'small_violation'"
    class="h-[60%] flex items-center text-[44px]"
  >
    {{ value | number: '':'de-DE' }}
  </p>
  <p
    *ngIf="value === null || value === undefined"
    class="h-[60%] flex items-center text-[44px]"
  >
    no data
  </p>
  <h6 *ngIf="referenceValue !== undefined" class="h-[20%]">
    {{ referenceValueText }}
    {{
      referenceValue === null
        ? 'no data'
        : (referenceValue | number: '':'de-DE')
    }}
  </h6>
  <mat-progress-spinner
    *ngIf="loading"
    class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
    [mode]="'indeterminate'"
  >
  </mat-progress-spinner>
</mat-card>
