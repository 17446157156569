import { Component } from '@angular/core'
import { MachineSelectionService } from '@dms-frontend/shared/services/machine-selection'
import { TimeSelectionService } from '@dms-frontend/shared/services/time-selection'
import {
  CycleTimeMonitorIntervalKPIs,
  KpiService,
} from '@dms-frontend/tds/services/api'
import { DashboardService } from '@dms-frontend/tds/services/dashboard'
import {
  BehaviorSubject,
  Subscription,
  combineLatest,
  filter,
  switchMap,
  take,
} from 'rxjs'

@Component({
  selector: 'dms-frontend-cycle-time-monitor',
  templateUrl: './cycle-time-monitor.component.html',
  styleUrls: ['./cycle-time-monitor.component.scss'],
})
export class CycleTimeMonitorComponent {
  cycleTimeIntervalLoading$ = new BehaviorSubject<boolean>(false)
  cycleTimeMonitorIntervalKPIs: CycleTimeMonitorIntervalKPIs | null = null
  subscriptions = new Subscription()

  showCurrentInfo = false
  showIntervalInfo = false
  showOrderInfo = false

  eq: number | null = null
  from: Date | null = null
  to: Date | null = null

  constructor(
    private machineSelectionService: MachineSelectionService,
    private timeSelectionService: TimeSelectionService,
    private cycleTimeMonitorService: KpiService,
    private dashboardService: DashboardService
  ) {
    const loadingSubscription = this.cycleTimeIntervalLoading$.subscribe(
      (loading) => {
        this.dashboardService.setCycleTimeIntervalLoading(loading)
      }
    )
    this.subscriptions.add(loadingSubscription)

    const refresh$ = this.dashboardService.refresh$
    const selectedEq$ = this.machineSelectionService.selectedEquipment$
    const from$ = this.timeSelectionService.from$
    const to$ = this.timeSelectionService.to$

    const refreshSubscription = refresh$.subscribe((refresh) => {
      if (!refresh) {
        return
      }

      const getCycleTImeMonitorIntervalKPIsSubscription = combineLatest([
        selectedEq$,
        from$,
        to$,
      ])
        .pipe(
          take(1),
          filter(([eq, from, to]) => {
            return eq != null && from != null && to != null
          }),
          // Switch from one observable to another and use the value from the previous observable
          switchMap(([eq, from, to]) => {
            this.eq = eq
            this.from = from
            this.to = to
            this.cycleTimeIntervalLoading$.next(true)
            return this.cycleTimeMonitorService.kpiCycletimemonitorIntervalEqGet(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              eq!.toString(),
              from.toISOString(),
              to.toISOString()
            )
          })
        )
        .subscribe((cycleTimeMonitorIntervalKPIs) => {
          this.cycleTimeIntervalLoading$.next(false)
          this.cycleTimeMonitorIntervalKPIs = cycleTimeMonitorIntervalKPIs
        })
      this.subscriptions.add(getCycleTImeMonitorIntervalKPIsSubscription)
    })
    this.subscriptions.add(refreshSubscription)
  }

  showAverageCycleTimeInterval(): void {
    this.showIntervalInfo = !this.showIntervalInfo
    if (this.showIntervalInfo) {
      this.dashboardService.refresh()
    }
  }

  showAverageCycleTimeCurrent(): void {
    this.showCurrentInfo = !this.showCurrentInfo
    if (this.showCurrentInfo) {
      this.dashboardService.refresh()
    }
  }

  showAverageCycleTimeOrder(): void {
    this.showOrderInfo = !this.showOrderInfo
    if (this.showOrderInfo) {
      this.dashboardService.refresh()
    }
  }
}
