<!-- Component Start -->
<div class="flex flex-col">
  <div
    class="hover:bg-hover-overlay cursor-pointer"
    (click)="menuClick(); $event.stopPropagation()"
  >
    <div class="w-full">
      <div
        class="flex inline align-middle"
        [ngClass]="{
          'text-grey-800': !isFocused,
          'text-primary': isFocused
        }"
      >
        <span
          *ngIf="menuPoint.iconStructure.iconStyle !== 'none'"
          aria-hidden="false"
          class="mx-5 my-3"
          [ngClass]="menuPoint.iconStructure.iconStyle"
        >
          {{ menuPoint.iconStructure.icon }}
        </span>
        <div
          *ngIf="menuPoint.iconStructure.iconStyle === 'none'"
          class="w-4"
        ></div>
        <span
          class="menu-text font-[Roboto] font-light my-3 pt-0.5"
          [ngClass]="'ml-' + menuPoint.menuLevel"
        >
          {{ menuPoint.name }}
        </span>
        <ng-container *ngIf="menuPoint.hasSubMenus">
          <mat-icon
            class="overflow-hidden my-3 mr-2"
            [style.display]="menuState === 'OPEN' ? 'none' : ''"
            >expand_more
          </mat-icon>
          <mat-icon
            class="overflow-hidden my-3 mr-2"
            [style.display]="menuState === 'CLOSED' ? 'none' : ''"
            >expand_less
          </mat-icon>
        </ng-container>
      </div>
    </div>
  </div>
  <div
    class="overflow-hidden"
    [ngClass]="{
      'max-h-0': menuState === 'CLOSED',
      'max-h-full': menuState === 'OPEN'
    }"
  >
    <ng-content></ng-content>
  </div>
</div>
<ng-container *ngIf="menuPoint.menuLevel === 1">
  <div class="border-b-2 border-border"></div>
</ng-container>
<!-- Component End  -->
