<div class="flex flex-row">
  <form class="inline">
    <mat-form-field class="mr-2 w-56" appearance="fill">
      <input type="text" matInput [formControl]="dateTimeFromControl" />
      <mat-label>From</mat-label>
      <mat-error *ngIf="dateTimeFromControl.hasError('invalidFromTime')">
        From can not be dated after To
      </mat-error>
      <mat-error *ngIf="dateTimeFromControl.hasError('dateTimeRangeOverflow')">
        Date can not range more than 14 Days
      </mat-error>
      <mat-error *ngIf="dateTimeFromControl.hasError('invalidDateTime')">
        Invalid Date Time Format
      </mat-error>
      <input
        class="hidden"
        matInput
        [min]="minDateFrom"
        [max]="selectedTimeTo"
        [matDatepicker]="datepickerFrom"
        [formControl]="dateFromControl"
      />
      <input
        class="hidden"
        matInput
        [max]="maxTimeFrom"
        [matTimepicker]="timepickerFrom"
        [formControl]="timeFromControl"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="datepickerFrom"
      ></mat-datepicker-toggle>
      <mat-datepicker #datepickerFrom>
        <mat-datepicker-actions>
          <button mat-button matDatepickerCancel>Cancel</button>
          <button
            mat-raised-button
            (click)="timepickerFrom.open()"
            color="primary"
            matDatepickerApply
          >
            Apply
          </button>
        </mat-datepicker-actions>
      </mat-datepicker>
      <mat-timepicker matTimepickerToggleIcon #timepickerFrom>
        <mat-timepicker-actions>
          <button mat-button matTimepickerCancel>Cancel</button>
          <button
            mat-raised-button
            (click)="setFromDateTime()"
            color="primary"
            matTimepickerApply
          >
            Apply
          </button>
        </mat-timepicker-actions>
      </mat-timepicker>
    </mat-form-field>
  </form>

  <form class="inline">
    <mat-form-field class="mr-2 w-56" appearance="fill">
      <input type="text" matInput [formControl]="dateTimeToControl" />
      <mat-label>To</mat-label>
      <mat-error *ngIf="dateTimeToControl.hasError('invalidToTime')">
        To can not be dated before From
      </mat-error>
      <mat-error *ngIf="dateTimeToControl.hasError('dateTimeRangeOverflow')">
        Date can not range more than 14 Days
      </mat-error>
      <mat-error *ngIf="dateTimeToControl.hasError('invalidDateTime')">
        Invalid Date Time Format
      </mat-error>
      <input
        class="hidden"
        matInput
        [min]="selectedTimeFrom"
        [max]="maxDateTo"
        [matDatepicker]="datepickerTo"
        [formControl]="dateToControl"
      />
      <input
        class="hidden"
        matInput
        [min]="minTimeTo"
        [matTimepicker]="timepickerTo"
        [formControl]="timeToControl"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="datepickerTo"
      ></mat-datepicker-toggle>
      <mat-datepicker #datepickerTo>
        <mat-datepicker-actions>
          <button mat-button matDatepickerCancel>Cancel</button>
          <button
            mat-raised-button
            (click)="timepickerTo.open()"
            color="primary"
            matDatepickerApply
          >
            Apply
          </button>
        </mat-datepicker-actions>
      </mat-datepicker>
      <mat-timepicker matTimepickerToggleIcon #timepickerTo>
        <mat-timepicker-actions>
          <button mat-button matTimepickerCancel>Cancel</button>
          <button
            mat-raised-button
            (click)="setToDateTime()"
            color="primary"
            matTimepickerApply
          >
            Apply
          </button>
        </mat-timepicker-actions>
      </mat-timepicker>
    </mat-form-field>
  </form>
</div>
