import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ApiStateService } from '@dms-frontend/tds-services-api-old'
import { SharedUiStatusTileModule } from '@dms-frontend/shared/ui/status-tile'
import { LineStatesComponent } from './line-states/line-states.component'

@NgModule({
  declarations: [LineStatesComponent],
  exports: [LineStatesComponent],
  imports: [CommonModule, SharedUiStatusTileModule],
  providers: [ApiStateService],
})
export class TdsUiLineStatesModule { }
