<div class="relative" id="subsystem-chart-container">
  <canvas
    #subsystemChart
    id="subsystem-chart"
    baseChart
    [data]="chartData"
    [type]="'line'"
    [options]="chartOptions"
    [plugins]="chartPlugins"
  >
  </canvas>
  <canvas id="subsystem-tip" width="0" height="0"></canvas>
  <mat-progress-spinner
    *ngIf="loading$ | async"
    class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
    [mode]="'indeterminate'"
  >
  </mat-progress-spinner>
</div>
