import { AllowanceDiagrammComponent } from './allowance-diagramm.component'

export function setChartData(component: AllowanceDiagrammComponent): void {
  // Current allowances
  const currentAllowances = component.currentAllowances.map(
    (currentAllowance) => {
      return { x: currentAllowance.ts, y: currentAllowance.value }
    }
  )

  // Average current allowances
  const averageCurrentAllowances = component.averagesCurrentAllowance.map(
    (el) => {
      return { x: el.ts, y: el.value }
    }
  )

  // Current allowances lower limits
  const currentAllowanceLowerLimits = component.lowerLimitsCurrentAllowance.map(
    (el) => {
      return { x: el.ts, y: el.value }
    }
  )

  const lastCurrentAllowanceLowerLimit =
    currentAllowanceLowerLimits[currentAllowanceLowerLimits.length - 1]

  if (lastCurrentAllowanceLowerLimit) {
    currentAllowanceLowerLimits.push(
      // Inject point for drawing last line
      {
        x: component.maxDate.toISOString(),
        y: lastCurrentAllowanceLowerLimit.y,
      }
    )
  }

  // Current allowances upper limits
  const currentAllowanceUpperLimits = component.upperLimitsCurrentAllowance.map(
    (el) => {
      return { x: el.ts, y: isNaN(el.value) ? null : el.value }
    }
  )

  const lastCurrentAllowanceUpperLimit =
    currentAllowanceUpperLimits[currentAllowanceUpperLimits.length - 1]

  if (lastCurrentAllowanceUpperLimit) {
    currentAllowanceUpperLimits.push(
      // Inject points for drawing last line
      {
        x: component.maxDate.toISOString(),
        y:
          lastCurrentAllowanceUpperLimit.y == null
            ? null
            : lastCurrentAllowanceUpperLimit.y,
      }
    )
  }

  // Allowance positions
  const allowancePositions = component.allowancePositions.map(
    (allowancePosition) => {
      return { x: allowancePosition.ts, y: allowancePosition.value }
    }
  ).sort((a, b) => {
    const aDate = new Date(a.x)
    const bDate = new Date(b.x)
    return aDate.getTime() - bDate.getTime()
  })

  // Reference allowance positions
  const referenceAllowancePositions = component.referenceAllowancePositions.map(
    (el) => {
      return { x: el.ts, y: el.value }
    }
  )

  const lastReferenceAllowancePosition =
    referenceAllowancePositions[referenceAllowancePositions.length - 1]

  if (lastReferenceAllowancePosition) {
    referenceAllowancePositions.push({
      x: component.maxDate.toISOString(),
      y: lastReferenceAllowancePosition.y,
    })
  }

  // Suggestion allowance positions
  const suggestionAllowancePositions =
    component.suggestionsAllowancePositions.map((el) => {
      return { x: el.ts, y: el.value }
    }) .sort((a, b) => {
      const aDate = new Date(a.x)
      const bDate = new Date(b.x)
      return aDate.getTime() - bDate.getTime()
    })


  const outliersCurrentAllowance = component.allowanceOutliers.map(
    (element) => {
      return { x: element.ts, y: element.value }
    }
  )

  const outliersAllowancePosition = component.allowancePositionOutliers.map(
    (element) => {
      return { x: element.ts, y: element.value }
    }
  )

  component.chartData = {
    datasets: [
      {
        label: 'Current allowance',
        backgroundColor: 'green',
        borderColor: 'green',
        pointRadius: 2,
        type: 'scatter',
        data: currentAllowances,
        order: 1,
      },
      {
        label: 'Average current allowance',
        type: 'line',
        stepped: 'before',
        borderColor: 'green',
        backgroundColor: 'green',
        data: averageCurrentAllowances,
        order: 2,
        pointRadius: 0,
        pointHitRadius: 4,
        datalabels: {
          display: (context: any): boolean => {
            return context.dataIndex === averageCurrentAllowances.length - 1
          },
          borderColor: 'green',
          anchor: 'end',
          color: 'green',
          backgroundColor: 'rgba(0,0,0,0)',
        },
      },
      {
        label: 'Lower limits current allowance',
        type: 'line',
        stepped: 'before',
        borderDash: [10, 5],
        borderColor: 'green',
        backgroundColor: 'rgba(0,0,0,0)',
        data: currentAllowanceLowerLimits,
        datalabels: {
          display: (context: any): boolean => {
            return context.dataIndex < currentAllowanceUpperLimits.length - 1
          },
          borderColor: 'green',
          anchor: 'end',
          color: 'green',
        },
        order: 3,
      },
      {
        label: 'Upper limits current allowance',
        type: 'line',
        stepped: 'after',
        borderColor: 'green',
        borderDash: [10, 5],
        data: currentAllowanceUpperLimits,
        datalabels: {
          display: (context: any): boolean => {
            return context.dataIndex < currentAllowanceUpperLimits.length - 1
          },
          borderColor: 'green',
          anchor: 'end',
          color: 'green',
        },
        order: 4,
      },
      {
        label: 'Allowance position',
        type: 'line',
        stepped: 'before',
        pointRadius: 0,
        pointHitRadius: 4,
        backgroundColor: 'black',
        borderColor: 'black',
        data: allowancePositions,
        order: 5,
      },
      {
        label: 'Reference allowance position',
        type: 'line',
        stepped: 'before',
        borderDash: [10, 5],
        datalabels: {
          display: (context: any): boolean => {
            return context.dataIndex < referenceAllowancePositions.length
          },
          borderColor: 'black',
          backgroundColor: 'rgba(0,0,0,0)',
          anchor: 'end',
          color: 'black',
        },
        data: referenceAllowancePositions,
        borderColor: 'black',
        pointRadius: 0,
        pointHitRadius: 4,
        backgroundColor: 'rgba(0,0,0,0.6)',
        order: 6,
      },
      {
        label: 'Suggestion allowance position',
        type: 'line',
        stepped: 'before',
        borderColor: 'blue',
        backgroundColor: 'blue',
        pointRadius: 0,
        pointHitRadius: 4,
        datalabels: {
          display: (context: any): boolean => {
            return context.dataIndex === suggestionAllowancePositions.length - 1
          },
          borderColor: 'blue',
          anchor: 'end',
          color: 'blue',
          backgroundColor: 'rgba(0,0,0,0)',
        },
        data: suggestionAllowancePositions,
        order: 7,
      },
      {
        label: 'Outlier current allowance',
        type: 'scatter',
        pointRadius: 4,
        borderWidth: 2,
        borderColor: 'red',
        backgroundColor: 'green',
        data: outliersCurrentAllowance,
        order: 0,
      },
      {
        label: 'Outlier allowance position ',
        type: 'scatter',
        pointRadius: 1,
        borderWidth: 1,
        borderColor: 'red',
        backgroundColor: 'red',
        pointHitRadius: 4,
        data: outliersAllowancePosition,
        order: 0,
      },
    ],
  }
}
