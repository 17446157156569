import { DOCUMENT } from '@angular/common'
import { Component, Inject, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { SCREEN_SIZE_TRESH_SMALL } from '@dms-frontend/shared/injection-tokens'
import { NavigationService } from '@dms-frontend/shared/services/navigation'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { MenuState } from '../type/menu-state'
import { MenuStructure } from '../type/menu-structure.interface'

@UntilDestroy()
@Component({
  selector: 'dms-frontend-menu-point',
  templateUrl: './menu-point.component.html',
  styleUrls: ['./menu-point.component.scss'],
})
export class MenuPointComponent implements OnInit {
  // Input properties
  @Input() menuPoint!: MenuStructure
  @Input() routerLink?: string
  @Input() isFocused?: boolean

  // Standard properties
  menuState: MenuState
  window?: Window

  constructor(
    private _router: Router,
    private _navigationService: NavigationService,
    @Inject(SCREEN_SIZE_TRESH_SMALL) public screenSizeTreshSmall: number,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.menuState = MenuState.Closed
  }
  ngOnInit(): void {
    if (this.document.defaultView) {
      this.window = this.document.defaultView
    }
    this._navigationService.focusChangeSignal$
      .pipe(untilDestroyed(this))
      .subscribe((menuName) => {
        if (this.menuPoint.subMenuList) {
          this.isFocused = false
          for (const name of this.menuPoint.subMenuList) {
            if (name === menuName) {
              this.isFocused = true
            }
          }
        } else if (this.menuPoint.name === menuName) {
          this.isFocused = true
        } else {
          this.isFocused = false
        }
      })
  }

  menuClick(): void {
    if (this.menuState === MenuState.Closed) {
      this.menuState = MenuState.Open
    } else {
      this.menuState = MenuState.Closed
    }
    if (this.routerLink || this.routerLink === '') {
      this._router.navigate([this.routerLink])
      if (this.window && this.window.innerWidth < this.screenSizeTreshSmall) {
        this._navigationService.setMenuState(false)
      }
    }
  }
}
