<div class="flex justify-between">
  <div class="pl-6 text-body-1 min-height-96">
    <div class="flex justify-between w-80">
      <p>Maximum [mm]:</p>
      <p>{{ maximumAllowance | number: "":"de-DE" }}</p>
    </div>
    <div class="flex justify-between w-80">
      <p>Average [mm]:</p>
      <p>{{ averageAllowance | number: "":"de-DE" }}</p>
    </div>
    <div class="flex justify-between w-80">
      <p>Minimum [mm]:</p>
      <p>{{ minimumAllowance | number: "":"de-DE" }}</p>
    </div>
    <div class="flex justify-between w-80">
      <p>Suggestion allowance pos. [mm]:</p>
      <p>{{ suggestionAllowance | number: "":"de-DE" }}</p>
    </div>
    <br />
    <form class="inline">
      <mat-form-field class="w-80 mr-2" appearance="fill">
        <mat-label>Allowance Grouping [mm]</mat-label>
        <input
          id="nip"
          size="5"
          type="number"
          aria-label="Number"
          matInput
          [formControl]="allowanceControl"
        />
      </mat-form-field>
    </form>
  </div>
  <div class="relative">
    <canvas
      id="histogram-chart"
      class="h-[25rem] w-[50rem] pr-6"
      baseChart
      [data]="chartData"
      [type]="'bar'"
      [options]="chartOptions"
      [plugins]="chartPlugins"
    >
    </canvas>
    <mat-progress-spinner
      *ngIf="loading$ | async"
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      [mode]="'indeterminate'"
    >
    </mat-progress-spinner>
  </div>
</div>
