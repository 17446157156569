import { FormControl } from '@angular/forms'
import { Machine } from '@dms-frontend/tds/services/api'
import { Subject, map, startWith } from 'rxjs'
import * as _ from 'underscore'

function _filter(strings: string[], value: string): string[] {
  const filterValue = value.toLowerCase()
  return strings.filter((option) => option.toLowerCase().includes(filterValue))
}

export interface MachineFilter {
  // Getter function getting the machine parameter to be filtered for
  machineParameter: (machine: Machine) => string;
  // Getter function getting the current value for which to compare the machine paramter
  value: () => string | null | undefined;
}

/**
 * Adds autocompletion feature to control for machines for the machine parametere machineParam
 * @param control FormControl to add autocompletion for
 * @param subject Subject emitting new autocompletion options
 * @param machineParam Getter function returning the machine parameter for which to filter
 * @param getAllMachines Getter function returning all machines
 * @param filters Functions for filtering all machines.
 *    Filtered machines are used to generate autocompletion options
 */
export function addAutocompletion(
  control: FormControl,
  subject: Subject<string[]>,
  machineParam: (machine: Machine) => string,
  getAllMachines: () => Machine[],
  filters: MachineFilter[]
): void {
  control.valueChanges
    .pipe(
      startWith(''),
      map((value) => {
        if (value == null) {
          return ''
        }
        return value.toString()
      }),
      map((value) => {
        // Creating filter function which filters all machines according to all filters-objects
        const machineFilter =
          filters.length === 0
            ? // Filter everything
            (): boolean => true
            : // Create filter function for all filters
            filters
              .map((filter) => {
                // Create filter function from filter object
                return (machine: Machine): boolean => {
                  const value = filter.value()
                  return (
                    value == null ||
                      value === '' ||
                      filter.machineParameter(machine) === value
                  )
                }
              })
              .reduce((prev, cur) => {
                // Reduce to one filter function filtering everything needed
                return (machine: Machine): boolean =>
                  prev(machine) && cur(machine)
              })

        const filteredMachines = getAllMachines().filter(machineFilter)
        // Get unique array of the parameter machineParam of the filtered machines
        // Example: plantCodes: ['A', 'A', 'A', 'AB', 'C', 'C'] => ['A', 'AB', 'C']
        const selectables = _.uniq(filteredMachines.map(machineParam))
        // Filter all selectables beginning with current control value
        // Example: selectables = ['A', 'AB', 'C'], User typed A => filteredSelectables = ['A', 'AB']
        const filteredSelectables = _filter(selectables, value)
        return filteredSelectables
      })
    )
    .subscribe(subject)
}
