import { Component, Input } from '@angular/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { BehaviorSubject, combineLatest, skip, take } from 'rxjs'
import { Machine, MachinesService } from '@dms-frontend/tds/services/api'

import { MachineSelectionService } from '@dms-frontend/shared/services/machine-selection'
import { TimeSelectionService } from '@dms-frontend/shared/services/time-selection'
import { DashboardService } from '@dms-frontend/tds/services/dashboard'

// In milliseconds | 360 seconds
const DURATION_BETWEEN_REAL_TIME_UPDATES = 360 * 1000
@Component({
  selector: 'dms-frontend-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss'],
})
export class DashboardHeaderComponent {
  // This is a flag whether the refresh button would refresh any shown diagram.
  // Use-case: If no diagram is shown, the refresh button will not be shown yellow.
  // This component will handle the logic whether an equipment, from or to changed since
  // last refresh.
  @Input() refreshIsRefreshingSomething = false
  @Input() disableRefresh = false
  @Input() disableAutoRefresh = false
  @Input() disableFromSelection = false

  selectedLine: Machine[] = []

  get autoRefresh(): boolean {
    return this.dashboardService.getRealTime()
  }
  @Input() set autoRefresh(autoRefresh: boolean) {
    this.dashboardService.setRealTime(autoRefresh)
    if (autoRefresh) {
      const deltaTime = this.toDate.getTime() - this.fromDate.getTime()
      const now = new Date(Date.now())
      this.timeSelectionService.selectTo(now)
      this.timeSelectionService.selectFrom(new Date(now.getTime() - deltaTime))
      this.activateAutoRefresh()
    }
  }
  disabled = false

  selectedEqOnLastRefresh: number | null = null
  selectedFromOnLastRefresh: Date | null = null
  selectedToOnLastRefresh: Date | null = null
  newSelectionSinceLastRefresh = false

  loading$ = new BehaviorSubject<boolean>(false)

  fromDate: Date = new Date(0)
  toDate: Date = new Date(0)

  constructor(
    public dialog: MatDialog,
    private machineApi: MachinesService,
    private machineSelectionService: MachineSelectionService,
    private timeSelectionService: TimeSelectionService,
    private dashboardService: DashboardService
  ) {
    this.machineSelectionService.machinesInSelectedLine$.subscribe(
      (machines) => {
        this.selectedLine = machines
      }
    )

    const from$ = this.timeSelectionService.from$
    const to$ = this.timeSelectionService.to$
    const eq$ = this.machineSelectionService.selectedEquipment$

    combineLatest([from$, to$]).subscribe(([from, to]) => {
      this.fromDate = from
      this.toDate = to
    })

    combineLatest([from$, to$, eq$])
      .pipe(skip(3)) // Ignore init of eq, from and to
      .subscribe(([from, to, eq]) => {
        if (!this.refreshIsRefreshingSomething) {
          this.selectedFromOnLastRefresh = from
          this.selectedToOnLastRefresh = to
          this.selectedEqOnLastRefresh = eq
          return
        }
        if (
          from.valueOf() !== this.selectedFromOnLastRefresh?.valueOf() ||
          to.valueOf() !== this.selectedToOnLastRefresh?.valueOf() ||
          eq !== this.selectedEqOnLastRefresh
        ) {
          // If no diagram is loaded set last selected to eq, from, to
          this.newSelectionSinceLastRefresh = true
        }
      })

    this.autoRefresh = this.dashboardService.realTime$.getValue()
  }

  forceRefresh(): void {
    this.timeSelectionService.selectTo(this.toDate)
    this.refresh()
  }

  refresh(): void {
    this.machineSelectionService.selectedEquipment$
      .pipe(take(1))
      .subscribe((eq) => {
        this.selectedEqOnLastRefresh = eq
      })
    this.timeSelectionService.from$.pipe(take(1)).subscribe((from) => {
      this.selectedFromOnLastRefresh = from
    })
    this.timeSelectionService.to$.pipe(take(1)).subscribe((to) => {
      this.selectedToOnLastRefresh = to
    })

    this.newSelectionSinceLastRefresh = false
    this.dashboardService.refresh()
  }

  activateAutoRefresh(): void {
    const loop = (): void => {
      setTimeout(() => {
        if (!this.autoRefresh) {
          return
        }
        const deltaTime = this.toDate.getTime() - this.fromDate.getTime()
        const now = new Date(Date.now())
        this.timeSelectionService.selectTo(now)
        this.timeSelectionService.selectFrom(
          new Date(now.getTime() - deltaTime)
        )
        this.refresh()
        loop()
      }, DURATION_BETWEEN_REAL_TIME_UPDATES)
    }
    loop()
  }
}
