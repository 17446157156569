import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { MachineSelectionService } from '@dms-frontend/shared/services/machine-selection'
import {
  MachinesService,
  ReferenceValue,
  ReferenceValues,
  ReferenceValuesService,
} from '@dms-frontend/tds/services/api'
import { BehaviorSubject, Subscription, forkJoin, map, startWith } from 'rxjs'
import * as _ from 'underscore'
import {
  ReferenceValueDummy,
  _filterForEQ,
  _getNewestReferenceValues,
  mapReferenceValuesArrayToMillimeter,
  mapReferenceValuesToMeter,
} from '../shared/reference-value-helper'

export interface EditMaterialNumberDialogInput {
  // All existing equipment numbers
  equipmentNumber?: number
  materialNumber?: number
  materialText: string
}

@Component({
  selector: 'dms-frontend-edit-material-number-dialog',
  templateUrl: './edit-material-number-dialog.component.html',
  styleUrls: ['./edit-material-number-dialog.component.scss'],
})
export class EditMaterialNumberDialogComponent implements OnInit, OnDestroy {
  equipmentNumberSelected = false
  materialNumberSelected = false

  equipmentNumberControl = new FormControl()
  materialNumberControl = new FormControl()

  allEquipmentNumbers: number[] = []
  filteredEquipmentNumbers = new BehaviorSubject<string[]>([])

  materialNumbers: number[] = []
  filteredMaterialNumbers = new BehaviorSubject<string[]>([])

  materialNumber?: number
  materialText: string | null = null

  referenceValues: ReferenceValueDummy[] = []
  initialReferenceValues: ReferenceValueDummy[] = []
  initFlag = true

  allReferenceValues: ReferenceValues[] = []
  referenceValuesValid = true

  loading$ = new BehaviorSubject(true)

  // Subscription containing all subscription | Will be unsubscribed on destory
  subscriptions = new Subscription()

  constructor(
    public dialogRef: MatDialogRef<EditMaterialNumberDialogComponent>,
    public referenceValuesService: ReferenceValuesService,
    public machineService: MachinesService,
    public machineSelectionService: MachineSelectionService,
    @Inject(MAT_DIALOG_DATA) public data: EditMaterialNumberDialogInput
  ) {}

  ngOnInit(): void {
    this.loading$.next(true)
    const machines$ = this.machineSelectionService.refreshMachines()
    const referenceValues$ = this.referenceValuesService
      .referencevaluesGet()
      .pipe(
        // Map values to millimeter
        map(mapReferenceValuesArrayToMillimeter)
      )

    const machinesAndRefValues = forkJoin([
      machines$,
      referenceValues$,
    ]).subscribe(([machines, referenceValues]) => {
      this.allEquipmentNumbers = machines.map((machine) => machine.eqnr)
      this.allReferenceValues = referenceValues
      this.referenceValues = _getNewestReferenceValues(
        referenceValues,
        this.data.equipmentNumber,
        this.data.materialNumber
      )
      if (this.initFlag) {
        // Deep copy
        this.referenceValues.forEach((val) =>
          this.initialReferenceValues.push(Object.assign({}, val))
        )
        this.initFlag = false
      }
      this.materialNumbers = this._materialNumbers()
      this._addAutocompletion()
      this._addValidators()
      this.equipmentNumbersClosed()
      this.equipmentNumberControl.setValue(this.data.equipmentNumber)
      this.materialNumberControl.setValue(this.materialNumber)
      this.loading$.next(false)
    })

    this.subscriptions.add(machinesAndRefValues)

    // Setting component properties from Dialog Input data
    this.equipmentNumberSelected = this.data.equipmentNumber != null
    this.materialNumber = this.data.materialNumber

    this.materialNumberSelected = this.materialNumber != null
    this.materialText = this.data.materialText

    if (!this.equipmentNumberSelected) {
      this.materialNumberControl.disable()
    }

    this.materialNumberControl.valueChanges.subscribe((materialNumber) => {
      this.materialNumber = materialNumber
    })

    this.equipmentNumberControl.setValue(this.data.equipmentNumber)
    this.materialNumberControl.setValue(this.materialNumber)
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  save(): void {
    if (
      this.materialNumberControl.valid &&
      this.equipmentNumberControl.valid &&
      this.referenceValuesValid
    ) {
      const referenceValues: ReferenceValues = {
        equipmentNumber: parseInt(this.equipmentNumberControl.value, 10),
        values: this.referenceValues.map((value) => {
          return {
            ...value,
            unit: value.unit as string,
            value: value.value?.replace(',', '.') as string,
            materialNumber: this.materialNumberControl.value,
          }
        }),
      }
      // Overwrite referenceValues with correct material number
      for (let i = 0; i < referenceValues.values.length; i++) {
        referenceValues.values[i].materialNumber = parseInt(
          this.materialNumberControl.value,
          10
        )
      }

      const valuesPut: ReferenceValue[] = []
      const valuesPost: ReferenceValue[] = []

      const referenceValuesInMeter = mapReferenceValuesToMeter(referenceValues)
      for (let i = 0; i < referenceValuesInMeter.values.length; i++) {
        const currentRefValue = this.initialReferenceValues.filter((values) => {
          return values.name === referenceValuesInMeter.values[i].name
        })
        if (currentRefValue.length !== 1) {
          continue
        }
        if (
          currentRefValue[0].validSince ===
          referenceValuesInMeter.values[i].validSince
        ) {
          valuesPut.push(referenceValuesInMeter.values[i])
        } else {
          valuesPost.push(referenceValuesInMeter.values[i])
        }
      }

      const referenceValuesPut: ReferenceValues = {
        equipmentNumber: parseInt(this.equipmentNumberControl.value, 10),
        values: valuesPut,
      }
      const referenceValuesPost: ReferenceValues = {
        equipmentNumber: parseInt(this.equipmentNumberControl.value, 10),
        values: valuesPost,
      }

      this.loading$.next(true)
      if (valuesPut.length > 0) {
        const putSubscription = this.referenceValuesService
          .referencevaluesPut(referenceValuesPut)
          .subscribe(() => {
            this.loading$.next(false)
            this.dialogRef.close()
          })
        this.subscriptions.add(putSubscription)
      }
      if (valuesPost.length > 0) {
        const postSubscription = this.referenceValuesService
          .referencevaluesPost(referenceValuesPost)
          .subscribe(() => {
            this.loading$.next(false)
            this.dialogRef.close()
          })
        this.subscriptions.add(postSubscription)
      }
    }
  }

  cancel(): void {
    this.dialogRef.close()
  }

  next(): void {
    if (this.materialNumberControl.valid && this.equipmentNumberControl.valid) {
      this.materialNumberSelected = true
      const machines$ = this.machineSelectionService.refreshMachines()
      const referenceValues$ = this.referenceValuesService
        .referencevaluesGet()
        .pipe(
          // Map values to millimeter
          map(mapReferenceValuesArrayToMillimeter)
        )

      const machinesAndRefValues = forkJoin([
        machines$,
        referenceValues$,
      ]).subscribe(([machines, referenceValues]) => {
        this.allEquipmentNumbers = machines.map((machine) => machine.eqnr)
        this.allReferenceValues = referenceValues
        this.referenceValues = _getNewestReferenceValues(
          referenceValues,
          this.data.equipmentNumber,
          parseInt(this.materialNumberControl.value, 10)
        )

        // Deep copy
        this.referenceValues.forEach((val) =>
          this.initialReferenceValues.push(Object.assign({}, val))
        )
        this.initFlag = false

        this._addAutocompletion()
        this._addValidators()
        this.loading$.next(false)
      })

      this.subscriptions.add(machinesAndRefValues)
    }
  }

  equipmentNumbersClosed(): void {
    // If equipmentnumber selected is valid enable material number input
    if (this.equipmentNumberControl.valid) {
      if (this.data.equipmentNumber === +this.equipmentNumberControl.value) {
        return
      }
      this.data.equipmentNumber = parseInt(
        this.equipmentNumberControl.value,
        10
      )
      this.materialNumberControl.enable()
      // Emit autocompletion values
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const materialNumbers = this._materialNumbers()
      this.materialNumbers = materialNumbers
      this.filteredMaterialNumbers.next(
        materialNumbers.map((e) => e.toString())
      )
      this.materialNumberControl.setValue('')
    } else {
      this.materialNumberControl.disable()
      this.materialNumberControl.setValue('')
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  materialNumbersClosed(): void {
    if (this.materialNumberControl.valid && this.equipmentNumberControl.valid) {
      this.referenceValues = _getNewestReferenceValues(
        this.allReferenceValues,
        parseInt(this.equipmentNumberControl.value, 10),
        parseInt(this.materialNumberControl.value, 10)
      )
    }
  }

  private _addAutocompletion(): void {
    // Equipmentnumber autocompletion
    // Initialise filtered equipmentnumbers for autocompletion
    // With all equipmentnumbers
    this.filteredEquipmentNumbers.next(
      this.allEquipmentNumbers.map((el) => el.toString())
    )

    const equiptmenNumberAutocompletion =
      this.equipmentNumberControl.valueChanges
        .pipe(
          startWith(''),
          map((value) => this._filterEquipmentNumbers(value?.toString()))
        )
        .subscribe(this.filteredEquipmentNumbers)
    this.subscriptions.add(equiptmenNumberAutocompletion)

    // Materialnumber autocompletion
    const materialNumberAutocompletion = this.materialNumberControl.valueChanges
      .pipe(
        startWith(''),
        map((value) => this._filterMaterialNumbers(value?.toString()))
      )
      .subscribe(this.filteredMaterialNumbers)
    this.subscriptions.add(materialNumberAutocompletion)
  }

  private _addValidators(): void {
    this.equipmentNumberControl.addValidators((control) => {
      const forbidden = !this.allEquipmentNumbers.includes(
        parseInt(control.value, 10)
      )
      return forbidden ? { forbiddenName: { value: control.value } } : null
    })

    // Check if there are already reference values for selected equipment number and material number
    this.materialNumberControl.addValidators((control) => {
      return this._materialNumberValid()
        ? null
        : { forbiddenName: { value: control.value } }
    })
  }

  private _materialNumberValid(): boolean {
    const materialNumber = this.materialNumberControl.value
    const materialNumbers = this._materialNumbers()
    return materialNumbers.includes(parseInt(materialNumber, 10))
  }

  private _filterEquipmentNumbers(value: string): string[] {
    if (value == null) {
      return this.allEquipmentNumbers.map((el) => el.toString())
    }
    const filterValue = value.toLowerCase()
    return this.allEquipmentNumbers
      .map((el) => el.toString())
      .filter((equipmentNumber) =>
        equipmentNumber.toLowerCase().includes(filterValue)
      )
  }

  private _filterMaterialNumbers(value: string): string[] {
    if (value == null) {
      return this.materialNumbers.map((el) => el.toString())
    }

    const filterValue = value.toLowerCase()

    return this.materialNumbers
      .map((el) => el.toString())
      .filter((materialNumber) =>
        materialNumber.toLowerCase().includes(filterValue)
      )
  }

  /**
   * Returns all material numbers of all reference values with equipment number equipmentNumber
   * @param equipmentNumber
   */
  private _materialNumbers(): number[] {
    if (this.allReferenceValues == null) {
      return []
    }

    const referenceValuesForEQ = _filterForEQ(
      this.allReferenceValues,
      this.data.equipmentNumber
    )
    if (referenceValuesForEQ == null) {
      return []
    }

    const materialNumbers = _.unique(
      referenceValuesForEQ.values
        .map((referenceValue) => {
          return [referenceValue.materialNumber]
        })
        .reduce((prev, cur) => {
          return [...prev, ...cur]
        }, [])
    )

    return materialNumbers
  }
}
