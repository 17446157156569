import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MachineSelectionComponent } from './machine-selection/machine-selection.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedMaterialModule } from '@dms-frontend/shared/material'

@NgModule({
  imports: [
    CommonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedMaterialModule,
  ],
  declarations: [
    MachineSelectionComponent
  ],
  exports: [
    MachineSelectionComponent
  ]
})
export class MachineSelectionModule { }
