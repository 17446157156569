<div class="container w-40 h-20 bg-secondary flex flex-row">
  <!-- status left: green | red -->
  <div class="container w-3" [class.bg-primary]="!error()" [class.bg-error]="error()">
  </div>
  <!-- content -->
  <div class="container w-full flex flex-col">
    <div class="container h-7 pt-1 flex items-center justify-center text-grey-800">
      {{ eqState.equipmentNumber }}
    </div>
    <div class="container h-full flex items-center justify-center text-2xl font-bold text-h6 text-grey-800">
      {{ errorCount() }}
    </div>
    <!-- icons: error categories -->
    <div class="container  h-7 pb-1 pl-1 pr-1 flex flex-row justify-around max-h-full">
      <div class="container h-5 w-5 flex items-center justify-center " [class.invisible]="!errorMeasurementMonitor()">
        <mat-icon class="text-grey-800">speed</mat-icon>
      </div>
      <!-- Placeholder used for extension for up to six icons in total -->
      <div class="container h-5 w-5 flex items-center justify-center invisible">
        <mat-icon class="text-grey-800">speed</mat-icon>
      </div>
      <div class="container h-5 w-5 flex items-center justify-center invisible">
        <mat-icon class="text-grey-800">speed</mat-icon>
      </div>
      <div class="container h-5 w-5 flex items-center justify-center invisible">
        <mat-icon class="text-grey-800">speed</mat-icon>
      </div>
      <div class="container h-5 w-5 flex items-center justify-center invisible">
        <mat-icon class="text-grey-800">speed</mat-icon>
      </div>
      <div class="container h-5 w-5 flex items-center justify-center invisible">
        <mat-icon class="text-grey-800">speed</mat-icon>
      </div>
    </div>
  </div>
</div>
