import { HistogramComponent } from './histogram.component'

export function setChartOptions(histogram: HistogramComponent): void {
  histogram.chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    animation: false,
    scales: {
      x: {
        position: 'bottom',
        title: {
          text: 'Allowance in [mm]',
          display: true,
          align: 'end',
          font: {
            size: 14,
          },
        },
        offset: false,
        min: 0,
        type: 'linear',
        beginAtZero: true,
        ticks: {
          stepSize: histogram.xAxisLabel[1],
        },
      },
      y: {
        title: {
          text: 'Frequency',
          display: true,
          align: 'end',
          font: {
            size: 14,
          },
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      datalabels: {
        display: false,
      },
    },
  }
}
