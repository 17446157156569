import { Order } from '@dms-frontend/tds/services/api'
import { Subject } from 'rxjs'
import { SubsystemComponent } from '../subsystem.component'

export function retrieveOrders(
  component: SubsystemComponent,
  from: Date,
  to: Date,
  workCenterID: number
): Subject<Order[]> {
  const orders$ = new Subject<Order[]>()
  component.mesInfoService
    .mesOrderWcGet(
      workCenterID.toString(),
      from.toISOString(),
      to.toISOString()
    )
    .subscribe(
      (orders) => {
        const returnOrders = orders == null || orders.length === 0 ? [] : orders
        orders$.next(returnOrders)
      },
      (err) => orders$.next([])
    )
  return orders$
}
