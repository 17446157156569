<div class="md:pt-2">
  <dms-frontend-dashboard-header
    [class.pointer-events-none]="loading$ | async"
    [disableAutoRefresh]="true"
    [disableFromSelection]="true"
    [autoRefresh]="true"
  ></dms-frontend-dashboard-header>

  <mat-accordion multi>
    <mat-expansion-panel *ngFor="let line of selectedLines" class="mr-4">
      <mat-expansion-panel-header
        class="text-body-1"
        (click)="showAverageCycleTimeInterval(line)"
      >
        <mat-panel-title>
          Line {{ line }} Average Cycle Time Total [min]
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="ml-4">
        <div
          *ngFor="let machine of possibleEQ.get(line)"
          class="inline-block my-2 mx-2"
        >
          <dms-frontend-tile-value-with-ref-value
            [headline]="machine.equipmentNumber"
            [value]="machine.value / 60"
            [referenceValueComparison]="machine.comparison"
            [referenceValue]="machine.referenceValue / 60"
          ></dms-frontend-tile-value-with-ref-value>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
