import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { OverviewMaterialnumbersComponent } from './overview-materialnumbers/overview-materialnumbers.component'
import { SharedMaterialModule } from '@dms-frontend/shared/material'
import { ReactiveFormsModule } from '@angular/forms'
import { SharedDirectivesModule } from '@dms-frontend/shared/directives'

@NgModule({
  imports: [
    CommonModule,
    SharedMaterialModule,
    ReactiveFormsModule,
    SharedDirectivesModule
  ],
  declarations: [
    OverviewMaterialnumbersComponent
  ],
  exports: [
    OverviewMaterialnumbersComponent
  ]
})
export class TdsUiOverviewMaterialnumbersModule { }
