import { Injectable } from '@angular/core'
import { Chart } from 'chart.js'
import { Order } from '@dms-frontend/tds/services/api'
import {
  materialTextLabelUnderXAxis,
  materialTextToolTip,
} from './materialTextLabel'

export interface Box {
  top: number
  bottom: number
  left: number
  right: number
}

export interface Label {
  matText: string
  matNumber: string
  orderID: number
  box: Box
}

export interface Coordinate {
  x: number
  y: number
}

/**
 * Determines whether x, y coordinate is inside box including the box´ border
 * @param x
 * @param y
 * @param box
 * @returns
 */
export function insideBox(x: number, y: number, box: Box): boolean {
  const xMatch = x >= box.left && x <= box.right
  const yMatch = y >= box.top && y <= box.bottom
  return xMatch && yMatch
}

/**
 * Returns Label on which was clicked. Returns null on no label clicked
 * @param x
 * @param y
 */
export function getClickedLabel(
  x: number,
  y: number,
  labels: Map<Coordinate, Label>
): Label | null {
  for (const label of labels) {
    if (insideBox(x, y, label[1].box)) {
      return label[1]
    }
  }
  return null
}
@Injectable({
  providedIn: 'root',
})
export class MaterialTextPluginService {
  buildMatTxtPlugin(
    minDate: Date,
    maxDate: Date,
    orders: Order[],
    canvas: HTMLCanvasElement,
    tooltip: HTMLCanvasElement,
    xAxisOffsetPrecantage: number,
    onMatTxtClick: (label: Label) => void
  ): any {
    const labels: Map<Coordinate, Label> = new Map<Coordinate, Label>()
    const timeDiff = maxDate.valueOf() - minDate.valueOf()
    let minShownDate = new Date(
      minDate.valueOf() - timeDiff * (xAxisOffsetPrecantage / 100)
    )
    let maxShownDate = new Date(
      maxDate.valueOf() + timeDiff * (xAxisOffsetPrecantage / 100)
    )

    if (xAxisOffsetPrecantage === 0) {
      minShownDate = new Date(minDate.valueOf())
      maxShownDate = new Date(maxDate.valueOf())
    }

    const yOffset = 42
    materialTextToolTip(canvas, tooltip, onMatTxtClick, yOffset, labels)

    // Return plugin object
    return {
      id: 'matTxtLabels',
      afterRender(chart: Chart): void {
        this.activeFlag = false
        const {
          ctx,
          chartArea: { bottom, top },
          scales: { x },
        } = chart
        const xScaleBegin = x.left
        const xScaleEnd = x.right

        ctx.save()

        /**
         * Calculates the x coordinate of the xAxis where date begins
         * @param date
         * @returns
         */
        function getXCoordinate(date: Date): number {
          // Get all labels from xAxis
          const ticks = x.getTicks()
          // Get x coordinate of first label on xAxis
          const xMin = x.getPixelForTick(0)
          // Get x coordinate of last label on xAxis
          const xMax = x.getPixelForTick(ticks.length - 1)

          // Length of xAxis in pixels
          const xAxisLength = xMax - xMin

          const deltaTimeShownOnXAxis =
            maxShownDate.getTime() - minShownDate.getTime()
          const deltaMinDateToDate = date.getTime() - minShownDate.getTime()

          // Portion of xAxis length where date begins
          const portionOfXAxis = deltaMinDateToDate / deltaTimeShownOnXAxis
          const resultX = xMin + portionOfXAxis * xAxisLength

          if (resultX < xScaleBegin) {
            return xScaleBegin
          } else if (resultX > xScaleEnd) {
            return xScaleEnd
          }
          return resultX
        }
        for (const order of orders) {
          const xBegin = getXCoordinate(new Date(order.start))
          let xEnd
          if (order.end === '') {
            xEnd = getXCoordinate(maxShownDate)
          } else {
            xEnd = getXCoordinate(new Date(order.end))
          }
          const matText = order.materialtext
          const matNumber = order.materialnumber
          const orderID = order.id
          let drawText = false
          if (xEnd - xBegin > 150) {
            drawText = true
          }

          materialTextLabelUnderXAxis(
            ctx,
            bottom,
            yOffset,
            xScaleBegin,
            top,
            matText,
            matNumber,
            orderID,
            xBegin,
            xEnd,
            drawText,
            labels
          )
        }
      },
    }
  }
}
