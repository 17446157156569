import { Injectable } from '@angular/core'
import { Roles, RolesService } from '@dms-frontend/shared/services/azure-auth'
import { Machine, MachinesService } from '@dms-frontend/tds/services/api'
import { BehaviorSubject, Subject } from 'rxjs'

/**
 * MachinesService handling all selected machines.
 * You need to request all machines from the api service to
 * get any machines.
 * @see MachinesService
 */
@Injectable({
  providedIn: 'root',
})
export class MachineSelectionService {
  /**
   * All machines initialised with []
   * Will be filled with allMachines from backend request
   */
  readonly allMachines$ = new BehaviorSubject<Machine[]>([])
  private _allMachines: Machine[] = []
  get allMachines(): Machine[] {
    return this._allMachines
  }
  set allMachines(allMachines: Machine[]) {
    this._allMachines = allMachines
    this.allMachines$.next(allMachines)
  }

  /**
   * Selected machines
   * Will be initialised with requested all machines after Service construction
   * Will be set to selected machines
   */
  readonly selectedMachines$ = new BehaviorSubject<Machine[]>([])
  private _selectedMachines: Machine[] = []
  private get selectedMachines(): Machine[] {
    return this._selectedMachines
  }
  private set selectedMachines(machines: Machine[]) {
    this._selectedMachines = machines
    this.selectedMachines$.next(machines)
  }

  // Emits selected plant on plant selection
  readonly selectedPlant$ = new BehaviorSubject<string | null>(null)
  private _selectedPlant: string | null = null
  get selectedPlant(): string | null {
    return this._selectedPlant
  }
  private set selectedPlant(plant: string | null) {
    this._selectedPlant = plant
    this.selectedPlant$.next(plant)
  }

  // Emits selected segment on segment selection
  readonly selectedSegment$ = new BehaviorSubject<string | null>(null)
  private _selectedSegment: string | null = null
  get selectedSegment(): string | null {
    return this._selectedSegment
  }
  private set selectedSegment(segment: string | null) {
    this._selectedSegment = segment
    this.selectedSegment$.next(segment)
  }

  // Emits selectedLine on line selection
  readonly selectedLine$ = new BehaviorSubject<string | null>(null)
  private _selectedLine: string | null = null
  get selectedLine(): string | null {
    return this._selectedLine
  }
  private set selectedLine(line: string | null) {
    this._selectedLine = line
    this.selectedLine$.next(line)
    const machinesInLine =
      line == null
        ? []
        : this.allMachines.filter((machine) => {
          return (
            machine.plantCode === this._selectedPlant &&
              machine.segment === this._selectedSegment &&
              machine.line === line
          )
        })
    this.machinesInSelectedLine$.next(machinesInLine)
  }

  // Emits machine in selected line on line selection
  readonly machinesInSelectedLine$ = new BehaviorSubject<Machine[]>([])

  // Emits selected equipment on equipment selection
  readonly selectedEquipment$ = new BehaviorSubject<number | null>(null)
  private _selectedEquipment: number | null = null
  get selectedEquipment(): number | null {
    return this._selectedEquipment
  }
  private set selectedEquipment(equipment: number | null) {
    this._selectedEquipment = equipment
    this.selectedEquipment$.next(equipment)
  }

  // Emits selected workcenterID on equipment selection
  readonly selectedWorkCenterID$ = new BehaviorSubject<number | null>(null)
  private _selectedWorkCenterID: number | null = null
  get selectedWorkCenterID(): number | null {
    return this._selectedWorkCenterID
  }
  private set selectedWorkCenterID(workCenterID: number | null) {
    this._selectedWorkCenterID = workCenterID
    this.selectedWorkCenterID$.next(workCenterID)
  }

  onGotAllMachines$ = new BehaviorSubject<boolean>(false)

  constructor(
    private readonly machinesApi: MachinesService,
    private readonly rolesService: RolesService
  ) {
    this.refreshMachines()
  }

  /**
   * Gets all machines from backend for the current user roles.
   * @returns a Subject emitting all machines. Subject completes after emitting
   */
  refreshMachines(): Subject<Machine[]> {
    let assignedPlants: string[] | undefined = this.rolesService.roles
    const userIsAdmin = assignedPlants.includes(Roles.Admin)
    // Set assignedPlants to undefined if user is admin.
    // This will lead to getting machines from all plants
    assignedPlants = userIsAdmin ? undefined : assignedPlants

    // Avoid side effects with creating new Subject
    const allMachines$ = new Subject<Machine[]>()
    this.machinesApi.machinesGet(assignedPlants).subscribe((machines) => {
      this.allMachines = machines
      this.onGotAllMachines$.next(true)
      this.allMachines$.next(machines)
      allMachines$.next(machines)
      allMachines$.complete()
    })
    return allMachines$
  }

  selectPlant(plantCode: string | null): void {
    if (this.selectedPlant === plantCode) {
      return
    }
    // Important! First set selected machines and then set selected plant!
    this.selectedMachines = this.allMachines.filter(
      (machine) => machine.plantCode === plantCode
    )
    this.selectedPlant = plantCode
  }

  selectSegment(segment: string | null): void {
    if (this.selectedSegment === segment) {
      return
    }
    // Important! First set selected machines and then set selected segment!
    this.selectedMachines = this.allMachines
      .filter((machine) => machine.plantCode === this.selectedPlant)
      .filter((machine) => machine.segment === segment)
    this.selectedSegment = segment
  }

  selectLine(line: string | null): void {
    if (this.selectedLine === line) {
      return
    }
    // Important! First set selected machines and then set selected line!
    this.selectedMachines = this.allMachines
      .filter((machine) => machine.plantCode === this.selectedPlant)
      .filter((machine) => machine.segment === this.selectedSegment)
      .filter((machine) => machine.line === this.selectedLine)
    this.selectedLine = line
  }

  selectEquipment(equipmentNumber: number | null): void {
    if (this.selectedEquipment === equipmentNumber || equipmentNumber == null) {
      return
    }
    // Important! First set selected machines and then set selected equipment!
    this.selectedMachines = this.allMachines.filter((machine) => {
      return machine.eqnr === +equipmentNumber
    })

    if (this.selectedMachines[0].mesworkcenterid !== undefined) {
      this.selectedWorkCenterID = this.selectedMachines[0].mesworkcenterid
    }
    this.selectedEquipment = equipmentNumber
  }
}
