import { InjectionToken } from '@angular/core'

export const BASE_PATH_TDS = new InjectionToken<string>('basePath')
export const BASE_PATH_CONNECIVITY = new InjectionToken<string>('basePath')
export const BASE_PATH_CONNETIVITY_LEGACY = new InjectionToken<string>('basePath')
export const COLLECTION_FORMATS = {
  'csv': ',',
  'tsv': '   ',
  'ssv': ' ',
  'pipes': '|'
}
