<dms-frontend-overview-materialnumbers
  [class.pointer-events-none]="loading$ | async"
>
</dms-frontend-overview-materialnumbers>
<br />

<button
  mat-raised-button
  color="primary"
  (click)="addMaterialDialog()"
  [class.pointer-events-none]="loading$ | async"
>
  + ADD MATERIAL
</button>

<mat-progress-spinner
  *ngIf="loading$ | async"
  class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
  [mode]="'indeterminate'"
>
</mat-progress-spinner>
