import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StatusTileComponent } from './status-tile/status-tile.component'
import { MatIconModule } from '@angular/material/icon'

@NgModule({
  imports: [CommonModule, MatIconModule],
  exports: [StatusTileComponent],
  declarations: [StatusTileComponent]
})
export class SharedUiStatusTileModule { }
