import { Directive, ElementRef, HostListener } from '@angular/core'

@Directive({
  selector: '[dmsFrontendFloatNumber]',
})
export class FloatNumberDirective {
  element: ElementRef

  constructor(elr: ElementRef) {
    this.element = elr
  }

  @HostListener('window:keypress', ['$event'])
  keyEvent(event: KeyboardEvent): boolean {
    const target = event.target as HTMLInputElement
    if (
      target != null &&
      target.id != null &&
      target.id !== this.element.nativeElement.id
    ) {
      return true
    }

    const isNumberRegEx = /^[0-9,]$/
    const elementValue: string = this.element.nativeElement.value
    if (
      elementValue != null &&
      elementValue !== undefined &&
      !elementValue.includes(',') &&
      event.key === ','
    ) {
      if (
        isNumberRegEx.test(event.key) &&
        parseFloat(this.element.nativeElement.value + event.key).toString() +
          ',' ===
          this.element.nativeElement.value + event.key
      ) {
        return true
      }
    }

    const cursorPosition = (event.target as any).selectionStart
    const value: string = this.element.nativeElement.value.toString()
    const newValue =
      value.slice(0, cursorPosition) +
      event.key +
      value.slice(cursorPosition, value.length)

    // If 0 is typed at beginning inject . if no point exists else abort
    const startingWithZero = cursorPosition === 0 && event.key === '0'
    if (startingWithZero) {
      this.element.nativeElement.value = value.includes(',')
        ? value
        : '0,' + value
      return false
    }

    return (
      isNumberRegEx.test(event.key) &&
      !isNaN(Number(newValue.replace(',', '.')))
    )
  }
}
