<div class="md:pt-2">
  <dms-frontend-dashboard-header
    [refreshIsRefreshingSomething]="anyDiagramRefreshable"
    [disableRefresh]="subsystemDiagramLoading"
    [class.pointer-events-none]="loading$ | async"
  ></dms-frontend-dashboard-header>

  <mat-accordion>
    <mat-expansion-panel class="mr-4">
      <mat-expansion-panel-header
        class="text-body-1"
        (click)="loadSubsystemDiagram()"
      >
        <mat-panel-title> Subsystems </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="pl-12 pr-12 w-full h-full">
        <dms-frontend-subsystem
          (loading$)="subsystemDiagramLoading = $event"
          *ngIf="showSubsystemDiagram"
        ></dms-frontend-subsystem>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
