import { DressingTimingDiagramComponent } from './dressing-timing-diagram.component'

export function setChartData(component: DressingTimingDiagramComponent): void {
  // Grinding Wheel Diameter
  const grindingWheelDiameter = component.grindingWheelDiameter.map(
    (grindingWheelDiameter) => {
      return { x: grindingWheelDiameter.ts, y: grindingWheelDiameter.value }
    }
  )

  // Manual Dressing
  const manualDressing = component.manualDressing.map((manualDressing) => {
    return { x: manualDressing.ts, y: manualDressing.value }
  })

  // Grinding Wheel Diameter Worn Out
  const grindingWheelDiameterWornOut =
    component.grindingWheelDiameterWornOut.map(
      (grindingWheelDiameterWornOut) => {
        return {
          x: grindingWheelDiameterWornOut.ts,
          y: grindingWheelDiameterWornOut.value,
        }
      }
    )

  component.chartData = {
    datasets: [
      {
        label: 'GrindingWheelDiameter',
        backgroundColor: 'green',
        borderColor: 'green',
        type: 'scatter',
        data: grindingWheelDiameter,
        order: 3,
      },
      {
        label: 'grindingWheelDiameterWornOut',
        backgroundColor: 'black',
        borderColor: 'black',
        type: 'scatter',
        data: grindingWheelDiameterWornOut,
        order: 2,
      },
      {
        label: 'ManualDressing',
        backgroundColor: 'red',
        borderColor: 'red',
        type: 'scatter',
        data: manualDressing,
        order: 1,
      },
    ],
  }
}
