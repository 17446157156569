import { REFERENCE_VALUESS } from './reference-values'

// Make one object for each ReferenceValue
export interface LinearisedReferenceValue {
  equipmentNumber: number
  materialNumber: number
  materialText: string
  name: string
  value: number
  type: string
  validSince: string
}

// Create one object for each reference value
export const LINEARISED_REFERENCE_VALUES: LinearisedReferenceValue[] = []

let i = 0
for (const referenceValues of REFERENCE_VALUESS) {
  for (const referenceValue of referenceValues.values) {
    LINEARISED_REFERENCE_VALUES.push({
      equipmentNumber: referenceValues.equipmentNumber,
      materialNumber: referenceValue.materialNumber,
      materialText: `MATERIAL_TEXT_${i}`,
      name: referenceValue.name,
      value: referenceValue.value,
      type: referenceValue.unit,
      validSince: referenceValue.validSince
    })
    i++
  }
}
