/**
 * State
 * This API provides comparison result of machine process values
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
export type NameEnum =
  | 'CurrentAllowance'
  | 'AllowancePosition'
  | 'CycleTimeGapGrinding'
  | 'AutomaticMode'
  | 'GapControl'
  | 'TouchControl'
  | 'AutoRoughGrinding'
  | 'AutoSparkOut'
  | 'Override100'

export const NameEnum = {
  CurrentAllowance: 'CurrentAllowance' as NameEnum,
  AllowancePosition: 'AllowancePosition' as NameEnum,
  CycleTimeGapGrinding: 'CycleTimeGapGrinding' as NameEnum,
  AutomaticMode: 'AutomaticMode' as NameEnum,
  GapControl: 'GapControl' as NameEnum,
  TouchControl: 'TouchControl' as NameEnum,
  AutoRoughGrinding: 'AutoRoughGrinding' as NameEnum,
  AutoSparkOut: 'AutoSparkOut' as NameEnum,
  Override100: 'Override100' as NameEnum,
  GapControlValid: 'GapControlValid' as NameEnum,
}

export interface State {
  name: NameEnum
  value: boolean
}
