import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  readonly isMenuOpen$ = new BehaviorSubject<boolean>(false)
  readonly focusChangeSignal$ = new BehaviorSubject<string>('')

  setMenuState(value: boolean): void {
    this.isMenuOpen$.next(value)
  }

  setFocusSignal(value: string): void {
    this.focusChangeSignal$.next(value)
  }
}
