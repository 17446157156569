import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MachineSelectionService } from '@dms-frontend/shared/services/machine-selection'
import { TimeSelectionService } from '@dms-frontend/shared/services/time-selection'
import {
  DressingMonitorIntervalKPIs,
  KpiService,
} from '@dms-frontend/tds/services/api'
import { DashboardService } from '@dms-frontend/tds/services/dashboard'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { combineLatest, filter, switchMap, take } from 'rxjs'
import { SharedMaterialModule } from '@dms-frontend/shared/material'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { TdsUiTilesModule } from '@dms-frontend/tds/ui/tiles'

@UntilDestroy()
@Component({
  selector: 'dms-frontend-dressing-consumption-overview',
  standalone: true,
  imports: [
    CommonModule,
    TdsUiTilesModule,
    SharedMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedMaterialModule,
  ],
  templateUrl: './dressing-consumption-overview.component.html',
  styleUrls: ['./dressing-consumption-overview.component.scss'],
})
export class DressingConsumptionOverviewComponent implements OnInit {
  loading = false
  dressingMonitorIntervalKPIs: DressingMonitorIntervalKPIs | null = null

  eq: number | null = null
  from: Date | null = null
  to: Date | null = null

  selectedEq$ = this.machineSelectionService.selectedEquipment$
  from$ = this.timeSelectionService.from$
  to$ = this.timeSelectionService.to$

  constructor(
    private machineSelectionService: MachineSelectionService,
    private timeSelectionService: TimeSelectionService,
    private kpiService: KpiService,
    private dashboardService: DashboardService,
    private cd: ChangeDetectorRef
  ) {
    this.refresh()
  }

  ngOnInit(): void {
    this.dashboardService.dressingOverviewLoading$
      .pipe(untilDestroyed(this))
      .subscribe((dressingOverviewLoading) => {
        this.loading = dressingOverviewLoading
        // Trigger change detection as value is changed by child component and therefore
        // Triggered after change detection for this component has been executed
        this.cd.detectChanges()
      })

    const refresh$ = this.dashboardService.refresh$

    refresh$.pipe(untilDestroyed(this)).subscribe((refresh) => {
      if (!refresh) {
        return
      }
      this.refresh()
    })
  }

  refresh(): void {
    combineLatest([this.selectedEq$, this.from$, this.to$])
      .pipe(untilDestroyed(this))
      .pipe(
        take(1),
        filter(([eq, from, to]) => {
          return eq != null && from != null && to != null
        }),
        // Switch from one observable to another and use the value from the previous observable
        switchMap(([eq, from, to]) => {
          this.eq = eq
          this.from = from
          this.to = to
          this.dashboardService.dressingOverviewLoading$.next(true)
          return this.kpiService.kpiDressingmonitorIntervalEqGet(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.eq!.toString(),
            from.toISOString(),
            to.toISOString()
          )
        })
      )
      .subscribe((dressingMonitorIntervalKPIs) => {
        this.dashboardService.dressingOverviewLoading$.next(false)
        this.dressingMonitorIntervalKPIs = dressingMonitorIntervalKPIs

        // Convert Tool Consumption: Manual Dressing into mm
        if (
          this.dressingMonitorIntervalKPIs.manualDressingSumInterval != null
        ) {
          this.dressingMonitorIntervalKPIs.manualDressingSumInterval.value *= 1000
        }
        // Convert Tool Consumption: diameter at change min into mm
        if (
          this.dressingMonitorIntervalKPIs
            .grindingWheelDiameterAtChangeIntervalMin != null
        ) {
          this.dressingMonitorIntervalKPIs.grindingWheelDiameterAtChangeIntervalMin.value *= 1000
        }
        // Convert Tool Consumption: diameter at change max into mm
        if (
          this.dressingMonitorIntervalKPIs
            .grindingWheelDiameterAtChangeIntervalMax != null
        ) {
          this.dressingMonitorIntervalKPIs.grindingWheelDiameterAtChangeIntervalMax.value *= 1000
        }
        // Convert Tool Consumption: diameter at change avg into mm
        if (
          this.dressingMonitorIntervalKPIs
            .grindingWheelDiameterAtChangeIntervalAvg != null
        ) {
          this.dressingMonitorIntervalKPIs.grindingWheelDiameterAtChangeIntervalAvg.value *= 1000
        }
      })
  }
}
